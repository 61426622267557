import React, { useState } from "react";
import ImageButton from "../../UniversalComponents/ImageButton";
import PropTypes from "prop-types";

const TrimColorSelection = ({
  handleTrimChange,
  redBorderFields,
  setFieldsWithRedBorder,
  handleRafterColorOptionChange,
}) => {
  const [selectedTrimOption, setSelectedTrimOption] = useState("");
  const [selectedRafterOption, setSelectedRafterOption] = useState("");
  const bronze = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/bronze.jpg`;
  const black = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/black.jpg`;
  const white = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/white.jpg`;
  const sandstone = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/sandstone.jpg`;
  const images1 = [white, sandstone, bronze, black];
  const messages = ["White", "Sandstone", "Bronze", "Black"];
  const bronzewwhite = `https://temoappstorage.blob.core.windows.net/temoimages/PergolaViewingImages/BronzewWhite.jpg`;
  const whitewblack = `https://temoappstorage.blob.core.windows.net/temoimages/PergolaViewingImages/WhitewBlackLouvers.jpg`;
  const blackwwhite = `https://temoappstorage.blob.core.windows.net/temoimages/PergolaViewingImages/BlackwWhite.jpg`;
  const blackwsandstone = `https://temoappstorage.blob.core.windows.net/temoimages/PergolaViewingImages/BlackWSandstoneRoof.jpg`;
  const images2 = [bronzewwhite, whitewblack, blackwwhite, blackwsandstone];
  const messages2 = [
    "Bronze With White Louvers",
    "White With Black Louvers",
    "Black with White Louvers",
    "Black with Sandstone Louvers",
  ];

  const handleColorOptionChange = (selected) => {
    setSelectedTrimOption(selected);
    let updatedRedBorderFields = redBorderFields.includes("Trim Color")
      ? selected
        ? redBorderFields.filter((field) => field !== "Trim Color")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Trim Color"];
    if (selected) {
      updatedRedBorderFields = updatedRedBorderFields.filter(
        (field) => field !== "Rafter Color",
      );
    }
    handleTrimChange(selected);
    handleRafterColorChange(selected);
    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleRafterColorChange = (selected) => {
    setSelectedRafterOption(selected);
    const updatedRedBorderFields = redBorderFields.includes("Rafter Color")
      ? selected
        ? redBorderFields.filter((field) => field !== "Rafter Color")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Rafter Color"];
    handleRafterColorOptionChange(selected);
    setFieldsWithRedBorder(updatedRedBorderFields); // Call setFieldsWithRedBorder after updating red border fields
  };

  return (
    <div className="trim-louvers">
      <div className="popupwindow trimcolor-choices">
        <h6
          className={
            "headers " +
            (redBorderFields.includes("Trim Color")
              ? "redBackground"
              : "greenBackground")
          }
        >
          <ImageButton images={images1} messages={messages} />
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Trim Color
          </span>
        </h6>
        <div>
          <div className="clickable-words">
            <div
              className={`clickable-word ${selectedTrimOption === "SANDSTONE" ? "clicked" : ""}`}
              onClick={() => handleColorOptionChange("SANDSTONE")}
            >
              Sandstone
            </div>
            <div
              className={`clickable-word ${selectedTrimOption === "WHITE" ? "clicked" : ""}`}
              onClick={() => handleColorOptionChange("WHITE")}
            >
              White
            </div>
          </div>
          <div className="clickable-words">
            <div
              className={`clickable-word ${selectedTrimOption === "BRONZE" ? "clicked" : ""}`}
              onClick={() => handleColorOptionChange("BRONZE")}
            >
              Bronze
            </div>
            <div
              className={`clickable-word ${selectedTrimOption === "BLACK" ? "clicked" : ""}`}
              onClick={() => handleColorOptionChange("BLACK")}
            >
              Black
            </div>
          </div>
        </div>
      </div>
      <div className="popupwindow louvercolor-choices">
        <h6
          className={
            "headers " +
            (redBorderFields.includes("Rafter Color")
              ? "redBackground"
              : "greenBackground")
          }
        >
          <ImageButton images={images2} messages={messages2} />
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Louver Color
          </span>
        </h6>
        <div>
          <div className="clickable-words">
            <div
              className={`clickable-word ${selectedRafterOption === "SANDSTONE" ? "clicked" : ""}`}
              onClick={() => handleRafterColorChange("SANDSTONE")}
            >
              Sandstone
            </div>
            <div
              className={`clickable-word ${selectedRafterOption === "WHITE" ? "clicked" : ""}`}
              onClick={() => handleRafterColorChange("WHITE")}
            >
              White
            </div>
          </div>
          <div className="clickable-words">
            <div
              className={`clickable-word ${selectedRafterOption === "BRONZE" ? "clicked" : ""}`}
              onClick={() => handleRafterColorChange("BRONZE")}
            >
              Bronze
            </div>
            <div
              className={`clickable-word ${selectedRafterOption === "BLACK" ? "clicked" : ""}`}
              onClick={() => handleRafterColorChange("BLACK")}
            >
              Black
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TrimColorSelection.propTypes = {
  handleTrimChange: PropTypes.func,
  handleRafterColorOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default TrimColorSelection;
