import React, { useState } from "react";
import InfoIcon from "../InfoIcon";
import PropTypes from "prop-types";

const SteelPiers = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleSteelPiers,
  style,
}) => {
  const [selectedPierValue, setSelectedPierValue] = useState("");

  const handleDropdownChange = (selected) => {
    const updatedRedBorderFields = redBorderFields.includes("steel piers")
      ? selected
        ? redBorderFields.filter((field) => field !== "steel piers")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "steel piers"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    setSelectedPierValue(selected);
    handleSteelPiers(selected);
  };

  return (
    <div className="popupwindow steelpiers" style={{ ...style }}>
      <form>
        <h6
          className={`headers ${
            redBorderFields.includes("steel piers")
              ? "redBackground"
              : "greenBackground"
          }`}
        >
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Steel Piers
          </span>
          <InfoIcon defaultMessage="Pier Tubes are for Free-Standing foundations." />
        </h6>
        <div className="clickable-words">
          <div
            className={`clickable-word ${selectedPierValue === "Yes" ? "clicked" : ""}`}
            onClick={() => handleDropdownChange("Yes")}
          >
            Yes
          </div>
          &nbsp;
          <div
            className={`clickable-word ${selectedPierValue === "No" ? "clicked" : ""}`}
            onClick={() => handleDropdownChange("No")}
          >
            No
          </div>
        </div>
      </form>
    </div>
  );
};

SteelPiers.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleSteelPiers: PropTypes.func,
  style: PropTypes.object,
};

export default SteelPiers;
