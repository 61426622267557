import { useEffect, useState } from "react";
import React from "react";
import PropTypes from "prop-types";
import DrawingModal from "../Standard/DrawingModal";
import SwingDoorOrientation from "./SwingDoorOrientation";
import DoorHardware from "./DoorHardware";
import PatioDoorOrientation from "./PatioDoorOrientation";
import WallLength from "./WallLength";
import DoorOption from "./DoorOption";
import DoorWidth from "./DoorWidth";
import PremierViewDrawingModal from "../PremierView/PremierViewDrawingModal";
import VinylViewDrawingModal from "../../../ScreenRoomsPageComponents/2INVinyl/VinylDrawingModal";
import ValueViewDrawingModal from "../../../ScreenRoomsPageComponents/2INGlassScreen/GlassDrawingModal";
import LinearDoorLocation from "../../../ScreenRoomsPageComponents/DoorLocation";

function SunRoomWallDrawing({
  index,
  doorOption,
  doorWidth,
  handleDoorOptionChange,
  handleDoorWidthChange,
  dimension,
  selectedRoomStyle,
  setSelectedImages,
  selectedImages,
  setSelectedSwingDoorOrientation,
  setSelectedCodes,
  selectedCodes,
  selectedButtonIndex,
  setSelectedButtonIndex,
  selectedSwingDoorOrientation,
  selectedDoorHardware,
  setSelectedDoorHardware,
  selectedPatioDoorOrientation,
  setSelectedPatioDoorOrientation,
  redBorderFields,
  setFieldsWithRedBorder,
  handleWallLengthFeetChange,
  handleWallLengthInchesChange,
  wallLengthsFeet,
  wallLengthsInches,
  SSHinges,
  handleSSHingesOptionChange,
  selectedPavilionLayoutOption,
  selectedBreezeWay,
  selectedSunRoomType,
  selectedRailSideOption,
  setWallLengthsFeet,
  setWallLengthsInches,
  setDoorOption,
  setDoorWidth,
  lastWallLengthUpdate,
  setLastWallLengthUpdate,
  handleCodeSelect,
  isScreenRoom,
  setSelectedLocations,
  handleDoorLocationChange,
  selectedLocations,
  handleSelectedScreenDoorWidthChoice,
  handleSelectedPatioDoorOrientation,
  currentImage,
  handleSwingDoorOrientation,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSwingDoorOrientation, setshowSwingDoorOrientation] =
    useState(false);
  const [showPatioDoorOrientation, setShowPatioDoorOrientation] =
    useState(false);
  const [showDoorHardware, setShowDoorHardware] = useState(false);

  const handleViewDrawingsClick = () => {
    setIsModalOpen(true);
  };

  const handleShowSwingDoorOrientationClick = () => {
    setshowSwingDoorOrientation(true);
  };

  const handleShowDoorHardwareClick = () => {
    setShowDoorHardware(true);
  };

  const handleShowPatioDoorOrientationClick = () => {
    setShowPatioDoorOrientation(true);
  };

  function getButtonClassName(index, doorWidth, redBorderFields) {
    const baseClass = "navbutton";
    let conditionClass = "";

    if (doorWidth[index] === "3' Door" || doorWidth[index] === "3.5' Door") {
      conditionClass = redBorderFields.includes(
        `Swing Door Orientation ${index + 1}`,
      )
        ? "redBackground"
        : "greenBackground";
    } else {
      conditionClass = redBorderFields.includes(
        `Patio Door Orientation ${index + 1}`,
      )
        ? "redBackground"
        : "greenBackground";
    }

    return `${baseClass} ${conditionClass}`;
  }

  useEffect(() => {
    const handleCheck = () => {
      if (selectedSunRoomType !== "Premier View") {
        if (wallLengthsFeet[index]) {
          const wallLength = Number(wallLengthsFeet[index]);
          if (wallLength < 4 && doorOption[index] !== "No") {
            handleDoorOptionChange(
              "No",
              index,
              setDoorOption,
              doorOption,
              doorWidth,
              setDoorWidth,
              `Door Option ${index + 1}`,
              setSelectedSwingDoorOrientation,
              setSelectedPatioDoorOrientation,
            );
          }
        }
        if (selectedPavilionLayoutOption !== "3") {
          if (
            index === 1 &&
            selectedRoomStyle === "CATHEDRAL" &&
            doorOption[index] !== "No"
          ) {
            handleDoorOptionChange(
              "No",
              index,
              setDoorOption,
              doorOption,
              doorWidth,
              setDoorWidth,
              `Door Option ${index + 1}`,
              setSelectedSwingDoorOrientation,
              setSelectedPatioDoorOrientation,
            );
          }
        } else {
          if (
            index === 0 &&
            selectedRoomStyle === "CATHEDRAL" &&
            doorOption[index] !== "No" &&
            wallLengthsFeet[index] !== ""
          ) {
            handleDoorOptionChange(
              "No",
              index,
              setDoorOption,
              doorOption,
              doorWidth,
              setDoorWidth,
              `Door Option ${index + 1}`,
              setSelectedSwingDoorOrientation,
              setSelectedPatioDoorOrientation,
            );
          }
        }
      } else {
        if (
          wallLengthsFeet[index] !== "" &&
          wallLengthsFeet[index] < 5 &&
          doorOption[index] !== "No"
        ) {
          handleDoorOptionChange(
            "No",
            index,
            setDoorOption,
            doorOption,
            doorWidth,
            setDoorWidth,
            `Door Option ${index + 1}`,
            setSelectedSwingDoorOrientation,
            setSelectedPatioDoorOrientation,
          );
        }
        if (
          index === 1 &&
          selectedRoomStyle === "CATHEDRAL" &&
          doorOption[index] !== "No" &&
          wallLengthsFeet[index] !== ""
        ) {
          handleDoorOptionChange(
            "No",
            index,
            setDoorOption,
            doorOption,
            doorWidth,
            setDoorWidth,
            `Door Option ${index + 1}`,
            setSelectedSwingDoorOrientation,
            setSelectedPatioDoorOrientation,
          );
        }
      }
    };
    const timeSinceLastUpdate = Date.now() - lastWallLengthUpdate;
    if (timeSinceLastUpdate >= 500) {
      handleCheck();
    }
  }, [
    index,
    doorOption,
    handleDoorOptionChange,
    selectedRoomStyle,
    wallLengthsFeet,
    selectedPavilionLayoutOption,
    selectedSunRoomType,
    setDoorOption,
    doorWidth,
    setDoorWidth,
    lastWallLengthUpdate,
    setSelectedSwingDoorOrientation,
    setSelectedPatioDoorOrientation,
  ]);

  let newIndex = null;

  if (selectedPavilionLayoutOption === "3") {
    newIndex = 1;
  }
  if (selectedPavilionLayoutOption === "2" && selectedBreezeWay === "Yes") {
    if (index === 0) {
      newIndex = 0;
    }
    if (index === 1) {
      newIndex = 2;
    }
  }
  if (selectedPavilionLayoutOption === "2" && selectedBreezeWay !== "Yes") {
    if (selectedRailSideOption === "RIGHT") {
      if (index === 0) {
        newIndex = 2;
      }
      if (index === 1) {
        newIndex = 1;
      }
    }
    if (selectedRailSideOption === "LEFT") {
      if (index === 0) {
        newIndex = 0;
      }
      if (index === 1) {
        newIndex = 1;
      }
    }
  }
  if (selectedPavilionLayoutOption === "1") {
    newIndex = index;
  }

  const isSwingDoor =
    doorWidth[index] === "3' Door" || doorWidth[index] === "3.5' Door";
  const isDoorOptionYes = doorOption[index] === "Yes";
  const isNotSwingDoorOrientation = !showSwingDoorOrientation;
  const isNotPatioDoorOrientation = !showPatioDoorOrientation;

  const condition =
    (isDoorOptionYes &&
      isSwingDoor &&
      isNotSwingDoorOrientation &&
      doorWidth[index] !== "") ||
    (isDoorOptionYes &&
      !isSwingDoor &&
      isNotPatioDoorOrientation &&
      doorWidth[index] !== "");

  return (
    <div
      key={index}
      style={{
        width: "33%",
        display: "grid",
        justifyItems: "center",
        alignItems: "center",
        marginRight: "0",
        marginLeft: "0",
      }}
    >
      <div
        style={{
          marginBottom: selectedSunRoomType === "Linear Screen" ? "20%" : "0",
        }}
      >
        <DoorHardware
          selectedDoorHardware={selectedDoorHardware}
          setSelectedDoorHardware={setSelectedDoorHardware}
          setShowDoorHardware={setShowDoorHardware}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
          index={index}
          selectedPavilionLayoutOption={selectedPavilionLayoutOption}
          doorWidth={doorWidth}
          handleShowDoorHardwareClick={handleShowDoorHardwareClick}
          SSHinges={SSHinges}
          handleSSHingesOptionChange={handleSSHingesOptionChange}
          redBorderFields={redBorderFields}
          showDoorHardware={showDoorHardware}
          selectedSunRoomType={selectedSunRoomType}
          handleSelectedScreenDoorWidthChoice={
            handleSelectedScreenDoorWidthChoice
          }
        />
      </div>
      {selectedSunRoomType !== "Linear Screen" && (
        <div
          className="popupwindow"
          style={{
            width: "fit-content",
            height: "fit-content",
            marginBottom: "3%",
            marginTop: "3%",
            backgroundColor: "transparent",
          }}
        >
          <img
            src={selectedImages[index]}
            alt="Selected"
            style={{
              height: `${dimension * 0.35}px`,
              maxWidth: `${dimension * 0.35}px`,
              maxHeight: "16vw",
              backgroundColor: "transparent",
            }}
          />
        </div>
      )}
      <div style={{ marginBottom: "3%", fontSize: "1.5vh" }}>
        {index === 0 &&
          selectedPavilionLayoutOption !== "3" &&
          selectedRailSideOption !== "RIGHT" && <span>Left Projection</span>}
        {index === 0 && selectedPavilionLayoutOption === "3" && (
          <>
            <span>Front</span>
            {selectedRoomStyle === "CATHEDRAL" && (
              <>
                <br />
                <span style={{ color: "red" }}>
                  Door Not Allowed On Cathedral Front Wall
                </span>
              </>
            )}
          </>
        )}
        {index === 1 && selectedBreezeWay !== "Yes" && (
          <>
            <span>Front</span>
            {selectedRoomStyle === "CATHEDRAL" && (
              <>
                <br />
                <span style={{ color: "red" }}>
                  Door Not Allowed On Cathedral Front Wall
                </span>
              </>
            )}
          </>
        )}
        {index === 0 &&
          selectedPavilionLayoutOption !== "3" &&
          selectedRailSideOption === "RIGHT" && <span>Right Projection</span>}
        {index === 1 && selectedBreezeWay === "Yes" && (
          <span>Right Projection</span>
        )}
        {index === 2 && <span>Right Projection</span>}
      </div>
      <WallLength
        wallLengthsFeet={wallLengthsFeet}
        wallLengthsInches={wallLengthsInches}
        redBorderFields={redBorderFields}
        index={index}
        handleWallLengthFeetChange={handleWallLengthFeetChange}
        handleWallLengthInchesChange={handleWallLengthInchesChange}
        setWallLengthsFeet={setWallLengthsFeet}
        setWallLengthsInches={setWallLengthsInches}
        wallLengthFeetMessage={`Wall Length Feet ${index + 1}`}
        wallLengthInchesMessage={`Wall Length Inches ${index + 1}`}
        setLastWallLengthUpdate={setLastWallLengthUpdate}
      />
      <div
        style={{
          backgroundColor: "transparent",
          width: "75%",
          marginBottom:
            (selectedPavilionLayoutOption !== "3" &&
              selectedBreezeWay !== "Yes" &&
              (index === 0 || index === 2)) ||
            (selectedPavilionLayoutOption === "2" && !selectedBreezeWay) ||
            (selectedPavilionLayoutOption === "3" && index === 1)
              ? "15%"
              : "0",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          height: "20vh",
          justifyItems: "center",
        }}
      >
        {(selectedSunRoomType !== "Premier View"
          ? (selectedPavilionLayoutOption !== "3" &&
              selectedBreezeWay !== "Yes" &&
              ((index === 1 &&
                wallLengthsFeet[index] >= 4 &&
                selectedRoomStyle !== "CATHEDRAL") ||
                (index !== 1 && wallLengthsFeet[index] >= 4))) ||
            (selectedPavilionLayoutOption !== "3" &&
              selectedBreezeWay === "Yes" &&
              wallLengthsFeet[index] >= 4) ||
            (selectedPavilionLayoutOption === "3" &&
              index === 0 &&
              wallLengthsFeet[index] >= 4 &&
              selectedRoomStyle !== "CATHEDRAL")
          : (selectedPavilionLayoutOption !== "3" &&
              selectedBreezeWay !== "Yes" &&
              ((index === 1 &&
                wallLengthsFeet[1] >= 5 &&
                selectedRoomStyle !== "CATHEDRAL") ||
                (index !== 1 && wallLengthsFeet[index] >= 5))) ||
            (selectedPavilionLayoutOption !== "3" &&
              selectedBreezeWay === "Yes" &&
              wallLengthsFeet[index] >= 5) ||
            (selectedPavilionLayoutOption === "3" &&
              index === 0 &&
              wallLengthsFeet[index] >= 5 &&
              selectedRoomStyle !== "CATHEDRAL")) && (
          <DoorOption
            handleDoorOptionChange={handleDoorOptionChange}
            redBorderFields={redBorderFields}
            doorOption={doorOption}
            index={index}
            setDoorOption={setDoorOption}
            doorWidth={doorWidth}
            setDoorWidth={setDoorWidth}
            doorOptionMessage={`Door Option ${index + 1}`}
            setSelectedSwingDoorOrientation={setSelectedSwingDoorOrientation}
            setSelectedPatioDoorOrientation={setSelectedPatioDoorOrientation}
          />
        )}
        {selectedSunRoomType === "Linear Screen" &&
          doorOption[index] === "Yes" && (
            <LinearDoorLocation
              redBorderFields={redBorderFields}
              index={index}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              handleDoorLocationChange={handleDoorLocationChange}
              redFieldMessage={`Door Location ${index + 1}`}
              selectedBreezeWay={selectedBreezeWay}
              selectedPavilionLayoutOption={selectedPavilionLayoutOption}
            />
          )}
        {doorOption[index] === "Yes" && !isScreenRoom && (
          <DoorWidth
            redBorderFields={redBorderFields}
            index={index}
            doorWidth={doorWidth}
            handleDoorWidthChange={handleDoorWidthChange}
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
            selectedBreezeWay={selectedBreezeWay}
            wallLengthsFeet={wallLengthsFeet}
            selectedRoomStyle={selectedRoomStyle}
            selectedSunRoomType={selectedSunRoomType}
            setNewDoorWidth={setDoorWidth}
            redFieldMessage={`Door Width ${index + 1}`}
            setSelectedSwingDoor={setSelectedSwingDoorOrientation}
            setSelectedPatioDoor={setSelectedPatioDoorOrientation}
          />
        )}
        {condition && (
          <button
            className={getButtonClassName(index, doorWidth, redBorderFields)}
            style={{
              fontSize: "1.4vh",
              height: "8vh",
              width: "9vw",
              padding: "5%",
              alignSelf: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            onClick={
              doorWidth[index] === "3' Door" || doorWidth[index] === "3.5' Door"
                ? handleShowSwingDoorOrientationClick
                : handleShowPatioDoorOrientationClick
            }
          >
            {(doorWidth[index] === "3' Door" || doorWidth[index] === "3.5' Door"
              ? !selectedSwingDoorOrientation[index]
              : !selectedPatioDoorOrientation[index]) && (
              <span>
                Door Orientation <br />
                Options
              </span>
            )}
            {(doorWidth[index] === "3' Door" || doorWidth[index] === "3.5' Door"
              ? selectedSwingDoorOrientation[index]
              : selectedPatioDoorOrientation[index]) && (
              <span>
                You Selected <br />
                {doorWidth[index] === "3' Door" ||
                doorWidth[index] === "3.5' Door"
                  ? selectedSwingDoorOrientation[index]
                  : selectedPatioDoorOrientation[index]}
                <br />
              </span>
            )}
          </button>
        )}
        {wallLengthsFeet[index] &&
          wallLengthsInches[index] &&
          (wallLengthsFeet[index] <= 4 ||
            (doorOption[index] &&
              (doorOption[index] === "No" || doorWidth[index] !== ""))) &&
          selectedSunRoomType !== "Linear Screen" && (
            <button
              className={`navbutton ${redBorderFields.includes("Wall Code " + (index + 1)) ? "redBackground" : "greenBackground"}`}
              style={{
                fontSize: "1.4vh",
                height: "8vh",
                width: "9vw",
                padding: "5%",
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleViewDrawingsClick()}
            >
              View&nbsp;Drawings
            </button>
          )}
        {(selectedSunRoomType === "Upper Deck" ||
          selectedSunRoomType === "Vista View" ||
          selectedSunRoomType === "Solarium") &&
          isModalOpen && (
            <DrawingModal
              setIsModalOpen={setIsModalOpen}
              index={index}
              doorOption={doorOption}
              doorWidth={doorWidth}
              selectedRoomStyle={selectedRoomStyle}
              onCodeSelect={handleCodeSelect}
              setSelectedImages={setSelectedImages}
              selectedImages={selectedImages}
              selectedButtonIndex={selectedButtonIndex}
              setSelectedButtonIndex={setSelectedButtonIndex}
              wallLengthsFeet={wallLengthsFeet}
              selectedPavilionLayoutOption={selectedPavilionLayoutOption}
              selectedBreezeWay={selectedBreezeWay}
              initialSelectedCodes={selectedCodes}
              setNewSelectedCodes={setSelectedCodes}
              redBorderField={`Wall Code ${index + 1}`}
            />
          )}
        {selectedSunRoomType === "Premier View" && isModalOpen && (
          <PremierViewDrawingModal
            setIsModalOpen={setIsModalOpen}
            index={index}
            doorOption={doorOption}
            doorWidth={doorWidth}
            selectedRoomStyle={selectedRoomStyle}
            onCodeSelect={handleCodeSelect}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            selectedButtonIndex={selectedButtonIndex}
            setSelectedButtonIndex={setSelectedButtonIndex}
            wallLengthsFeet={wallLengthsFeet}
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
            selectedBreezeWay={selectedBreezeWay}
            initialSelectedCodes={selectedCodes}
            setNewSelectedCodes={setSelectedCodes}
            redBorderField={`Wall Code ${index + 1}`}
          />
        )}
        {selectedSunRoomType === "Vinyl View" && isModalOpen && (
          <VinylViewDrawingModal
            setIsModalOpen={setIsModalOpen}
            index={index}
            doorOption={doorOption}
            doorWidth={doorWidth}
            selectedRoomStyle={selectedRoomStyle}
            onCodeSelect={handleCodeSelect}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            selectedButtonIndex={selectedButtonIndex}
            setSelectedButtonIndex={setSelectedButtonIndex}
            wallLengthsFeet={wallLengthsFeet}
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
            selectedBreezeWay={selectedBreezeWay}
            initialSelectedCodes={selectedCodes}
            setNewSelectedCodes={setSelectedCodes}
            redBorderField={`Wall Code ${index + 1}`}
          />
        )}
        {selectedSunRoomType === "Value View" && isModalOpen && (
          <ValueViewDrawingModal
            setIsModalOpen={setIsModalOpen}
            index={index}
            doorOption={doorOption}
            doorWidth={doorWidth}
            selectedRoomStyle={selectedRoomStyle}
            onCodeSelect={handleCodeSelect}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            selectedButtonIndex={selectedButtonIndex}
            setSelectedButtonIndex={setSelectedButtonIndex}
            wallLengthsFeet={wallLengthsFeet}
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
            selectedBreezeWay={selectedBreezeWay}
            initialSelectedCodes={selectedCodes}
            setNewSelectedCodes={setSelectedCodes}
            redBorderField={`Wall Code ${index + 1}`}
          />
        )}
        {showSwingDoorOrientation && (
          <SwingDoorOrientation
            setSelectedSwingDoorOrientation={setSelectedSwingDoorOrientation}
            index={index}
            setshowSwingDoorOrientation={setshowSwingDoorOrientation}
            selectedSwingDoorOrientation={selectedSwingDoorOrientation}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            newIndex={newIndex}
            redFieldMessage={`Swing Door Orientation ${index + 1}`}
            handleSwingDoorOrientation={handleSwingDoorOrientation}
          />
        )}
        {showPatioDoorOrientation && (
          <PatioDoorOrientation
            index={index}
            setShowPatioDoorOrientation={setShowPatioDoorOrientation}
            selectedPatioDoorOrientation={selectedPatioDoorOrientation}
            setSelectedPatioDoorOrientation={setSelectedPatioDoorOrientation}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            redFieldMessage={`Patio Door Orientation ${index + 1}`}
            handleSelectedPatioDoorOrientation={
              handleSelectedPatioDoorOrientation
            }
            currentImage={currentImage}
          />
        )}
      </div>
    </div>
  );
}

SunRoomWallDrawing.propTypes = {
  index: PropTypes.number,
  doorOption: PropTypes.array,
  doorWidth: PropTypes.array,
  handleDoorOptionChange: PropTypes.func,
  handleDoorWidthChange: PropTypes.func,
  handleViewDrawingsClick: PropTypes.func,
  dimension: PropTypes.number,
  setIsOptionsModalVisible: PropTypes.func,
  selectedRoomStyle: PropTypes.string,
  setSelectedImages: PropTypes.func,
  selectedImages: PropTypes.array,
  setSelectedSwingDoorOrientation: PropTypes.func,
  setSelectedCodes: PropTypes.func,
  selectedCodes: PropTypes.array,
  selectedButtonIndex: PropTypes.array,
  setSelectedButtonIndex: PropTypes.func,
  selectedSwingDoorOrientation: PropTypes.array,
  selectedDoorHardware: PropTypes.string,
  setSelectedDoorHardware: PropTypes.func,
  selectedPatioDoorOrientation: PropTypes.array,
  setSelectedPatioDoorOrientation: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleWallLengthFeetChange: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  wallLengthsFeet: PropTypes.array,
  wallLengthsInches: PropTypes.array,
  SSHinges: PropTypes.string,
  handleSSHingesOptionChange: PropTypes.func,
  selectedPavilionLayoutOption: PropTypes.string,
  selectedBreezeWay: PropTypes.string,
  selectedSunRoomType: PropTypes.string,
  selectedRailSideOption: PropTypes.string,
  setWallLengthsFeet: PropTypes.func,
  setWallLengthsInches: PropTypes.func,
  setDoorOption: PropTypes.func,
  setDoorWidth: PropTypes.func,
  lastWallLengthUpdate: PropTypes.number,
  setLastWallLengthUpdate: PropTypes.func,
  redSwingFieldMessage: PropTypes.string,
  redPatioFieldMessage: PropTypes.string,
  handleCodeSelect: PropTypes.func,
  isScreenRoom: PropTypes.bool,
  setSelectedLocations: PropTypes.func,
  handleDoorLocationChange: PropTypes.func,
  selectedLocations: PropTypes.array,
  handleSelectedScreenDoorWidthChoice: PropTypes.func,
  handleSelectedPatioDoorOrientation: PropTypes.func,
  currentImage: PropTypes.string,
  handleSwingDoorOrientation: PropTypes.func,
};

export default SunRoomWallDrawing;
