import React, { useState, useEffect } from "react";
import FoundationSelection from "../UniversalComponents/PermitChoiceComponents/FoundationSelection";
import RoofMount from "../UniversalComponents/PermitChoiceComponents/RoofMount";
import KneeWallSelection from "../UniversalComponents/PermitChoiceComponents/KneeWallSelection";
import PropTypes from "prop-types";
import InfoIcon from "../UniversalComponents/InfoIcon";

const PermitChoice = ({
  onPermitSelection,
  handlePermitChange,
  handleKneeWallChange,
  handleFoundationChange,
  handleNewFoundationChange,
  handleExistingFoundationChange,
  handleGradeHeightOption,
  handleKneeWallFeetChange,
  handleKneeWallInchesChange,
  redBorderFields,
  setFieldsWithRedBorder,
  handleMountChange,
  handleStepDownChange,
  handleFasciaInchesOption,
  selectedMountOption,
  inchesRoofMountValue,
  feetRoofMountValue,
  selectedAttachedOption,
  selectedOperableOption,
}) => {
  const [selectedPermitOption, setSelectedPermitOption] = useState("");
  const [initialFoundationOption, setInitialFoundationOption] = useState("");
  const [initalKneeWallOption, setInitialKneeWallOption] = useState("");

  useEffect(() => {
    if (selectedAttachedOption !== "attached") {
      handleMountChange("");
      handleStepDownChange("");
      handleFasciaInchesOption("");
    }
  }, [
    selectedAttachedOption,
    handleMountChange,
    handleStepDownChange,
    handleFasciaInchesOption,
  ]);

  const handlePermitOptionChange = (selected) => {
    setSelectedPermitOption(selected);
    handlePermitChange(selected);

    const updatedRedBorderFields = redBorderFields.includes(
      "permit package option",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "permit package option")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "permit package option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    onPermitSelection(selected === "YES");

    if (selected !== "YES") {
      handleKneeWallChange("");
      handleFoundationChange("");
      handleGradeHeightOption("");
      handleKneeWallFeetChange("");
      handleKneeWallInchesChange("");
      handleExistingFoundationChange("");
      handleNewFoundationChange("");
      handleMountChange("");
      handleStepDownChange("");
      handleFasciaInchesOption("");
    }
  };

  return (
    <div>
      <div className="popupwindow permitchoices">
        <h6
          className={
            "headers " +
            (redBorderFields.includes("permit package option")
              ? "redBackground"
              : "greenBackground")
          }
        >
          <InfoIcon
            defaultMessage={
              "Drawing Package Includes:<br/>&nbsp;&nbsp;1. Elevation<br/>&nbsp;&nbsp;2. Plan View<br/>&nbsp;&nbsp;3. Roof Attachment<br/>&nbsp;&nbsp;4. Cross-Section<br/>&nbsp;&nbsp;5. Deck Layout(if new)<br/>&nbsp;&nbsp;6.  Bill of Materials<br/><br/>No Drawing Package Includes:<br/>&nbsp;&nbsp;1.  Bill of Materials"
            }
          />
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Drawing Package Choice
          </span>
        </h6>
        <div className="clickable-words">
          <div
            className={`clickable-word ${selectedPermitOption === "YES" ? "clicked" : ""}`}
            onClick={() => handlePermitOptionChange("YES")}
          >
            All Drawings Needed
          </div>
          <div
            className={`clickable-word ${selectedPermitOption === "NO" ? "clicked" : ""}`}
            onClick={() => handlePermitOptionChange("NO")}
          >
            No Drawings Needed
          </div>
        </div>
      </div>
      <div className="foundation-roofmount-kneewall">
        {selectedPermitOption === "YES" &&
          selectedAttachedOption === "attached" &&
          selectedOperableOption !== "MOTOR CITY" && (
            <>
              <FoundationSelection
                handleFoundationChange={handleFoundationChange}
                handleKneeWallChange={handleKneeWallChange}
                handleNewFoundationChange={handleNewFoundationChange}
                handleExistingFoundationChange={handleExistingFoundationChange}
                handleGradeHeightOption={handleGradeHeightOption}
                handleKneeWallFeetChange={handleKneeWallFeetChange}
                handleKneeWallInchesChange={handleKneeWallInchesChange}
                redBorderFields={redBorderFields}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                initialFoundationOption={initialFoundationOption}
                setInitialFoundationOption={setInitialFoundationOption}
                selectedAttachedOption={selectedAttachedOption}
              />
              <RoofMount
                selectedMountOption={selectedMountOption}
                inchesRoofMountValue={inchesRoofMountValue}
                feetRoofMountValue={feetRoofMountValue}
                handleMountChange={handleMountChange}
                handleStepDownChange={handleStepDownChange}
                handleFasciaInchesOption={handleFasciaInchesOption}
                redBorderFields={redBorderFields}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                selectedAttachedOption={selectedAttachedOption}
              />

              <KneeWallSelection
                handleKneeWallChange={handleKneeWallChange}
                handleKneeWallInchesChange={handleKneeWallInchesChange}
                handleKneeWallFeetChange={handleKneeWallFeetChange}
                redBorderFields={redBorderFields}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                initalKneeWallOption={initalKneeWallOption}
                setInitialKneeWallOption={setInitialKneeWallOption}
              />
            </>
          )}
      </div>
      <div className="roofmount-only">
        {selectedPermitOption === "YES" &&
          selectedAttachedOption === "FREE-STANDING" &&
          selectedOperableOption !== "MOTOR CITY" && (
            <>
              <FoundationSelection
                handleFoundationChange={handleFoundationChange}
                handleKneeWallChange={handleKneeWallChange}
                handleNewFoundationChange={handleNewFoundationChange}
                handleExistingFoundationChange={handleExistingFoundationChange}
                handleGradeHeightOption={handleGradeHeightOption}
                handleKneeWallFeetChange={handleKneeWallFeetChange}
                handleKneeWallInchesChange={handleKneeWallInchesChange}
                redBorderFields={redBorderFields}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                initialFoundationOption={initialFoundationOption}
                setInitialFoundationOption={setInitialFoundationOption}
                selectedAttachedOption={selectedAttachedOption}
              />
              <KneeWallSelection
                handleKneeWallChange={handleKneeWallChange}
                handleKneeWallInchesChange={handleKneeWallInchesChange}
                handleKneeWallFeetChange={handleKneeWallFeetChange}
                redBorderFields={redBorderFields}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                initalKneeWallOption={initalKneeWallOption}
                setInitialKneeWallOption={setInitialKneeWallOption}
              />
            </>
          )}
      </div>
      <div className="roofmount-only">
        {selectedPermitOption === "YES" &&
          selectedOperableOption === "MOTOR CITY" && (
            <FoundationSelection
              handleFoundationChange={handleFoundationChange}
              handleKneeWallChange={handleKneeWallChange}
              handleNewFoundationChange={handleNewFoundationChange}
              handleExistingFoundationChange={handleExistingFoundationChange}
              handleGradeHeightOption={handleGradeHeightOption}
              handleKneeWallFeetChange={handleKneeWallFeetChange}
              handleKneeWallInchesChange={handleKneeWallInchesChange}
              redBorderFields={redBorderFields}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              initialFoundationOption={initialFoundationOption}
              setInitialFoundationOption={setInitialFoundationOption}
              selectedAttachedOption={selectedAttachedOption}
            />
          )}
      </div>
    </div>
  );
};

PermitChoice.propTypes = {
  onPermitSelection: PropTypes.func,
  handlePermitChange: PropTypes.func,
  handleKneeWallChange: PropTypes.func,
  handleFoundationChange: PropTypes.func,
  handleNewFoundationChange: PropTypes.func,
  handleExistingFoundationChange: PropTypes.func,
  handleGradeHeightOption: PropTypes.func,
  handleKneeWallFeetChange: PropTypes.func,
  handleKneeWallInchesChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleMountChange: PropTypes.func,
  handleStepDownChange: PropTypes.func,
  handleFasciaInchesOption: PropTypes.func,
  selectedMountOption: PropTypes.string,
  inchesRoofMountValue: PropTypes.string,
  feetRoofMountValue: PropTypes.string,
  selectedAttachedOption: PropTypes.string,
  selectedOperableOption: PropTypes.string,
};

export default PermitChoice;
