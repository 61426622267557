import React, { useState } from "react";
import ImageButton from "../../UniversalComponents/ImageButton";
import PropTypes from "prop-types";

const TrimColorSelection = ({
  handleTrimChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedTrimOption, setSelectedTrimOption] = useState("");
  const white = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/white.jpg`;
  const sandstone = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/sandstone.jpg`;
  const bronze = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/bronze.jpg`;
  const black = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/black.jpg`;
  const images1 = [white, sandstone, bronze, black];
  const messages = ["White", "Sandstone", "Bronze", "Black"];

  const handleColorOptionChange = (selected) => {
    setSelectedTrimOption(selected);
    let updatedRedBorderFields = redBorderFields.includes("Trim Color")
      ? selected
        ? redBorderFields.filter((field) => field !== "Trim Color")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Trim Color"];
    handleTrimChange(selected);
    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  return (
    <div className="popupwindow trimcolor">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Trim Color")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <ImageButton images={images1} messages={messages} />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Trim Color
        </span>
      </h6>
      <div>
        <div className="clickable-words">
          <div
            className={`clickable-word ${selectedTrimOption === "SANDSTONE" ? "clicked" : ""}`}
            onClick={() => handleColorOptionChange("SANDSTONE")}
          >
            Sandstone
          </div>
          <div
            className={`clickable-word ${selectedTrimOption === "WHITE" ? "clicked" : ""}`}
            onClick={() => handleColorOptionChange("WHITE")}
          >
            White
          </div>
          &nbsp;
          <div
            className={`clickable-word ${selectedTrimOption === "BRONZE" ? "clicked" : ""}`}
            onClick={() => handleColorOptionChange("BRONZE")}
          >
            Bronze
          </div>
          &nbsp;
          <div
            className={`clickable-word ${selectedTrimOption === "BLACK" ? "clicked" : ""}`}
            onClick={() => handleColorOptionChange("BLACK")}
          >
            Black
          </div>
        </div>
      </div>
    </div>
  );
};

TrimColorSelection.propTypes = {
  handleTrimChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default TrimColorSelection;
