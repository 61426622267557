import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import InfoModal from "./InfoModal";

const Callback = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [userCompany, setUserCompany] = useState(null);
  const { isLoading } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserCompany = async () => {
      if (userCompany) return;
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      fetch("/api/DealerUserIDName/DealerName", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => setUserCompany(data.dealerName))
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    fetchUserCompany();
  }, [getAccessTokenSilently, userCompany]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!userCompany) {
    return (
      <>
        <div>Loading...</div>
        <button
          style={{ position: "absolute", top: "20%", right: "1%" }}
          className="navbutton"
          onClick={() => setIsModalOpen(true)}
        >
          How To
        </button>
        <InfoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </>
    );
  }

  return (
    <>
      <div className="callback">
        WELCOME!
        <br></br>
        {userCompany}
      </div>
      <button
        style={{ position: "absolute", top: "20%", right: "1%" }}
        className="navbutton"
        onClick={() => setIsModalOpen(true)}
      >
        How To
      </button>
      <InfoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default Callback;
