import React from "react";
import PropTypes from "prop-types";

function DoorOption({
  handleDoorOptionChange,
  redBorderFields,
  doorOption,
  index,
  setDoorOption,
  doorWidth,
  setDoorWidth,
  doorOptionMessage,
  setSelectedSwingDoorOrientation,
  setSelectedPatioDoorOrientation,
}) {
  return (
    <label
      className="popupwindow"
      style={{
        width: "9vw",
        alignSelf: "center",
        height: "8vh",
        color: "black",
        fontSize: "1.4vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h6
        className={
          "headers " +
          (redBorderFields.includes(`${doorOptionMessage}`)
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Door?
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${doorOption[index] === "Yes" ? "clicked" : ""}`}
          onClick={() =>
            handleDoorOptionChange(
              "Yes",
              index,
              setDoorOption,
              doorOption,
              doorWidth,
              setDoorWidth,
              doorOptionMessage,
              setSelectedSwingDoorOrientation,
              setSelectedPatioDoorOrientation,
            )
          }
        >
          Yes
        </div>
        &nbsp;
        <div
          className={`clickable-word ${doorOption[index] === "No" ? "clicked" : ""}`}
          onClick={() =>
            handleDoorOptionChange(
              "No",
              index,
              setDoorOption,
              doorOption,
              doorWidth,
              setDoorWidth,
              doorOptionMessage,
              setSelectedSwingDoorOrientation,
              setSelectedPatioDoorOrientation,
            )
          }
        >
          No
        </div>
      </div>
    </label>
  );
}

DoorOption.propTypes = {
  handleDoorOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  doorOption: PropTypes.array,
  index: PropTypes.number,
  setDoorOption: PropTypes.func,
  doorWidth: PropTypes.array,
  setDoorWidth: PropTypes.func,
  doorOptionMessage: PropTypes.string,
  setSelectedSwingDoorOrientation: PropTypes.func,
  setSelectedPatioDoorOrientation: PropTypes.func,
};

export default DoorOption;
