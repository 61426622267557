import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CurrentOrdersTable from "../../components/CurrentOrdersComponents/CurrentOrdersTable";
import PdfFilesTable from "../../components/CurrentOrdersComponents/PdfFilesTable";
import JSZip from "jszip";

function CurrentOrders() {
  const [confirmed, getConfirmed] = useState([]);
  const [processed_jobs, getProcessed] = useState([]);
  const [unprocessed, getUnprocessed] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [initialorders, getInitialOrders] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Confirmed");
  const [hasFetchedConfirmed, setHasFetchedConfirmed] = useState(false);
  const [hasFetchedProcessed, setHasFetchedProcessed] = useState(false);
  const [hasFetchedUnprocessed, setHasFetchedUnprocessed] = useState(false);
  const [hasFetchedInitialOrders, setHasFetchedInitialOrders] = useState(false);
  const [processedIsLoading, setProcessedIsLoading] = useState(false);
  const [unprocessedIsLoading, setUnprocessedIsLoading] = useState(false);
  const [initialOrdersIsLoading, setInitialOrdersIsLoading] = useState(false);
  const [confirmedIsLoading, setConfirmedIsLoading] = useState(false);
  const [isPdfsLoading, setIsPdfsLoading] = useState(false);
  const [currentProcess, setCurrentProcess] = useState(null);
  const [currentConfirm, setCurrentConfirm] = useState(null);
  const [isUserHide, setIsUserHide] = useState(true);

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const { getAccessTokenSilently } = useAuth0();

  const fetchConfirmed = useCallback(async () => {
    try {
      setConfirmedIsLoading(true);
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      const response = await fetch("/api/SQLThings/ConfirmedData", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error("Error fetching confirmed data:", response.statusText);
        setConfirmedIsLoading(false);
        return [];
      }

      const data = await response.json();
      setConfirmedIsLoading(false);

      return Object.values(data);
    } catch (error) {
      console.error("Error fetching confirmed data:", error);
      setConfirmedIsLoading(false);
      return [];
    }
  }, [getAccessTokenSilently]);

  const fetchProcessed = useCallback(async () => {
    try {
      setProcessedIsLoading(true);
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      const response = await fetch("/api/SQLThings/ProcessedData", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error("Error fetching processed data:", response.statusText);
        setProcessedIsLoading(false);
        return [];
      }

      const data = await response.json();
      setProcessedIsLoading(false);
      return Object.values(data);
    } catch (error) {
      console.error("Error fetching processed data:", error);
      setProcessedIsLoading(false);
      return [];
    }
  }, [getAccessTokenSilently]);

  const fetchUnprocessed = useCallback(async () => {
    try {
      setUnprocessedIsLoading(true);
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      const response = await fetch("/api/TxtThings/unprocessedFiles", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error("Error fetching unprocessed data:", response.statusText);
        setUnprocessedIsLoading(false);
        return [];
      }

      const data = await response.json();
      setUnprocessedIsLoading(false);
      return Object.values(data);
    } catch (error) {
      console.error("Error fetching unprocessed data:", error);
      setUnprocessedIsLoading(false);
      return [];
    }
  }, [getAccessTokenSilently]);

  const fetchInitialOrders = useCallback(async () => {
    try {
      setInitialOrdersIsLoading(true);
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      const response = await fetch("/api/TxtThings/initialOrders", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error(
          "Error fetching initial orders data:",
          response.statusText,
        );
        setInitialOrdersIsLoading(false);
        return [];
      }

      const data = await response.json();
      setInitialOrdersIsLoading(false);
      return Object.values(data);
    } catch (error) {
      console.error("Error fetching initial orders data:", error);
      setInitialOrdersIsLoading(false);
      return [];
    }
  }, [getAccessTokenSilently]);

  const fetchPdfFiles = useCallback(
    async (confirm) => {
      try {
        let fileName = "defaultFileName";
        let folderName = "defaultFolderName";
        if (confirm && confirm.FileName && confirm.Folder) {
          fileName = confirm.FileName;
          folderName = confirm.Folder;
        } else {
          console.error(
            "Invalid or missing FileName or Folder in the confirm object.",
          );
          return null;
        }

        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: "TEMOAuth",
          },
        });

        const res = await fetch(
          `/api/TxtThings/pdf/?folderName=${folderName}&fileNames=${fileName}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (res.ok) {
          const blob = await res.blob();
          const zip = await JSZip.loadAsync(blob);
          const files = Object.keys(zip.files);
          return { confirm, files };
        } else {
          console.error("Error fetching PDF files:", res.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching and extracting ZIP:", error);
        return null;
      }
    },
    [getAccessTokenSilently],
  );

  const fetchProcessedPdfFiles = useCallback(
    async (process) => {
      try {
        let fileName = "defaultFileName";
        let folderName = "defaultFolderName";
        if (process && process.FileName && process.FolderName) {
          fileName = process.FileName;
          folderName = process.FolderName;
        } else {
          console.error(
            "Invalid or missing FileName or Folder in the process object.",
          );
          return null;
        }

        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: "TEMOAuth",
          },
        });

        const res = await fetch(
          `/api/TxtThings/pdf/?folderName=${folderName}&fileNames=${fileName}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (res.ok) {
          const blob = await res.blob();
          const zip = await JSZip.loadAsync(blob);
          const files = Object.keys(zip.files);
          return { process, files };
        } else {
          console.error("Error fetching PDF files:", res.statusText);
        }
      } catch (error) {
        console.error("Error fetching and extracting ZIP:", error);
        return null;
      }
    },
    [getAccessTokenSilently],
  );

  useEffect(() => {
    setLoading(true);
    switch (selectedTab) {
      case "Confirmed":
        if (confirmed.length === 0 && !hasFetchedConfirmed) {
          fetchConfirmed()
            .then((confirmedResponse) => {
              getConfirmed(confirmedResponse);
              setLoading(false);
              setHasFetchedConfirmed(true);
            })
            .catch((error) => {
              setError("Error fetching data");
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
        break;
      case "Processed":
        if (processed_jobs.length === 0 && !hasFetchedProcessed) {
          fetchProcessed()
            .then((processedResponse) => {
              getProcessed(processedResponse);
              setLoading(false);
              setHasFetchedProcessed(true);
            })
            .catch((error) => {
              setError("Error fetching data");
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
        break;
      case "Unprocessed":
        if (unprocessed.length === 0 && !hasFetchedUnprocessed) {
          fetchUnprocessed()
            .then((unprocessedResponse) => {
              getUnprocessed(unprocessedResponse);
              setLoading(false);
              setHasFetchedUnprocessed(true);
            })
            .catch((error) => {
              setError("Error fetching data");
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
        break;
      case "Initial Orders":
        if (initialorders.length === 0 && !hasFetchedInitialOrders) {
          fetchInitialOrders()
            .then((initialordersResponse) => {
              getInitialOrders(initialordersResponse);
              setLoading(false);
              setHasFetchedInitialOrders(true);
            })
            .catch((error) => {
              setError("Error fetching data");
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
        break;
      default:
        break;
    }
  }, [
    confirmed,
    fetchConfirmed,
    fetchInitialOrders,
    fetchProcessed,
    fetchUnprocessed,
    initialorders,
    processed_jobs,
    selectedTab,
    unprocessed,
    hasFetchedConfirmed,
    hasFetchedInitialOrders,
    hasFetchedProcessed,
    hasFetchedUnprocessed,
  ]);

  const handleShowPdfFilesTable = async (confirm) => {
    if (!confirm || typeof confirm.FileName === "undefined") {
      console.error("Invalid or missing FileName in the confirm object.");
      return;
    }

    setCurrentConfirm((prevConfirm) => {
      if (prevConfirm === confirm) {
        setFilesList([]);
        setIsUserHide(true);
        return null;
      }

      setIsUserHide(false);
      return confirm;
    });

    if (currentConfirm === confirm) {
      return;
    }

    try {
      setIsPdfsLoading(true);
      const pdfData = await fetchPdfFiles(confirm);

      if (pdfData) {
        setIsPdfsLoading(false);
        setFilesList([{ confirm, files: pdfData.files }]);
      } else {
        setIsPdfsLoading(false);
        setFilesList([]);
        console.error("Error fetching PDF files.");
      }
    } catch (error) {
      setIsPdfsLoading(false);
      setFilesList([]);
      console.error("An error occurred while handling PDF files:", error);
    }
  };

  const handleShowPdfFilesProcess = async (process) => {
    if (!process || typeof process.FileName.toString() === "undefined") {
      console.error("Invalid or missing FileName in the confirm object.");
      return;
    }

    setCurrentProcess((prevProcess) => {
      if (prevProcess === process) {
        setFilesList([]);
        setIsUserHide(true);
        return null;
      }

      setIsUserHide(false);
      return process;
    });

    if (currentProcess === process) {
      return;
    }

    try {
      setIsPdfsLoading(true);
      const pdfData = await fetchProcessedPdfFiles(process);

      if (pdfData) {
        setIsPdfsLoading(false);
        setFilesList([{ process, files: pdfData.files }]);
      } else {
        setIsPdfsLoading(false);
        setFilesList([]);
        console.error("Error fetching PDF files.");
      }
    } catch (error) {
      setIsPdfsLoading(false);
      setFilesList([]);
      console.error("An error occurred while handling PDF files:", error);
    }
  };

  return (
    <div className="app-page" style={{}}>
      <div style={{ height: "85vh", width: "75vw" }}>
        <CurrentOrdersTable
          confirmed={confirmed}
          processed={processed_jobs}
          unprocessed={unprocessed}
          initialorders={initialorders}
          handleShowPdfFilesProcess={handleShowPdfFilesProcess}
          handleShowPdfFilesTable={handleShowPdfFilesTable}
          handleTabClick={handleTabClick}
          confirmedIsLoading={confirmedIsLoading}
          processedIsLoading={processedIsLoading}
          unprocessedIsLoading={unprocessedIsLoading}
          initialOrdersIsLoading={initialOrdersIsLoading}
          currentProcess={currentProcess}
          currentConfirm={currentConfirm}
          setFilesList={setFilesList}
          setIsUserHide={setIsUserHide}
        />
      </div>
      {(selectedTab === "Confirmed" || selectedTab === "Processed") && (
        <div
          style={{
            height: "85vh",
            width: "15%",
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "3%",
            marginTop: "20vh",
          }}
        >
          <PdfFilesTable
            filesList={filesList}
            isPdfsLoading={isPdfsLoading}
            isUserHide={isUserHide}
          />
        </div>
      )}
    </div>
  );
}

export default CurrentOrders;
