import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import InfoIcon from "./InfoIcon";

const RoofPanelColors = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleRoofPanelColorOption,
  selectedSkyviewChoice,
  selectedRoofDensity,
  selectedShingle,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [brandnewmessage, setBrandNewMessage] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const modalRoot = document.getElementById("modal-root");

  const handleButtonClick = () => {
    setIsModalVisible((prevIsModalVisible) => !prevIsModalVisible);

    if (
      !isModalVisible &&
      !selectedMessage &&
      !redBorderFields.includes("RoofPanelColors")
    ) {
      setFieldsWithRedBorder((prevRedBorderFields) => [
        ...prevRedBorderFields,
        "RoofPanelColors",
      ]);
    }
  };

  const handleRoofPanelColors = useCallback(
    (message) => {
      if (selectedSkyviewChoice === "Skyview YES") {
        setSelectedMessage("");
        handleRoofPanelColorOption("");
      } else if (
        selectedRoofDensity === '4 1/4" 0.032 1 pound' ||
        selectedRoofDensity === '4 1/4" 0.032 2 pound'
      ) {
        setSelectedMessage("");
        handleRoofPanelColorOption("");
      } else {
        setSelectedMessage(message);
        handleRoofPanelColorOption(message);
      }

      if (redBorderFields.includes("RoofPanelColors")) {
        setFieldsWithRedBorder((prevRedBorderFields) =>
          prevRedBorderFields.filter((field) => field !== "RoofPanelColors"),
        );
      }
    },
    [
      selectedSkyviewChoice,
      selectedRoofDensity,
      redBorderFields,
      setFieldsWithRedBorder,
      handleRoofPanelColorOption,
    ],
  );
  let style = { fontSize: windowWidth > 1700 ? "1.7vh" : "1.5vh" };
  let roofcolormessage = <span>Roof{"\u00A0"}Panel Color</span>;
  let disabled = false;

  if (selectedRoofDensity === '4 1/4" 0.032 1 pound') {
    style = {
      backgroundColor: "grey",
      border: "grey",
      color: "yellow",
      fontSize: windowWidth > 1700 ? "1.7vh" : "1.5vh",
    };
    roofcolormessage = (
      <span>
        Defaulted
        <br />
        to
        <br />
        White
      </span>
    );
    disabled = true;
  }
  if (selectedRoofDensity === '4 1/4" 0.032 2 pound') {
    style = {
      backgroundColor: "grey",
      border: "grey",
      color: "yellow",
      fontSize: windowWidth > 1700 ? "1.7vh" : "1.5vh",
    };
    roofcolormessage = (
      <span>
        Defaulted
        <br />
        to
        <br />
        White
      </span>
    );
    disabled = true;
  }
  if (selectedSkyviewChoice === "Skyview YES") {
    style = {
      backgroundColor: "grey",
      border: "grey",
      color: "yellow",
      fontSize: windowWidth > 1700 ? "1.7vh" : "1.5vh",
    };
    roofcolormessage = (
      <span>
        Defaulted
        <br />
        to
        <br />
        White
      </span>
    );
    disabled = true;
  }

  useEffect(() => {
    if (
      selectedSkyviewChoice === "Skyview YES" ||
      selectedRoofDensity === '4 1/4" 0.032 1 pound' ||
      selectedRoofDensity === '4 1/4" 0.032 2 pound'
    ) {
      handleRoofPanelColors("");
    }
    if (
      selectedRoofDensity === '4 1/4" 0.032 1 pound' ||
      selectedRoofDensity === '4 1/4" 0.032 2 pound'
    ) {
      setBrandNewMessage(
        "The roof panel can only be White with 0.032 Roof Skin.",
      );
    }
    if (selectedSkyviewChoice === "Skyview YES") {
      setBrandNewMessage(
        "The roof panel can only be White if there is a skyview.",
      );
    }
  }, [selectedSkyviewChoice, selectedRoofDensity, handleRoofPanelColors]);

  return (
    <div
      style={{
        gridColumn: "3",
        gridRow: "1",
        display: "flex",
        justifySelf: "start",
        alignSelf: "center",
        marginTop: "10vh",
        paddingTop: "1%",
        width: "7vw",
        height: "fit-content",
        marginLeft: "-3vw",
      }}
    >
      <div style={{ cursor: "pointer", width: "7vw" }}>
        <div
          style={{
            width: "fit-content",
            height: "fit-content",
            backgroundColor: "white",
            position: "relative",
          }}
        >
          <button
            className={`navbutton ${redBorderFields.includes("RoofPanelColors") ? "redBackground" : "greenBackground"}`}
            onClick={handleButtonClick}
            style={{
              ...style,
              width: "6.2vw",
              overflow: "hidden",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            disabled={disabled}
          >
            {roofcolormessage}
            {(selectedRoofDensity === '4 1/4" 0.032 1 pound' ||
              selectedRoofDensity === '4 1/4" 0.032 2 pound' ||
              selectedSkyviewChoice === "Skyview YES") && (
              <span style={{ position: "absolute", top: "1%", right: "1%" }}>
                <InfoIcon defaultMessage={brandnewmessage} />
              </span>
            )}
          </button>
        </div>
      </div>

      {isModalVisible &&
        ReactDOM.createPortal(
          <div className="modal">
            <div
              className="modal-content"
              style={{ width: "fit-content", height: "fit-content" }}
            >
              {!selectedMessage && (
                <button
                  type="button"
                  className="showpdfbuttons"
                  style={{
                    position: "absolute",
                    top: "1%",
                    right: "1%",
                    cursor: "pointer",
                    width: "auto",
                    height: "auto",
                  }}
                  onClick={handleButtonClick}
                >
                  X
                </button>
              )}
              {selectedMessage && (
                <button
                  type="button"
                  className="showpdfbuttons"
                  style={{
                    position: "absolute",
                    top: "1%",
                    right: "1%",
                    cursor: "pointer",
                    width: "auto",
                    height: "auto",
                  }}
                  onClick={handleButtonClick}
                >
                  Done
                </button>
              )}
              <b style={{ textAlign: "center" }}>
                <span style={{ color: "red" }}>&#10033;</span>IF ROOF SKIN
                THICKNESS IS<span style={{ color: "red" }}>&#10033;</span>
                <br />
                <span style={{ color: "red" }}>&#10033;</span>
                <u>0.032</u> ROOF PANEL COLOR CAN ONLY BE WHITE
                <span style={{ color: "red" }}>&#10033;</span>
              </b>
              <div className="options-container">
                <div className="clickable-words">
                  <div
                    className={`clickable-word ${selectedMessage === "White Top, White Bottom" ? "clicked" : ""}`}
                    onClick={() =>
                      handleRoofPanelColors("White Top, White Bottom")
                    }
                  >
                    White Top, White Bottom
                  </div>
                  {selectedShingle !== "YES" && (
                    <div
                      className={`clickable-word ${selectedMessage === "Sandstone Top, Sandstone Bottom" ? "clicked" : ""}`}
                      onClick={() =>
                        handleRoofPanelColors("Sandstone Top, Sandstone Bottom")
                      }
                    >
                      Sandstone Top, Sandstone Bottom
                    </div>
                  )}
                </div>
                <div className="clickable-words">
                  <div
                    className={`clickable-word ${selectedMessage === "White Top, Sandstone Bottom" ? "clicked" : ""}`}
                    onClick={() =>
                      handleRoofPanelColors("White Top, Sandstone Bottom")
                    }
                  >
                    White Top, Sandstone Bottom
                  </div>
                  <div
                    className={`clickable-word ${selectedMessage === "Sandstone Top, White Bottom" ? "clicked" : ""}`}
                    onClick={() =>
                      handleRoofPanelColors("Sandstone Top, White Bottom")
                    }
                  >
                    Sandstone Top, White Bottom
                  </div>
                </div>
              </div>
            </div>
          </div>,
          modalRoot,
        )}
    </div>
  );
};

RoofPanelColors.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleRoofPanelColorOption: PropTypes.func,
  selectedSkyviewChoice: PropTypes.string,
  selectedRoofDensity: PropTypes.string,
  selectedShingle: PropTypes.string,
};

export default RoofPanelColors;
