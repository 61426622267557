import React from "react";

const WelcomePage = () => (
  <div className="welcome-container">
    <h1>Welcome Page and Navigation</h1>
    <div className="welcome-section">
      <h2>Welcome Page</h2>
      <p>
        After logging in, you will see the welcome page. To return here at any
        time, simply click the “TEMO” icon at the top of the page.
      </p>
    </div>
    <div className="welcome-section">
      <h2>Make an Order</h2>
      <p>Click the “Make an order” button to start placing a new order.</p>
    </div>
    <div className="welcome-section">
      <h2>Current Orders</h2>
      <p>
        Press the “Current Orders” button to view all your recently placed
        orders until they are shipped.
      </p>
    </div>
    <div className="welcome-section">
      <h2>Order History</h2>
      <p>
        Click the “Order history” button to see all orders that have been
        shipped to you.
      </p>
    </div>
    <div className="welcome-section">
      <h2>Log Out</h2>
      <p>Click “Log Out” to exit the portal.</p>
    </div>
    <div className="welcome-section">
      <h2>How To</h2>
      <p>Click the “How To” button to view this information again.</p>
    </div>
  </div>
);

export default WelcomePage;
