import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const OrderHistoryRow = ({
  order,
  handleShowOrderHistoryPdfTable,
  handleButtonClick,
  isButtonClicked,
  currentOrder,
}) => {
  const [newAddress, setNewAddress] = useState("");
  const [newCityStateZip, setNewCityStateZip] = useState("");

  useEffect(() => {
    if (order.Address) {
      let address =
        typeof order.Address === "object"
          ? JSON.stringify(order.Address)
          : order.Address;
      address = address.trim();
      if (
        address !== "{}" &&
        address !== "null" &&
        address !== "" &&
        address !== "."
      ) {
        setNewAddress(address);
      }
    }

    if (order.CityStateZip) {
      let cityStateZip =
        typeof order.CityStateZip === "object"
          ? JSON.stringify(order.CityStateZip)
          : order.CityStateZip;
      cityStateZip = cityStateZip.trim();
      if (
        cityStateZip !== "{}" &&
        cityStateZip !== "null" &&
        cityStateZip !== "" &&
        cityStateZip !== "."
      ) {
        setNewCityStateZip(cityStateZip);
      }
    }
  }, [order]);

  return (
    <tr style={{ width: "100%" }}>
      <td>{order.Folder}</td>
      <td>{order.FileName}</td>
      <td>{order.JobName}</td>
      <td>{order.DateShipped}</td>
      <td>{newAddress}</td>
      <td>{newCityStateZip}</td>
      <td>
        <button
          type="button"
          className="showpdfbuttons"
          onClick={() => {
            handleButtonClick();
            handleShowOrderHistoryPdfTable(order);
          }}
        >
          {isButtonClicked && currentOrder === order
            ? "Hide PDFs"
            : "Show PDFs"}
        </button>
      </td>
    </tr>
  );
};

OrderHistoryRow.propTypes = {
  order: PropTypes.object,
  handleShowOrderHistoryPdfTable: PropTypes.func,
  handleButtonClick: PropTypes.func,
  isButtonClicked: PropTypes.bool,
  currentOrder: PropTypes.object,
};

export default OrderHistoryRow;
