import React from "react";

const CurrentOrdersPage = () => (
  <div className="current-orders-container">
    <h1>Viewing Current Orders</h1>
    <div className="current-orders-section">
      <h2>Confirmed</h2>
      <p>Orders that you have confirmed.</p>
    </div>
    <div className="current-orders-section">
      <h2>Processed</h2>
      <p>Orders that have been drawn up but are not yet confirmed.</p>
    </div>
    <div className="current-orders-section">
      <h2>Unprocessed</h2>
      <p>Orders in the queue that have not been drawn.</p>
    </div>
    <div className="current-orders-section">
      <h2>Initial Orders</h2>
      <p>Printouts of your unprocessed orders.</p>
    </div>
    <div className="current-orders-section">
      <h2>Show PDFs</h2>
      <p>
        In the Confirmed and Processed tabs, click the “Show PDFs” button to
        view all attached PDFs for an order, such as:
      </p>
      <ul className="pdf-list">
        <li>BOM</li>
        <li>Parts List</li>
        <li>Elevation</li>
        <li>Cross-Section</li>
        <li>Deck</li>
      </ul>
    </div>
    <div className="current-orders-section">
      <h2>Show File</h2>
      <p>
        In the Initial Orders tab, click the “Show File” button to open your
        printout.
      </p>
    </div>
  </div>
);

export default CurrentOrdersPage;
