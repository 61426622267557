import React, { useState } from "react";
import PropTypes from "prop-types";

const ScreenDoorWidth = ({
  handleSelectedScreenDoorWidthChoice,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedScreenDoorWidthOption, setSelectedScreenDoorWidthOption] =
    useState("");

  const handleScreenDoorWidthOptionChange = (selected) => {
    setSelectedScreenDoorWidthOption(selected);
    const updatedRedBorderFields = redBorderFields.includes("Screen Door Width")
      ? selected
        ? redBorderFields.filter((field) => field !== "Screen Door Width")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Screen Door Width"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedScreenDoorWidthChoice(selected);
  };

  return (
    <div className="popupwindow" style={{ flex: 1 }}>
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Screen Door Width")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Door Width
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedScreenDoorWidthOption === "3' Door" ? "clicked" : ""}`}
          onClick={() => handleScreenDoorWidthOptionChange("3' Door")}
        >
          3&apos; Door
        </div>
        <div
          className={`clickable-word ${selectedScreenDoorWidthOption === "3.5' Door" ? "clicked" : ""}`}
          onClick={() => handleScreenDoorWidthOptionChange("3.5' Door")}
        >
          3.5&apos; Door
        </div>
      </div>
    </div>
  );
};

ScreenDoorWidth.propTypes = {
  handleSelectedScreenDoorWidthChoice: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default ScreenDoorWidth;
