import React from "react";
import PropTypes from "prop-types";

const ClickMeOrValues = ({
  gridRow,
  gridColumn,
  id,
  clickMeImage,
  values,
  selectedSunRoomType,
}) => {
  const hasNonEmptyValues =
    values &&
    values.some((value) => typeof value === "string" && value.trim() !== "");
  return (
    <div
      id={id}
      style={{
        gridColumn,
        gridRow,
        justifySelf: "center",
        alignSelf: "center",
        width: "11vh",
        height: "11vh",
      }}
    >
      {!hasNonEmptyValues && (
        <img
          style={{ width: "inherit", height: "auto" }}
          src={clickMeImage}
          alt="Click Me"
        />
      )}
      {hasNonEmptyValues && (
        <div
          className="popupwindow"
          style={{
            display: "grid",
            gridTemplateRows: "repeat(4, 1fr)",
            gridTemplateColumns: "1fr",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {values.map((value, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.4vh",
                textAlign: "center",
                backgroundColor:
                  (index === 3 &&
                    values[2] !== "" &&
                    values[2] !== "No Door" &&
                    !values[3] &&
                    selectedSunRoomType !== "Linear Screen") ||
                  (index === 3 &&
                    values[1] !== "" &&
                    values[1] !== "No Door" &&
                    !values[3] &&
                    selectedSunRoomType === "Linear Screen") ||
                  (index === 2 &&
                    selectedSunRoomType === "Linear Screen" &&
                    values[1] !== "" &&
                    values[1] !== "No Door" &&
                    !values[2]) ||
                  (index !== 3 && index !== 2 && value === "")
                    ? "red"
                    : "white",
                borderTop: index !== 0 ? "1px solid black" : "none",
              }}
            >
              <span style={{ flex: 1 }}>
                {!value && (
                  <span>
                    {index === 0 && selectedSunRoomType !== "Linear Screen" && (
                      <span>Code</span>
                    )}
                    {index === 0 && selectedSunRoomType === "Linear Screen" && (
                      <span>Length</span>
                    )}
                    {index === 1 && selectedSunRoomType !== "Linear Screen" && (
                      <span>Length</span>
                    )}
                    {index === 1 && selectedSunRoomType === "Linear Screen" && (
                      <span>Door</span>
                    )}

                    {index === 2 && selectedSunRoomType !== "Linear Screen" && (
                      <span>Door</span>
                    )}
                    {index === 2 &&
                      values[1] !== "No Door" &&
                      values[1] !== "" &&
                      selectedSunRoomType === "Linear Screen" && (
                        <span>Location</span>
                      )}

                    {index === 3 &&
                      values[2] !== "No Door" &&
                      values[2] !== "" &&
                      selectedSunRoomType !== "Linear Screen" && (
                        <span>Orientation</span>
                      )}
                    {index === 3 &&
                      values[1] !== "No Door" &&
                      values[1] !== "" &&
                      selectedSunRoomType === "Linear Screen" && (
                        <span>Orientation</span>
                      )}
                  </span>
                )}
                {value && <span>{value}</span>}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ClickMeOrValues.propTypes = {
  gridRow: PropTypes.string,
  gridColumn: PropTypes.string,
  clickMeImage: PropTypes.string,
  id: PropTypes.string,
  values: PropTypes.array,
  selectedSunRoomType: PropTypes.string,
};

export default ClickMeOrValues;
