import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";

const Tabs = forwardRef(
  (
    {
      children,
      handleTabClick,
      searchTerm,
      setSearchTerm,
      setFilesList,
      setIsUserHide,
    },
    ref,
  ) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label);

    const onClickTabItem = (tab) => {
      setIsUserHide(true);
      setFilesList([]);
      setActiveTab(tab);
      handleTabClick(tab);
    };

    return (
      <div className="tabs">
        <div style={{ display: "flex" }}>
          <ol className="tab-list" style={{ flex: 3 }}>
            {children.map((child) => {
              const { label } = child.props;

              return (
                <Tab
                  ref={ref}
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                />
              );
            })}
          </ol>
          <div
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              flex: 1,
              marginLeft: "auto",
              bottom: 0,
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "100%", height: "80%" }}
            />
          </div>
        </div>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  },
);

Tabs.displayName = "Tabs";

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array),
  handleTabClick: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  setFilesList: PropTypes.func,
  setIsUserHide: PropTypes.func,
};

export default Tabs;
