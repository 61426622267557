import React, { useState } from "react";
import ColorDropdown from "./SunroomColorsComponents/ColorDropDown";
import PropTypes from "prop-types";
import InfoIcon from "../UniversalComponents/InfoIcon";

const ACWindows = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleACLeftOption,
  handleACCenterOption,
  handleACRightOption,
}) => {
  const [leftProjValue, setLeftProjValue] = useState("");
  const [centerValue, setCenterValue] = useState("");
  const [rightProjValue, setRightProjValue] = useState("");
  const [noneChecked, setNoneChecked] = useState(false); // State to manage checkbox checked status

  const handleDropdownChange = (id, value) => {
    setNoneChecked(false); // Uncheck the checkbox when any dropdown changes
    switch (id) {
      case "LeftProj":
        setLeftProjValue(value);
        handleACLeftOption(value);
        break;
      case "FrontWall":
        setCenterValue(value);
        handleACCenterOption(value);
        break;
      case "RightProj":
        setRightProjValue(value);
        handleACRightOption(value);
        break;
      default:
        break;
    }

    if (value) {
      setFieldsWithRedBorder((prev) => prev.filter((field) => field !== id));
    } else {
      setFieldsWithRedBorder((prev) => [...new Set([...prev, id])]);
    }
  };

  const handleNoneButtonClick = (e) => {
    const isChecked = e.target.checked;
    setNoneChecked(isChecked); // Update the checkbox state based on click
    const newValue = isChecked ? "Do Not Need" : ""; // If checked, set to "Do Not Need", else clear the value

    setLeftProjValue(newValue);
    setCenterValue(newValue);
    setRightProjValue(newValue);

    // Call the handlers with the new value
    handleACLeftOption(newValue);
    handleACCenterOption(newValue);
    handleACRightOption(newValue);

    // Update fields with red border based on the new value
    if (isChecked) {
      setFieldsWithRedBorder((prev) =>
        prev.filter(
          (field) => !["LeftProj", "FrontWall", "RightProj"].includes(field),
        ),
      );
    }
  };

  return (
    <div className="popupwindow" style={{ width: "100%", marginBottom: "1%" }}>
      <h6 className="headers greenBackground">
        <InfoIcon
          defaultMessage={
            "The sill height will be raised to 26.0 to accommodate an air conditioning unit or doggy door."
          }
        />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          A/C Window
        </span>
      </h6>
      <form>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ColorDropdown
            id="LeftProj"
            onChange={(e) => handleDropdownChange("LeftProj", e.target.value)}
            options={[
              "At House",
              "Centered",
              "Away From House",
              "Do Not Need",
              "Other Location",
            ]}
            title="Left Proj Location"
            redBorderFields={redBorderFields}
            value={leftProjValue}
            otherTitle={"L"}
          />
          <ColorDropdown
            id="RightProj"
            onChange={(e) => handleDropdownChange("RightProj", e.target.value)}
            options={[
              "At House",
              "Centered",
              "Away From House",
              "Do Not Need",
              "Other Location",
            ]}
            title="Right Proj Location"
            redBorderFields={redBorderFields}
            value={rightProjValue}
            otherTitle={"R"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <ColorDropdown
            id="FrontWall"
            onChange={(e) => handleDropdownChange("FrontWall", e.target.value)}
            options={[
              "At Left Projection",
              "Centered",
              "At Right Projection",
              "Do Not Need",
              "Other Location",
            ]}
            title="Front Wall Location"
            redBorderFields={redBorderFields}
            value={centerValue}
            otherTitle={"F"}
          />
          <label
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "1.5vh",
              justifyContent: "center",
              width: "100%",
            }}
          >
            None
            <input
              type="checkbox"
              checked={noneChecked}
              onChange={handleNoneButtonClick}
              style={{ marginLeft: "7%" }}
            />
          </label>
        </div>
      </form>
    </div>
  );
};

ACWindows.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleACLeftOption: PropTypes.func,
  handleACCenterOption: PropTypes.func,
  handleACRightOption: PropTypes.func,
};

export default ACWindows;
