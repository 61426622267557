import React, { useState } from "react";
import PropTypes from "prop-types";
import WelcomePage from "./HowToPages/WelcomePage";
import PlacingOrderPage from "./HowToPages/PlacingAnOrder";
import CurrentOrdersPage from "./HowToPages/ViewingCurrentOrders";
import OrderHistoryPage from "./HowToPages/ViewingPastOrders";

const InfoModal = ({ isOpen, onClose }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const pages = [
    <WelcomePage key="page1" />,
    <PlacingOrderPage key="page2" />,
    <CurrentOrdersPage key="page3" />,
    <OrderHistoryPage key="page4" />,
  ];

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, pages.length - 1));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  return (
    <div
      style={{
        gridColumn: "3",
        gridRow: "1",
        display: "flex",
        justifySelf: "start",
        alignSelf: "end",
        paddingTop: "1%",
        width: "7vw",
        height: "fit-content",
        marginLeft: "-3vw",
      }}
    >
      {isOpen && (
        <div className="modal">
          <div
            className="popupwindow"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              paddingBottom: "1%",
              width: "80vw",
              height: "90vh",
            }}
          >
            <div
              style={{
                width: "fit-content",
                height: "fit-content",
                border: "solid white 2px",
                position: "absolute",
                top: "1%",
                right: "0",
                padding: "2px",
              }}
            >
              <button
                type="button"
                className="showpdfbuttons"
                style={{ cursor: "pointer", width: "auto", height: "auto" }}
                onClick={onClose}
              >
                X
              </button>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                overflow: "auto",
                flexDirection: "column",
              }}
            >
              <h2
                className="greenBackground"
                style={{
                  marginBottom: "2vh",
                  width: "100%",
                  height: "15%",
                  padding: "1%",
                  color: "White",
                  fontSize: "3.5vh",
                }}
              >
                Welcome to the TEMO Web Ordering Portal!<br></br> Here&apos;s a
                brief overview of how to navigate and utilize the features
                available to you.
              </h2>
              {pages[currentPage]}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <button
                className="navbutton"
                style={{ fontSize: "2vh", marginLeft: "1%" }}
                onClick={handlePrevPage}
              >
                Previous
              </button>
              <span>{currentPage + 1} of 4</span>
              <button
                className="navbutton"
                style={{ fontSize: "2vh", marginRight: "1%" }}
                onClick={handleNextPage}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

InfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default InfoModal;
