import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import TemoLogo from "./Logo";
import ScrollingBar from "./ScrollingBar";

const NavButtons = () => {
  const { logout } = useAuth0();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "10vh",
      }}
    >
      <nav
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TemoLogo />
        <Link to="/Ordering">
          <button type="button" className="navbutton">
            Make An Order
          </button>
        </Link>
        <Link to="/CurrentOrders">
          <button type="button" className="navbutton">
            Current Orders
          </button>
        </Link>
        <Link to="/OrderHistory">
          <button type="button" className="navbutton">
            Order History
          </button>
        </Link>
        <button
          type="button"
          className="navbutton"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Log Out
        </button>
      </nav>
      <ScrollingBar />
    </div>
  );
};

export default NavButtons;
