import React, { useState } from "react";
import AscendingDescendingButton from "../ascending_descending";
import ProcessedRow from "./ProcessedRow";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const ProcessedTable = ({
  processedIsLoading,
  processed,
  processedData,
  handleShowPdfFilesProcess,
  setProcessedData,
  setSortProcessedByName,
  sortProcessedByName,
  setSortProcessedByDateCreated,
  sortProcessedByDateCreated,
  currentProcess,
}) => {
  const [clickedButtons, setClickedButtons] = useState({});

  const handleButtonClick = (index) => {
    setClickedButtons((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const sortProcessedByJobName = (order) => {
    const sortedProcessed = [...processed].sort((a, b) => {
      const nameA = a.JobName.replace(/^\(C\) /i, "");
      const nameB = b.JobName.replace(/^\(C\) /i, "");

      if (order === "asc") {
        return nameA.localeCompare(nameB);
      } else if (order === "desc") {
        return nameB.localeCompare(nameA);
      }
      return 0;
    });
    return sortedProcessed;
  };

  const sortProcessedDate = (order) => {
    const sortedProcessed = [...processed].sort((a, b) => {
      const dateA = new Date(a.DateCreated);
      const dateB = new Date(b.DateCreated);

      if (order === "asc") {
        return dateA - dateB;
      } else if (order === "desc") {
        return dateB - dateA;
      }
      return 0;
    });
    return sortedProcessed;
  };

  const handleSortProcessedByJobName = () => {
    const newOrder = sortProcessedByName === "asc" ? "desc" : "asc";
    const sortedData = sortProcessedByJobName(newOrder);
    setSortProcessedByName(newOrder);
    setProcessedData(sortedData);
  };

  const handleSortProcessedByDateCreated = () => {
    const newOrder = sortProcessedByDateCreated === "asc" ? "desc" : "asc";
    const sortedData = sortProcessedDate(newOrder);
    setSortProcessedByDateCreated(newOrder);
    setProcessedData(sortedData);
  };

  return (
    <table style={{ maxHeight: "60vh", width: "100%", tableLayout: "fixed" }}>
      {/* Processed table */}
      <thead
        className="table-header-div theadThStyle"
        style={{ width: "100%" }}
      >
        <tr style={{ width: "100%" }}>
          {/* Table header for Processed*/}
          <th className="theadThStyle"> Folder Name </th>
          <th className="theadThStyle"> File Name </th>
          <th className="theadThStyle">
            Job Name
            <AscendingDescendingButton onClick={handleSortProcessedByJobName} />
          </th>
          <th className="theadThStyle">
            Date Created
            <AscendingDescendingButton
              onClick={handleSortProcessedByDateCreated}
            />
          </th>
          <th className="theadThStyle"> Detailer </th>
          <th className="theadThStyle" style={{ borderRight: "none" }}>
            PDF Files
          </th>
        </tr>
      </thead>
      <SimpleBar style={{ maxHeight: "50vh", width: "100%" }}>
        <tbody
          style={{
            display: "block",
            width: "100%",
          }}
        >
          {processedIsLoading ? (
            <tr style={{ width: "100%" }}>
              <td colSpan={6} style={{ width: "100%" }}>
                Loading...
              </td>
            </tr>
          ) : processed.length === 0 ? (
            <tr style={{ width: "100%" }}>
              <td colSpan={6} style={{ width: "100%" }}>
                No data available.
              </td>
            </tr>
          ) : (
            processedData.map((process, i) => (
              <ProcessedRow
                process={process}
                key={i}
                handleShowPdfFilesProcess={handleShowPdfFilesProcess}
                handleButtonClick={() => handleButtonClick(i)}
                isButtonClicked={clickedButtons[i]}
                currentProcess={currentProcess}
              />
            ))
          )}
        </tbody>
      </SimpleBar>
      <tfoot style={{ width: "100%" }}>
        <tr style={{ width: "100%" }}>
          <td
            style={{
              backgroundColor: "#9ecb6a",
              fontSize: "medium",
              fontWeight: "bold",
              color: "white",
              width: "100%",
            }}
            colSpan="6"
          >
            <b>
              TO CONFIRM ANY JOB, PLEASE SIGN THE BILL OF MATERIALS, SCAN AND
              EMAIL IT TO THE USERS&apos; EMAIL ADDRESS SHOWN ON THE BILL OF
              MATERIALS.
            </b>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

ProcessedTable.displayName = "ProcessedTable";

ProcessedTable.propTypes = {
  processedIsLoading: PropTypes.bool,
  processed: PropTypes.array,
  processedData: PropTypes.array,
  handleShowPdfFilesProcess: PropTypes.func,
  setProcessedData: PropTypes.func,
  setSortProcessedByName: PropTypes.func,
  sortProcessedByName: PropTypes.string,
  setSortProcessedByDateCreated: PropTypes.func,
  sortProcessedByDateCreated: PropTypes.string,
  currentProcess: PropTypes.object,
};

export default ProcessedTable;
