import React, { useState } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const SunroomElectrical = ({
  handleSelectedElectricalChoice,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedElectricalOption, setSelectedElectricalOption] = useState("");

  const handleElectricalOptionChange = (selected) => {
    setSelectedElectricalOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "ELECTRICAL YES or NO",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "ELECTRICAL YES or NO")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "ELECTRICAL YES or NO"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedElectricalChoice(selected);
  };

  return (
    <div className="popupwindow" style={{ width: "50%" }}>
      <h6
        className={
          "headers " +
          (redBorderFields.includes("ELECTRICAL YES or NO")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon defaultMessage="Yes - TEMO sends electrical floor raceway to cover inside perimeter of room." />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Electrical
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedElectricalOption === "ELECTRICAL YES" ? "clicked" : ""}`}
          onClick={() => handleElectricalOptionChange("ELECTRICAL YES")}
        >
          YES
        </div>
        <div
          className={`clickable-word ${selectedElectricalOption === "ELECTRICAL NO" ? "clicked" : ""}`}
          onClick={() => handleElectricalOptionChange("ELECTRICAL NO")}
        >
          NO
        </div>
      </div>
    </div>
  );
};

SunroomElectrical.propTypes = {
  handleSelectedElectricalChoice: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default SunroomElectrical;
