import React from "react";
import PropTypes from "prop-types";
import AscendingDescendingButton from "../ascending_descending";
import InitialOrdersRow from "./InitialOrdersRow";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const InitialOrdersTable = ({
  initialOrdersIsLoading,
  initialorders,
  initialordersData,
  reformatDateSent,
  setInitialOrdersData,
  sortInitialOrderByLastName,
  setsortInitialOrderByLastName,
  sortInitialOrderByFirstName,
  setsortInitialOrderByFirstName,
  sortOrderByDate,
  setSortOrderByDate,
}) => {
  const sortInitialOrdersLastName = (order) => {
    const sortedInitialOrders = [...initialorders].sort((a, b) => {
      const nameA = a.lastName.replace(/^\(C\) /i, "");
      const nameB = b.lastName.replace(/^\(C\) /i, "");

      if (order === "asc") {
        return nameA.localeCompare(nameB);
      } else if (order === "desc") {
        return nameB.localeCompare(nameA);
      }
      return 0;
    });
    return sortedInitialOrders;
  };

  const sortInitialOrdersFirstName = (order) => {
    const sortedInitialOrders = [...initialorders].sort((a, b) => {
      const nameA = a.firstName.replace(/^\(C\) /i, "");
      const nameB = b.firstName.replace(/^\(C\) /i, "");

      if (order === "asc") {
        return nameA.localeCompare(nameB);
      } else if (order === "desc") {
        return nameB.localeCompare(nameA);
      }
      return 0;
    });
    return sortedInitialOrders;
  };

  const handleSortInitialOrdersByLastName = () => {
    const newOrder = sortInitialOrderByLastName === "asc" ? "desc" : "asc";
    const sortedData = sortInitialOrdersLastName(newOrder);
    setsortInitialOrderByLastName(newOrder);
    setInitialOrdersData(sortedData);
  };

  const handleSortInitialOrdersByFirstName = () => {
    const newOrder = sortInitialOrderByFirstName === "asc" ? "desc" : "asc";
    const sortedData = sortInitialOrdersFirstName(newOrder);
    setsortInitialOrderByFirstName(newOrder);
    setInitialOrdersData(sortedData);
  };

  const sortInitialOrdersByDate = (order) => {
    const sortedInitialOrders = [...initialorders].sort((a, b) => {
      const dateA = new Date(
        a.dateTimeSent.slice(0, 4) +
          "-" +
          a.dateTimeSent.slice(4, 6) +
          "-" +
          a.dateTimeSent.slice(6, 8) +
          "T" +
          a.dateTimeSent.slice(8),
      );
      const dateB = new Date(
        b.dateTimeSent.slice(0, 4) +
          "-" +
          b.dateTimeSent.slice(4, 6) +
          "-" +
          b.dateTimeSent.slice(6, 8) +
          "T" +
          b.dateTimeSent.slice(8),
      );

      if (order === "asc") {
        return dateA - dateB;
      } else if (order === "desc") {
        return dateB - dateA;
      }
      return 0;
    });

    return sortedInitialOrders;
  };

  const handleSortInitialOrdersByDate = () => {
    const newOrder = sortOrderByDate === "asc" ? "desc" : "asc";
    const sortedData = sortInitialOrdersByDate(newOrder);
    setSortOrderByDate(newOrder);
    setInitialOrdersData(sortedData);
  };

  return (
    <table style={{ maxHeight: "60vh", width: "100%", tableLayout: "fixed" }}>
      <thead
        className="table-header-div theadThStyle"
        style={{ width: "100%" }}
      >
        <tr style={{ width: "100%" }}>
          {/* Table header for Initial Orders */}
          <th className="theadThStyle">
            Last Name
            <AscendingDescendingButton
              onClick={handleSortInitialOrdersByLastName}
            />
          </th>
          <th className="theadThStyle">
            First&nbsp;Name
            <AscendingDescendingButton
              onClick={handleSortInitialOrdersByFirstName}
            />
          </th>
          <th className="theadThStyle"> Sent By</th>
          <th className="theadThStyle">
            Date Sent
            <AscendingDescendingButton
              onClick={handleSortInitialOrdersByDate}
            />
          </th>
          <th className="theadThStyle">File Name</th>
          <th className="theadThStyle" style={{ borderRight: "none" }}>
            Txt File
          </th>
        </tr>
      </thead>
      <SimpleBar style={{ maxHeight: "50vh", width: "100%" }}>
        <tbody
          style={{
            display: "block",
            width: "100%",
          }}
        >
          {initialOrdersIsLoading ? (
            <tr style={{ width: "100%" }}>
              <td colSpan={6} style={{ width: "100%" }}>
                Loading...
              </td>
            </tr>
          ) : initialorders.length === 0 ? (
            <tr style={{ width: "100%" }}>
              <td colSpan={6} style={{ width: "100%" }}>
                No data available.
              </td>
            </tr>
          ) : (
            initialordersData.map((initialorder, i) => (
              <InitialOrdersRow
                initialorder={initialorder}
                reformatDateSent={reformatDateSent}
                key={i}
              />
            ))
          )}
        </tbody>
      </SimpleBar>
    </table>
  );
};

InitialOrdersTable.displayName = "InitialOrdersTable";

InitialOrdersTable.propTypes = {
  initialOrdersIsLoading: PropTypes.bool,
  initialorders: PropTypes.arrayOf(PropTypes.object),
  initialordersData: PropTypes.arrayOf(PropTypes.object),
  reformatDateSent: PropTypes.func,
  setInitialOrdersData: PropTypes.func,
  sortInitialOrderByLastName: PropTypes.string,
  setsortInitialOrderByLastName: PropTypes.func,
  sortInitialOrderByFirstName: PropTypes.string,
  setsortInitialOrderByFirstName: PropTypes.func,
  sortOrderByDate: PropTypes.string,
  setSortOrderByDate: PropTypes.func,
};

export default InitialOrdersTable;
