import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import infoicon from "./info-circle-line-icon.png";
import PropTypes from "prop-types";
import parse from "html-react-parser";

const InfoIcon = ({ defaultMessage }) => {
  const tooltipRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = (event) => {
    const rect = tooltipRef.current.getBoundingClientRect();
    const distanceToRight = window.innerWidth - event.clientX;

    setPosition({
      x:
        distanceToRight < event.clientX
          ? event.clientX - rect.width - 440
          : event.clientX + 20,
      y: event.clientY,
    });

    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setPosition({ x: 0, y: 0 });
    setIsTooltipVisible(false);
  };

  const tooltipRoot = document.getElementById("tooltip-root");
  return (
    <div
      ref={tooltipRef}
      style={{
        gridColumn: "3",
        gridRow: "1",
        display: "flex",
        justifyContent: "flex-end",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={infoicon} alt="Info Icon" style={{ height: "2vh" }} />

      {isTooltipVisible &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "absolute",
              top: position.y,
              left: position.x,
              background: "white",
              padding: ".1%",
              border: "ridge 3px #9ecb6a",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              zIndex: 10000,
              width: "fit-content",
              height: "fit-content",
              fontSize: "medium",
              color: "red",
              whiteSpace: "normal",
              textAlign: "left",
            }}
          >
            {parse(defaultMessage)}
          </div>,
          tooltipRoot,
        )}
    </div>
  );
};

InfoIcon.propTypes = {
  defaultMessage: PropTypes.string,
};

export default InfoIcon;
