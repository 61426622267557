import React, { useEffect, useState, useRef, useCallback } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net";
import OrdersHistoryTable from "../../components/OrderHistoryComponents/OrderHistoryTable";
import OrderHistoryPdfTable from "../../components/OrderHistoryComponents/OrderHistoryPdfTable";
import JSZip from "jszip";
import { useAuth0 } from "@auth0/auth0-react";
import * as XLSX from "xlsx";

const OrderHistory = () => {
  const [orders, getOrders] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [filesList, setFilesList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);
  const tableRef = useRef(null);
  const dataTableRef = useRef(null);
  const orderhistoryTableRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();
  const [searchTerms, setSearchTerms] = useState({
    folder: "",
    fileName: "",
    jobName: "",
    dateShipped: "",
    streetAddress: "",
    cityStateZip: "",
  });
  const [inputFields, setInputFields] = useState({
    folder: "",
    fileName: "",
    jobName: "",
    dateShipped: "",
    streetAddress: "",
    cityStateZip: "",
  });
  const [isHistoryPdfsLoading, setHistoryPdfsLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [isUserHide, setIsUserHide] = useState(true);

  const handleButtonClick = () => {
    setSearchTerms(inputFields);
  };

  const handleClearSearch = () => {
    setSearchTerms({
      folder: "",
      fileName: "",
      jobName: "",
      dateShipped: "",
      streetAddress: "",
      cityStateZip: "",
    });
    setInputFields({
      folder: "",
      fileName: "",
      jobName: "",
      dateShipped: "",
      streetAddress: "",
      cityStateZip: "",
    });
  };

  const fetchOrderHistory = useCallback(
    async (page, pageSize) => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: "TEMOAuth",
          },
        });

        const searchParams = new URLSearchParams(searchTerms).toString();

        const response = await fetch(
          `/api/SQLThings/OrderHistoryData?page=${page}&pageSize=${pageSize}&${searchParams}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (!response.ok) {
          console.error(
            "Error fetching unprocessed data:",
            response.statusText,
          );
          return [];
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching unprocessed data:", error);
        return [];
      }
    },
    [getAccessTokenSilently, searchTerms],
  );

  const fetchHistoryPdfFiles = useCallback(
    async (order) => {
      try {
        let fileName = "defaultFileName";
        let folderName = "defaultFolderName";
        if (order && order.FileName && order.Folder) {
          fileName = order.FileName;
          folderName = order.Folder;
        } else {
          console.error("Invalid or missing FileName in the confirm object.");
          return null;
        }

        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: "TEMOAuth",
          },
        });

        const res = await fetch(
          `/api/TxtThings/historypdf?folderName=${folderName}&fileNames=${fileName}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (res.ok) {
          const blob = await res.blob();

          const zip = await JSZip.loadAsync(blob);

          const files = Object.keys(zip.files);

          return { order, files };
        } else {
          console.error("Error fetching PDF files:", res.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching and extracting ZIP:", error);
        return null;
      }
    },
    [getAccessTokenSilently],
  );

  useEffect(() => {
    if (orders.length > 0) {
      const tableElement = tableRef.current;
      if (tableElement) {
        dataTableRef.current = $(tableElement)
          .DataTable({})
          .addClass("bs-select");
        $(tableElement).addClass("table-bordered");
      }
    }
    return () => {
      if (dataTableRef.current) {
        dataTableRef.current.destroy(true);
      }
    };
  }, [orders]);

  const handleShowOrderHistoryPdfTable = async (order) => {
    if (!order || typeof order.FileName === "undefined") {
      console.error("Invalid or missing FileName in the confirm object.");
      return;
    }

    setCurrentOrder((prevOrder) => {
      if (prevOrder === order) {
        setFilesList([]);
        setIsUserHide(true);
        return null;
      }

      setIsUserHide(false);
      return order;
    });

    if (currentOrder === order) {
      return;
    }

    try {
      setHistoryPdfsLoading(true);
      const pdfData = await fetchHistoryPdfFiles(order);

      if (pdfData) {
        setHistoryPdfsLoading(false);
        setFilesList([{ order, files: pdfData.files }]);
      } else {
        setHistoryPdfsLoading(false);
        setFilesList([]);
        console.error("Error fetching PDF files.");
      }
    } catch (error) {
      setHistoryPdfsLoading(false);
      setFilesList([]);
      console.error("An error occurred while handling PDF files:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getOrders([]);
    Promise.all([fetchOrderHistory(page, pageSize)])
      .then(([orderhistoryResponse]) => {
        getOrders(orderhistoryResponse);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data");
        setLoading(false);
      });
  }, [fetchOrderHistory, page, pageSize]);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(orders);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "orderhistory.xlsx");
  };

  return (
    <div className="app-page">
      <div
        style={{
          height: "fit-content",
          width: "80vw",
          position: "relative",
          marginTop: "15vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <button
            type="button"
            className="searchbutton"
            onClick={handleButtonClick}
          >
            Search
          </button>
          <button
            type="button"
            className="clearsearchbutton"
            onClick={handleClearSearch}
          >
            Clear Search
          </button>
          <button
            type="button"
            className="archivebuttons"
            onClick={exportToExcel}
          >
            Export to Excel
          </button>
          <button
            className="archivebuttons"
            onClick={() => setPage((prevPage) => prevPage - 1)}
            disabled={page === 1}
          >
            Previous Page
          </button>
          <button
            className="archivebuttons"
            onClick={() => setPage((prevPage) => prevPage + 1)}
          >
            Next Page
          </button>
          <select onChange={(e) => setPageSize(Number(e.target.value))}>
            <option value="">Page Size Start 200</option>
            <option value="200">200</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
            <option value="2000">2000</option>
          </select>
        </div>
        <table className="tableHeaderTableHeight" style={{ width: "80vw" }}>
          <thead className="theadThStyle">
            <th
              colSpan={7}
              className="thStyle"
              style={{ border: "1px solid black", borderBottom: "none" }}
            >
              PAST ORDERS
            </th>
          </thead>
        </table>
        <OrdersHistoryTable
          id="dtDynamicVerticalScrollExample"
          orders={orders}
          ref={orderhistoryTableRef}
          handleShowOrderHistoryPdfTable={handleShowOrderHistoryPdfTable}
          page={page}
          setPage={setPage}
          isLoading={isLoading}
          setInputFields={setInputFields}
          inputFields={inputFields}
          currentOrder={currentOrder}
        />
      </div>
      <div
        style={{
          height: "75vh",
          width: "15%",
          display: "flex",
          alignItems: "flex-start",
          marginLeft: "2%",
          marginTop: "20vh",
        }}
      >
        <OrderHistoryPdfTable
          filesList={filesList}
          isHistoryPdfsLoading={isHistoryPdfsLoading}
          isUserHide={isUserHide}
        />
      </div>
    </div>
  );
};

export default OrderHistory;
