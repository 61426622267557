import React, { useEffect, useState, useCallback } from "react";
import InfoIcon from "./InfoIcon";
import PropTypes from "prop-types";

const ShingleRoof = ({
  handleShingleChange,
  handleSelectedSkyviewChoice, // Added prop for handling Skyview change
  redBorderFields,
  setFieldsWithRedBorder,
  selectedSkyviewChoice,
}) => {
  const [selectedShingleRoofOption, setSelectedShingleRoofOption] =
    useState("");

  const handleShingleRoofChange = useCallback(
    (selected) => {
      setSelectedShingleRoofOption(selected);
      const updatedRedBorderFields = redBorderFields.includes("Shingle Roof")
        ? selected
          ? redBorderFields.filter((field) => field !== "Shingle Roof")
          : redBorderFields
        : selected
          ? redBorderFields
          : [...redBorderFields, "Shingle Roof"];

      setFieldsWithRedBorder(updatedRedBorderFields);
      handleShingleChange(selected);

      // Automatically set Skyview choice based on Shingle Roof selection
      if (selected === "YES") {
        handleSelectedSkyviewChoice("Skyview NO");
      }
    },
    [
      redBorderFields,
      setFieldsWithRedBorder,
      handleShingleChange,
      handleSelectedSkyviewChoice,
    ],
  );

  useEffect(() => {
    if (selectedSkyviewChoice === "Skyview YES") {
      setSelectedShingleRoofOption("NO");
      handleShingleChange("NO");
    } else if (selectedShingleRoofOption === "YES") {
      // Ensure Skyview is set to NO if Shingle Roof is YES
      handleSelectedSkyviewChoice("Skyview NO");
    }
  }, [
    selectedSkyviewChoice,
    handleShingleChange,
    handleSelectedSkyviewChoice,
    selectedShingleRoofOption,
  ]);

  return (
    <div className="popupwindow shingleroof">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Shingle Roof")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon defaultMessage="Temo does not supply shingles.<br/>Shingles add 5 lb. per square foot.<br/>Temo roof panels can only handle shingles if the roof skin thickness is 0.032." />
        <span className="header-grid-location">
          <span className="redasterisk"> &#10033;</span>
          Shingle TEMO Roof
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedShingleRoofOption === "YES" ? "clicked" : ""}`}
          onClick={() => handleShingleRoofChange("YES")}
        >
          Yes
        </div>
        &nbsp;
        <div
          className={`clickable-word ${selectedShingleRoofOption === "NO" ? "clicked" : ""}`}
          onClick={() => handleShingleRoofChange("NO")}
        >
          No
        </div>
      </div>
    </div>
  );
};

ShingleRoof.propTypes = {
  handleShingleChange: PropTypes.func,
  handleSelectedSkyviewChoice: PropTypes.func, // Added propType for the new function
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  selectedSkyviewChoice: PropTypes.string,
};

export default ShingleRoof;
