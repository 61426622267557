import React, { useState, useEffect } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const SunroomsDimensionsSelection = ({
  handleDimensionChange,
  redBorderFields,
  setFieldsWithRedBorder,
  lengthFeet,
  lengthInches,
  widthFeet,
  widthInches,
  selectedSunRoomType,
}) => {
  const [widthInchesOption, setWidthInchesOption] = useState("");
  const [lengthInchesOption, setLengthInchesOption] = useState("");
  const [widthFeetValue, setWidthFeetValue] = useState("");
  const [lengthFeetValue, setLengthFeetValue] = useState("");

  const handleWidthInchesChange = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, "");

    const numericInput = parseFloat(input);
    const isValidNumber = !isNaN(numericInput) && numericInput < 12;

    if (input !== "" && !isValidNumber) {
      if (numericInput >= 12) {
        input = "11.999";
      } else {
        input = numericInput.toString();
      }
    }

    setWidthInchesOption(input);

    const updatedRedBorderFields = redBorderFields.includes("width inches")
      ? input
        ? redBorderFields.filter((field) => field !== "width inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "width inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDimensionChange(widthFeetValue, input, "width");
  };

  const handleWidthFeetChange = (event) => {
    const input = event.target.value.replace(/\D/g, "").slice(0, 3);
    setWidthFeetValue(input);
    const updatedRedBorderFields = redBorderFields.includes("width feet")
      ? input
        ? redBorderFields.filter((field) => field !== "width feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "width feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDimensionChange(input, widthInchesOption, "width");
  };

  const handleLengthInchesChange = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, "");

    const numericInput = parseFloat(input);
    const isValidNumber = !isNaN(numericInput) && numericInput < 12;

    if (input !== "" && !isValidNumber) {
      if (numericInput >= 12) {
        input = "11.999";
      } else {
        input = numericInput.toString();
      }
    }

    setLengthInchesOption(input);

    const updatedRedBorderFields = redBorderFields.includes("length inches")
      ? input
        ? redBorderFields.filter((field) => field !== "length inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "length inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDimensionChange(lengthFeetValue, input, "length");
  };

  const handleLengthFeetChange = (event) => {
    const input = event.target.value.replace(/\D/g, "").slice(0, 3);
    setLengthFeetValue(input);
    const updatedRedBorderFields = redBorderFields.includes("length feet")
      ? input
        ? redBorderFields.filter((field) => field !== "length feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "length feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDimensionChange(input, lengthInchesOption, "length");
  };

  useEffect(() => {
    if (widthFeet) {
      setWidthFeetValue(widthFeet);
    }
  }, [widthFeet]);

  useEffect(() => {
    if (widthInches) {
      setWidthInchesOption(widthInches);
    }
  }, [widthInches]);

  useEffect(() => {
    if (lengthFeet) {
      setLengthFeetValue(lengthFeet);
    }
  }, [lengthFeet]);

  useEffect(() => {
    if (lengthInches) {
      setLengthInchesOption(lengthInches);
    }
  }, [lengthInches]);

  return (
    <div className="load-selection">
      <div className="popupwindow lengthdim">
        <h6 className="greenBackground headers">
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Roof Projection
          </span>
          <InfoIcon defaultMessage="Projection has a minimum of 4 feet.<br/>Maximum overhang = 1'<br/>Anything more will require Roma or Florentine posts." />
        </h6>

        <div className="fontsizeforinputsrow" id="rooflength">
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Feet:&nbsp;
            </span>
            <input
              id="lengthfeet"
              className={
                redBorderFields.includes("length feet")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={lengthFeetValue}
              onChange={handleLengthFeetChange}
              maxLength={3}
              pattern="\d*"
              style={{ width: "50%" }}
            />
          </span>
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Inch:&nbsp;
            </span>
            <input
              id="lengthinches"
              className={
                redBorderFields.includes("length inches")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={lengthInchesOption}
              onChange={handleLengthInchesChange}
              style={{ width: "50%" }}
            />
          </span>
        </div>
      </div>
      {selectedSunRoomType !== "Solarium" && (
        <div className="popupwindow widthdim">
          <h6 className="greenBackground headers">
            <span className="header-grid-location">
              <span className="redasterisk">&#10033;</span>
              Roof Width
            </span>
            <InfoIcon defaultMessage="Roof Width refers to distance from side to side.<br/>Width has a minimum of 4 feet." />
          </h6>

          <div id="roofwidth" className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="fontsizeforinputsrow">
                <span className="redasterisk">&#10033;</span>
                Feet:&nbsp;
              </span>
              <input
                id="widthfeet"
                className={
                  redBorderFields.includes("width feet")
                    ? "redBackground"
                    : "whiteBackground"
                }
                value={widthFeetValue}
                onChange={handleWidthFeetChange}
                maxLength={3}
                pattern="\d*"
                style={{ width: "50%" }}
              />
            </span>
            <span className="fontsizeforinputsrow">
              <span className="fontsizeforinputsrow">
                <span className="redasterisk">&#10033;</span>
                Inch:&nbsp;
              </span>
              <input
                id="widthinches"
                className={
                  redBorderFields.includes("width inches")
                    ? "redBackground"
                    : "whiteBackground"
                }
                value={widthInchesOption}
                onChange={handleWidthInchesChange}
                style={{ width: "50%" }}
              />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

SunroomsDimensionsSelection.propTypes = {
  handleDimensionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  lengthFeet: PropTypes.string,
  lengthInches: PropTypes.string,
  widthFeet: PropTypes.string,
  widthInches: PropTypes.string,
  selectedSunRoomType: PropTypes.string,
};

export default SunroomsDimensionsSelection;
