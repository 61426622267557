import React from "react";
import PropTypes from "prop-types";

const ColorDropdown = ({
  id,
  onChange,
  options,
  title,
  redBorderFields,
  value,
  otherTitle,
}) => (
  <div
    style={{
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      marginRight: "1%",
      height: "3.5vh",
      fontSize: "1.5vh",
      alignItems: "center",
      flexDirection: "row",
    }}
  >
    <span>{otherTitle}:&nbsp;</span>
    <select
      id={id}
      className={`input-box ${redBorderFields.includes(id) ? "redBackground" : ""}`}
      style={{ width: "5.4vw", height: "fit-content" }}
      onChange={onChange}
      value={value}
    >
      <option value="">Select {title}</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </option>
      ))}
    </select>
  </div>
);

ColorDropdown.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  title: PropTypes.string,
  redBorderFields: PropTypes.array,
  value: PropTypes.string,
  otherTitle: PropTypes.string,
};

export default ColorDropdown;
