import React from "react";

const OrderHistoryPage = () => (
  <div className="order-history-container">
    <h1>Viewing Order History</h1>
    <div className="order-history-section">
      <h2>Order History Page</h2>
      <p>
        Similar to the Current Orders page, this page shows your shipped orders
        and includes:
      </p>
    </div>
    <div className="order-history-section">
      <h2>Pagination</h2>
      <p>Navigate through pages using the previous and next page buttons.</p>
    </div>
    <div className="order-history-section">
      <h2>Page Size</h2>
      <p>
        Set how many orders you want to see at once, ranging from 200 to 2000,
        using the page size dropdown.
      </p>
    </div>
    <div className="order-history-section">
      <h2>Search Bar</h2>
      <p>
        Search for orders based on available information by entering details in
        the search bar and <u>clicking the search button</u>. For example,
        entering “B” in the Job Name search and “2024” in the Date Shipped
        column will filter results accordingly. The results will be every order
        that was in the year 2024 with a “B” in the job name.
      </p>
    </div>
    <div className="order-history-section">
      <h2>Show PDFs</h2>
      <p>Click this button to view attached files for each order.</p>
    </div>
    <div
      className="order-history-section star-item"
      style={{ color: "black", overflow: "visible", padding: "1%" }}
    >
      Note that not all orders may have saved files due to when the file was put
      put into the system.
    </div>
  </div>
);

export default OrderHistoryPage;
