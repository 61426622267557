import React from "react";
import PropTypes from "prop-types";

const ConfirmedRow = ({
  confirm,
  handleShowPdfFilesTable,
  reformatScheduledDate,
  currentConfirm,
  isConfirmButtonClicked,
  handleConfirmButtonClick,
}) => {
  return (
    <tr style={{ width: "100%" }}>
      <td>{confirm.Folder}</td>
      <td>{confirm.JobName}</td>
      <td>{confirm.FileName}</td>
      <td>{confirm.Confirmation}</td>
      <td>{reformatScheduledDate(confirm.SchedDate)}</td>
      <td>
        <button
          type="button"
          className="showpdfbuttons"
          style={{
            backgroundColor:
              isConfirmButtonClicked && currentConfirm === confirm
                ? "#B5651d"
                : "",
            borderColor:
              isConfirmButtonClicked && currentConfirm === confirm
                ? "#B5651d"
                : "",
          }}
          onClick={() => {
            handleConfirmButtonClick();
            handleShowPdfFilesTable(confirm);
          }}
        >
          {isConfirmButtonClicked && currentConfirm === confirm
            ? "Hide PDFs"
            : "Show PDFs"}
        </button>
      </td>
    </tr>
  );
};

ConfirmedRow.propTypes = {
  confirm: PropTypes.object,
  handleShowPdfFilesTable: PropTypes.func,
  reformatScheduledDate: PropTypes.func,
  currentConfirm: PropTypes.object,
  isConfirmButtonClicked: PropTypes.bool,
  handleConfirmButtonClick: PropTypes.func,
};

export default ConfirmedRow;
