import React /*, { useEffect, useState }*/ from "react";
import PropTypes from "prop-types";
// import DrawingOverlaySelection from "../../OrderingSystemComponents/DrawingOverlay/DrawingOverlaySelection";

const UnprocessedRow = ({ unprocess, reformatDateSent, rowKey }) => {
  const { date, time } = reformatDateSent(unprocess.date);
  // const [drawingImages, setDrawingImages] = useState([]);
  // const [isDrawingOverlayOpen, setIsDrawingOverlayOpen] = React.useState(false);
  // const [currentCADImagesFiles, setCurrentCADImagesFiles] = useState("");

  // useEffect(() => {
  //   if (isDrawingOverlayOpen) {
  //     const newFileName = unprocess.fileName.replace(".txt", "-parallel.txt");
  //     setCurrentCADImagesFiles(newFileName);
  //   }
  // }, [unprocess, isDrawingOverlayOpen, rowKey]);

  return (
    <tr style={{ width: "100%" }}>
      <td>{unprocess.folderName}</td>
      <td>{unprocess.lastName ? unprocess.lastName.toUpperCase() : ""}</td>
      <td>{unprocess.firstName ? unprocess.firstName.toUpperCase() : ""}</td>
      <td>{unprocess.sentBy ? unprocess.sentBy.toUpperCase() : ""}</td>
      <td>
        {date}
        <br />
        Time Stamp:
        <br />
        {time}
      </td>
      <td>{unprocess.fileName}</td>
      {/* <td style={{ zIndex: "5" }}>
        <DrawingOverlaySelection
          currentCADImagesFiles={currentCADImagesFiles}
          drawingImages={drawingImages}
          setDrawingImages={setDrawingImages}
          setIsDrawingOverlayOpen={setIsDrawingOverlayOpen}
          sentCorrectly={true}
          isDrawingOverlayOpen={isDrawingOverlayOpen}
          style={{ fontSize: "2vh" }}
          newDelay={true}
          isUnprocessedPage={true}
        />
      </td> */}
    </tr>
  );
};

UnprocessedRow.propTypes = {
  unprocess: PropTypes.object,
  reformatDateSent: PropTypes.func,
  rowKey: PropTypes.number,
};

export default UnprocessedRow;
