import React from "react";
import PropTypes from "prop-types";
import ascending_descending from "./Ascending_Descending.png";
function AscendingDescendingButton({ onClick }) {
  return (
    <img
      src={ascending_descending}
      alt="Ascending/Descending"
      onClick={onClick}
      className="ascending-descending-button"
    />
  );
}

AscendingDescendingButton.propTypes = {
  onClick: PropTypes.func,
};

export default AscendingDescendingButton;
