import React, { useState } from "react";
import InfoIcon from "../../../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const CableAndHangingRail = ({
  handleActuatorOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedCableOption, setSelectedCableOption] = useState("");

  const handleCableOptionChange = (selectedoption) => {
    setSelectedCableOption(selectedoption);
    handleActuatorOptionChange(selectedoption);
    const updatedRedBorderFields = redBorderFields.includes(
      "actuator cable option",
    )
      ? selectedoption
        ? redBorderFields.filter((field) => field !== "actuator cable option")
        : redBorderFields
      : selectedoption
        ? redBorderFields
        : [...redBorderFields, "actuator cable option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  return (
    <div className="popupwindow cable-choices">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("actuator cable option")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Optional Actuator Cable
        </span>
        <InfoIcon defaultMessage="Your Drive Rafter Assembly comes with a 25' cable.<br/>Picking this will give you an additional cable." />
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedCableOption === "12' EXTENSION CABLE" ? "clicked" : ""}`}
          onClick={() => handleCableOptionChange("12' EXTENSION CABLE")}
        >
          12&apos; Cable
        </div>
        <div
          className={`clickable-word ${selectedCableOption === "25' EXTENSION CABLE" ? "clicked" : ""}`}
          onClick={() => handleCableOptionChange("25' EXTENSION CABLE")}
        >
          25&apos; Cable
        </div>
        <div
          className={`clickable-word ${selectedCableOption === "none" ? "clicked" : ""}`}
          onClick={() => handleCableOptionChange("none")}
          style={{ marginLeft: "1%" }}
        >
          Not Needed
        </div>
      </div>
    </div>
  );
};

CableAndHangingRail.propTypes = {
  handleActuatorOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default CableAndHangingRail;
