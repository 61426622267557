import React, { useState } from "react";
import ReactDOM from "react-dom";
import viewImage from "./view-image-photo-icon.png";
import PropTypes from "prop-types";

const ImageButton = ({ images, messages }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleButtonClick = () => {
    setIsModalVisible(!isModalVisible);
  };

  const modalRoot = document.getElementById("modal-root");

  return (
    <div
      style={{
        gridColumn: "3",
        gridRow: "1",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <div
        style={{
          width: "fit-content",
          height: "fit-content",
          cursor: "pointer",
          fontSize: "x-small",
        }}
      >
        <div onClick={handleButtonClick}>
          <img src={viewImage} alt="View" style={{ height: "1.9vh" }} />
        </div>
      </div>

      {isModalVisible &&
        ReactDOM.createPortal(
          <div className="modal">
            <div className="modal-content">
              <button
                type="button"
                className="showpdfbuttons"
                style={{
                  position: "absolute",
                  top: "1%",
                  right: "1%",
                  cursor: "pointer",
                  width: "auto",
                  height: "auto",
                }}
                onClick={handleButtonClick}
              >
                X
              </button>
              {images.length <= 3 ? (
                <div className="image-container2">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {images.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          fontSize: "medium",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <img src={image} alt={`${index + 1}`} />
                        <span>{messages[index]}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="image-container2">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {images.slice(0, 2).map((image, index) => (
                        <div
                          key={index}
                          style={{
                            fontSize: "medium",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={image}
                            alt={`${index + 1}`}
                            style={{
                              width: "40vh",
                              height: "40vh",
                            }}
                          />
                          <span>{messages[index]}</span>
                        </div>
                      ))}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {images.slice(2).map((image, index) => (
                        <div
                          key={index + 2}
                          style={{
                            fontSize: "medium",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={image}
                            alt={`${index + 3}`}
                            style={{
                              width: "40vh",
                              height: "40vh",
                            }}
                          />
                          <span>{messages[index + 2]}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>,
          modalRoot,
        )}
    </div>
  );
};

ImageButton.propTypes = {
  images: PropTypes.array,
  messages: PropTypes.array,
};

export default ImageButton;
