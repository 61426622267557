import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";

const InitalOrdersRow = ({ initialorder, reformatDateSent }) => {
  const { date, time } = reformatDateSent(initialorder.dateTimeSent);
  const [fileLoading, setFileLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleButtonClick = async () => {
    const newWindow = window.open();

    try {
      setFileLoading(true);

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      const response = await fetch(
        `/api/TxtThings/textfile/${initialorder.fileName}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.ok) {
        const fileBlob = await response.blob();

        const fileUrl = URL.createObjectURL(fileBlob);

        newWindow.location = fileUrl;
      } else {
        console.error("Failed to fetch file");
        newWindow.close();
      }
    } catch (error) {
      console.error("Error fetching file", error);
      if (newWindow) newWindow.close();
    } finally {
      setFileLoading(false);
    }
  };

  return (
    <tr style={{ width: "100%" }}>
      <td>
        {initialorder.lastName ? initialorder.lastName.toUpperCase() : ""}
      </td>
      <td>
        {initialorder.firstName ? initialorder.firstName.toUpperCase() : ""}
      </td>
      <td>{initialorder.sentBy ? initialorder.sentBy.toUpperCase() : ""}</td>
      <td>
        {date}
        <br />
        Time Stamp: {time}
      </td>
      <td>{initialorder.fileName}</td>
      <td>
        <button
          type="button"
          className="showpdfbuttons"
          onClick={handleButtonClick}
          disabled={fileLoading}
        >
          {fileLoading ? "Loading..." : "Show File"}
        </button>
      </td>
    </tr>
  );
};

InitalOrdersRow.propTypes = {
  initialorder: PropTypes.object,
  reformatDateSent: PropTypes.func,
};

export default InitalOrdersRow;
