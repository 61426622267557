import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoIcon from "../UniversalComponents/InfoIcon";

const VinylColorSelection = ({
  handleVinylColorChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedVinylColor, setSelectedVinylColor] = useState("");

  const handleVinylColorOptionChange = (selected) => {
    setSelectedVinylColor(selected);
    let updatedRedBorderFields = redBorderFields.includes("Vinyl Color")
      ? selected
        ? redBorderFields.filter((field) => field !== "Vinyl Color")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Vinyl Color"];
    handleVinylColorChange(selected);
    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  return (
    <div className="popupwindow trimcolor" style={{ marginTop: "1%" }}>
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Vinyl Color")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon defaultMessage="" />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Vinyl Color
        </span>
      </h6>
      <div>
        <div className="clickable-words">
          <div
            className={`clickable-word ${selectedVinylColor === "CLEAR" ? "clicked" : ""}`}
            onClick={() => handleVinylColorOptionChange("CLEAR")}
          >
            Clear
          </div>
          <div
            className={`clickable-word ${selectedVinylColor === "SMOKE GREY" ? "clicked" : ""}`}
            onClick={() => handleVinylColorOptionChange("SMOKE GREY")}
          >
            Smoke Grey
          </div>
          &nbsp;
          <div
            className={`clickable-word ${selectedVinylColor === "BRONZE" ? "clicked" : ""}`}
            onClick={() => handleVinylColorOptionChange("BRONZE")}
          >
            Bronze
          </div>
          &nbsp;
          <div
            className={`clickable-word ${selectedVinylColor === "DARK GREY" ? "clicked" : ""}`}
            onClick={() => handleVinylColorOptionChange("DARK GREY")}
          >
            Dark Grey
          </div>
        </div>
      </div>
    </div>
  );
};

VinylColorSelection.propTypes = {
  handleVinylColorChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default VinylColorSelection;
