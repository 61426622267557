import React, { useState } from "react";
import PropTypes from "prop-types";

const NewConcreteSlab = ({
  handleNewFoundationChange,
  redBorderFields,
  setFieldsWithRedBorder,
  setSelectedNewConcrete,
}) => {
  const [selectedNewConcreteOption, setSelectedNewConcreteOption] =
    useState("");

  const handleNewConcreteOptionChange = (pickedoption) => {
    setSelectedNewConcreteOption(pickedoption);
    const updatedRedBorderFields = redBorderFields.includes(
      "Pick a new concrete option",
    )
      ? pickedoption
        ? redBorderFields.filter(
            (field) => field !== "Pick a new concrete option",
          )
        : redBorderFields
      : pickedoption
        ? redBorderFields
        : [...redBorderFields, "Pick a new concrete option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleNewFoundationChange(pickedoption);
    setSelectedNewConcrete(pickedoption);
  };

  return (
    <div className="popupwindow existingconcretefooting">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Pick a new concrete option")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Footing Option
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedNewConcreteOption === "perimftg" ? "clicked" : ""}`}
          onClick={() => handleNewConcreteOptionChange("perimftg")}
        >
          Add&nbsp;Footing
        </div>
        <div
          className={`clickable-word ${selectedNewConcreteOption === "piers" ? "clicked" : ""}`}
          onClick={() => handleNewConcreteOptionChange("piers")}
        >
          Piers
        </div>
      </div>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedNewConcreteOption === "nofootings" ? "clicked" : ""}`}
          onClick={() => handleNewConcreteOptionChange("nofootings")}
        >
          No&nbsp;Footing
        </div>
        <div
          className={`clickable-word ${selectedNewConcreteOption === "overpour" ? "clicked" : ""}`}
          onClick={() => handleNewConcreteOptionChange("overpour")}
          style={{ whiteSpace: "nowrap" }}
        >
          Over-Pour
        </div>
      </div>
    </div>
  );
};

NewConcreteSlab.propTypes = {
  handleNewFoundationChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  setSelectedNewConcrete: PropTypes.func,
};

export default NewConcreteSlab;
