import React from "react";

const ErrorMessage = () => (
  <div>
    There was an error with our system.
    <br />
    Please contact Brendan Bordine at:
    <br />
    +1 586-412-6077 <br />
    <a href="mailto:bbordine@temoinc.com">bbordine@temoinc.com</a>
  </div>
);

export default ErrorMessage;
