import React from "react";
import PropTypes from "prop-types";

const RailBreezeway = ({
  redBorderFields,
  selectedBreezeWay,
  handleBreezeWayChange,
}) => {
  return (
    <div className="popupwindow hangingrailside">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("breeze way")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">✱</span>Is it a Breeze Way?
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedBreezeWay === "Yes" ? "clicked" : ""}`}
          onClick={() => handleBreezeWayChange("Yes")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedBreezeWay === "No" ? "clicked" : ""}`}
          onClick={() => handleBreezeWayChange("No")}
        >
          No
        </div>
      </div>
    </div>
  );
};

RailBreezeway.propTypes = {
  redBorderFields: PropTypes.array,
  selectedBreezeWay: PropTypes.string,
  handleBreezeWayChange: PropTypes.func,
};

export default RailBreezeway;
