import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const NoSkyLights = ({ lengthFeet, selectedRoomStyle, selectedAngled }) => {
  const [greaterorlessthan, setGreaterorlessthan] = useState("");

  useEffect(() => {
    if (lengthFeet < 7) {
      setGreaterorlessthan(">= 7");
    }
    if (lengthFeet > 15) {
      setGreaterorlessthan("<= 15");
    }
  }, [lengthFeet]);

  return (
    <div className="popupwindow skylightselection">
      <h6 className="headers greenBackground">
        <span
          style={{ marginBottom: ".5em", marginTop: ".5em" }}
          className="header-grid-location"
        >
          <span className="redasterisk">&#10033;</span>
          Skyview Option Not Allowed
        </span>
      </h6>
      <div className="clickable-words">
        {greaterorlessthan && (
          <div className="clickable-word">
            Projection must be {greaterorlessthan}
          </div>
        )}
        {selectedRoomStyle !== "STUDIO" && (
          <div className="clickable-word">Skyview Only Allowed With Studio</div>
        )}
        {selectedAngled === "Yes" && (
          <div className="clickable-word">
            Skyview Not Allowed With Angled Roof
          </div>
        )}
      </div>
    </div>
  );
};

NoSkyLights.propTypes = {
  lengthFeet: PropTypes.number,
  selectedRoomStyle: PropTypes.string,
  selectedAngled: PropTypes.string,
};

export default NoSkyLights;
