import React from "react";
import PropTypes from "prop-types";

const ProcessedRow = ({
  process,
  handleShowPdfFilesProcess,
  handleButtonClick,
  isButtonClicked,
  currentProcess,
}) => {
  return (
    <tr style={{ width: "100%" }}>
      <td>{process.FolderName}</td>
      <td>{process.FileName}</td>
      <td>{process.JobName}</td>
      <td>{process.DateCreated}</td>
      <td>{process.Detailer}</td>
      <td>
        <button
          type="button"
          className="showpdfbuttons"
          style={{
            backgroundColor:
              isButtonClicked && currentProcess === process ? "#B5651d" : "",
            borderColor:
              isButtonClicked && currentProcess === process ? "#B5651d" : "",
          }}
          onClick={() => {
            handleButtonClick();
            handleShowPdfFilesProcess(process);
          }}
        >
          {isButtonClicked && currentProcess === process
            ? "Hide PDFs"
            : "Show PDFs"}
        </button>
      </td>
    </tr>
  );
};

ProcessedRow.propTypes = {
  process: PropTypes.object,
  handleShowPdfFilesProcess: PropTypes.func,
  handleButtonClick: PropTypes.func,
  isButtonClicked: PropTypes.bool,
  currentProcess: PropTypes.object,
};

export default ProcessedRow;
