import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const PdfFilesTable = ({ filesList, isPdfsLoading, isUserHide }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);
  const [isFetchEmpty, setIsFetchEmpty] = useState(false);

  useEffect(() => {
    if (isPdfsLoading) {
      setHasAttemptedFetch(true);
    }
    if (
      hasAttemptedFetch &&
      !isPdfsLoading &&
      (!filesList || filesList.length === 0)
    ) {
      setIsFetchEmpty(true);
    } else {
      setIsFetchEmpty(false);
    }
  }, [isPdfsLoading, filesList, hasAttemptedFetch]);

  const openFile = async (folderName, fileName, pdfName, e) => {
    e.preventDefault();

    const newWindow = window.open();

    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: "TEMOAuth",
      },
    });

    const fileURL = `/api/TxtThings/pdf/${folderName}/${pdfName}?folderName=${fileName}`;

    fetch(fileURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        newWindow.location = blobURL;
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  return (
    <div
      className="popupwindow"
      style={{
        width: "100%",
        maxHeight: "70vh",
      }}
    >
      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <thead style={{ width: "100%" }}>
          <tr style={{ width: "100%" }}>
            <th className="theadThStyle" style={{ width: "100%" }}>
              PDF List
            </th>
          </tr>
        </thead>
        <SimpleBar style={{ maxHeight: "60vh", width: "100%" }}>
          <tbody style={{ width: "100%" }}>
            {isPdfsLoading ? (
              <tr style={{ width: "100%" }}>
                <td style={{ width: "100%" }}>Loading...</td>
              </tr>
            ) : filesList && filesList.length > 0 ? (
              filesList.map((item, index) =>
                item.files && item.files.length > 0
                  ? item.files.map((file, fileIndex) => (
                      <tr key={fileIndex} style={{ width: "100%" }}>
                        <td style={{ width: "100%" }}>
                          {(item.confirm && item.confirm.FileName) ||
                          (item.process && item.process.FileName) ? (
                            <button
                              type="button"
                              onClick={(e) =>
                                openFile(
                                  item.confirm
                                    ? item.confirm.Folder
                                    : item.process.FolderName,
                                  item.confirm
                                    ? item.confirm.FileName
                                    : item.process.FileName,
                                  file,
                                  e,
                                )
                              }
                              style={{
                                background: "none",
                                border: "none",
                                padding: "0",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            >
                              {file}
                            </button>
                          ) : (
                            <span>{file}</span>
                          )}
                        </td>
                      </tr>
                    ))
                  : null,
              )
            ) : isFetchEmpty && !isUserHide ? (
              <tr style={{ width: "100%" }}>
                <td style={{ width: "100%" }}>No files available</td>
              </tr>
            ) : (
              <tr style={{ width: "100%" }}>
                <td style={{ width: "100%" }}>Click a Show PDFs button!</td>
              </tr>
            )}
          </tbody>
        </SimpleBar>
      </table>
    </div>
  );
};

PdfFilesTable.propTypes = {
  filesList: PropTypes.array,
  isPdfsLoading: PropTypes.bool,
  isUserHide: PropTypes.bool,
};

export default PdfFilesTable;
