import React from "react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ScrollingBanner = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [userCompany, setUserCompany] = useState(null);
  const [userProductLocks, setUserProductLocks] = useState(null);
  const { isLoading } = useAuth0();
  const [productMessage, setProductMessage] = useState("");

  useEffect(() => {
    const fetchUserCompany = async () => {
      if (userCompany) return;

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      fetch("/api/DealerUserIDName/DealerName", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => setUserCompany(data.dealerName))
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    fetchUserCompany();
  }, [getAccessTokenSilently, userCompany]);

  useEffect(() => {
    const fetchUserProductLock = async () => {
      if (userProductLocks) return;

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      fetch("/api/DealerUserIDName/GetProduct", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUserProductLocks(data);

          if (data.toString() === "S") {
            setProductMessage("You can not order patio covers or pavilions.");
          } else if (data.toString() === "P") {
            setProductMessage("You can not order sunrooms.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    fetchUserProductLock();
  }, [getAccessTokenSilently, userProductLocks]);

  if (isLoading) {
    return (
      <div className="banner">
        <div className="scrolling-text">Loading...</div>
      </div>
    );
  }
  if (!userCompany && !userProductLocks) {
    return (
      <div className="banner">
        <div className="scrolling-text">Loading...</div>
      </div>
    );
  }
  return (
    <div className="banner">
      <div className="scrolling-text">
        Welcome {userCompany}. {productMessage}
      </div>
    </div>
  );
};

export default ScrollingBanner;
