import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

function SwingDoorOrientation({
  setSelectedSwingDoorOrientation,
  index,
  newIndex,
  setshowSwingDoorOrientation,
  selectedSwingDoorOrientation,
  redFieldMessage,
  handleSwingDoorOrientation,
}) {
  const L_LeftInswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/L-LEFT-INSWING.jpg`;
  const L_RightInswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/L-RIGHT-INSWING.jpg`;
  const L_LeftOutswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/L-LEFT-OUTSWING.jpg`;
  const L_RightOutswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/L-RIGHT-OUTSWING.jpg`;
  const F_LeftInswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/F-LEFT-INSWING.jpg`;
  const F_RightInswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/F-RIGHT-INSWING.jpg`;
  const F_LeftOutswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/F-LEFT-OUTSWING.jpg`;
  const F_RightOutswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/F-RIGHT-OUTSWING.jpg`;
  const R_LeftInswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/R-LEFT-INSWING.jpg`;
  const R_RightInswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/R-RIGHT-INSWING.jpg`;
  const R_LeftOutswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/R-LEFT-OUTSWING.jpg`;
  const R_RightOutswing = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/R-RIGHT-OUTSWING.jpg`;
  const orderedImageSets = {
    0: [L_RightInswing, L_LeftInswing, L_LeftOutswing, L_RightOutswing],
    1: [F_RightInswing, F_LeftInswing, F_LeftOutswing, F_RightOutswing],
    2: [R_RightInswing, R_LeftInswing, R_LeftOutswing, R_RightOutswing],
  };

  const orderedImageCaptions = [
    "Right-Inswing",
    "Left-Inswing",
    "Left-Outswing",
    "Right-Outswing",
  ];

  const images = orderedImageSets[newIndex];

  const handleCloseModal = () => {
    setshowSwingDoorOrientation(false);
  };

  return ReactDOM.createPortal(
    <div
      style={{
        zIndex: 2005,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="modal">
        <div
          className="modal-content"
          style={{ width: "80%", height: "80%", overflowY: "auto" }}
        >
          {selectedSwingDoorOrientation[index] === null && (
            <button
              className="navbutton"
              style={{
                position: "absolute",
                top: "1%",
                right: "1%",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={handleCloseModal}
            >
              X
            </button>
          )}
          {selectedSwingDoorOrientation[index] !== null && (
            <button
              className="navbutton"
              style={{
                position: "absolute",
                top: "1%",
                right: "1%",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={handleCloseModal}
            >
              Done
            </button>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {images.map((image, imageIndex) => (
              <div
                key={imageIndex}
                style={{
                  flex: "0 0 50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "49%",
                  cursor: "pointer",
                  position: "relative",
                }}
                className="popupwindow"
                onClick={() =>
                  handleSwingDoorOrientation(
                    orderedImageCaptions[imageIndex],
                    index,
                    redFieldMessage,
                    setSelectedSwingDoorOrientation,
                  )
                }
              >
                <img
                  src={image}
                  alt={`Orientation ${imageIndex + 1}`}
                  style={{ width: "50%", height: "90%" }}
                />
                {selectedSwingDoorOrientation[index] ===
                  orderedImageCaptions[imageIndex] && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(173, 216, 230, 0.5)",
                      pointerEvents: "none",
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root"),
  );
}

SwingDoorOrientation.propTypes = {
  setSelectedSwingDoorOrientation: PropTypes.func,
  index: PropTypes.number,
  setshowSwingDoorOrientation: PropTypes.func,
  selectedSwingDoorOrientation: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  redFieldMessage: PropTypes.string,
};

export default SwingDoorOrientation;
