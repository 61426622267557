import React, { useState } from "react";
import AscendingDescendingButton from "../ascending_descending.jsx";
import ConfirmedRow from "./ConfirmedRow";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const ConfirmedTable = ({
  confirmedIsLoading,
  confirmed,
  confirmedData,
  reformatScheduledDate,
  handleShowPdfFilesTable,
  sortOrderByName,
  setSortOrderByName,
  sortOrderByDate,
  setSortOrderByDate,
  setConfirmedData,
  sortOrderBySchedDate,
  setSortOrderBySchedDate,
  currentConfirm,
}) => {
  const [clickedButtons, setClickedButtons] = useState({});

  const handleConfirmButtonClick = (index) => {
    setClickedButtons((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const sortConfirmedByJobName = (order) => {
    const sortedConfirmed = [...confirmed].sort((a, b) => {
      const nameA = a.JobName.replace(/^\(C\) /i, "");
      const nameB = b.JobName.replace(/^\(C\) /i, "");

      if (order === "asc") {
        return nameA.localeCompare(nameB);
      } else if (order === "desc") {
        return nameB.localeCompare(nameA);
      }
      return 0;
    });
    return sortedConfirmed;
  };

  const sortConfirmedByConfirmationDate = (order) => {
    const sortedConfirmed = [...confirmed].sort((a, b) => {
      const dateA = new Date(a.Confirmation);
      const dateB = new Date(b.Confirmation);

      if (order === "asc") {
        return dateA - dateB;
      } else if (order === "desc") {
        return dateB - dateA;
      }
      return 0;
    });
    return sortedConfirmed;
  };

  const sortConfirmedByScheduledDate = (order) => {
    const sortedConfirmed = [...confirmed].sort((a, b) => {
      const dateA = reformatScheduledDate(a.SchedDate);
      const dateB = reformatScheduledDate(b.SchedDate);

      if (!dateA && !dateB) {
        return 0;
      } else if (!dateA) {
        return order === "asc" ? -1 : 1;
      } else if (!dateB) {
        return order === "asc" ? 1 : -1;
      }

      if (order === "asc") {
        return dateA.localeCompare(dateB);
      } else if (order === "desc") {
        return dateB.localeCompare(dateA);
      }
      return 0;
    });
    return sortedConfirmed;
  };

  const handleSortOrderByScheduledDate = () => {
    const newOrder = sortOrderBySchedDate === "asc" ? "desc" : "asc";
    const sortedData = sortConfirmedByScheduledDate(newOrder);
    setSortOrderBySchedDate(newOrder);
    setConfirmedData(sortedData);
  };

  const handleSortByName = () => {
    const newOrder = sortOrderByName === "asc" ? "desc" : "asc";
    const sortedData = sortConfirmedByJobName(newOrder);
    setSortOrderByName(newOrder);
    setConfirmedData(sortedData);
  };

  const handleSortByDate = () => {
    const newOrder = sortOrderByDate === "asc" ? "desc" : "asc";
    const sortedData = sortConfirmedByConfirmationDate(newOrder);
    setSortOrderByDate(newOrder);
    setConfirmedData(sortedData);
  };

  return (
    <table style={{ maxHeight: "60vh", width: "100%", tableLayout: "fixed" }}>
      {/* Confirmed table */}
      <thead
        className="table-header-div theadThStyle tableHeaderTableHeight"
        style={{ width: "100%" }}
      >
        <tr style={{ width: "100%" }}>
          {/* Table header for Confirmed */}
          <th className="theadThStyle"> Folder Name </th>
          <th className="theadThStyle">
            Job Name <AscendingDescendingButton onClick={handleSortByName} />
          </th>
          <th className="theadThStyle"> File Name </th>
          <th className="theadThStyle">
            Confirmation Date
            <AscendingDescendingButton onClick={handleSortByDate} />
          </th>
          <th className="theadThStyle">
            Production Date
            <AscendingDescendingButton
              onClick={handleSortOrderByScheduledDate}
            />
          </th>
          <th
            className="theadThStyle"
            style={{ borderRight: "1px solid transparent" }}
          >
            PDF Files
          </th>
        </tr>
      </thead>
      <SimpleBar style={{ maxHeight: "50vh", width: "100%" }}>
        <tbody
          style={{
            display: "block",
            width: "100%",
          }}
        >
          {confirmedIsLoading ? (
            <tr style={{ width: "100%" }}>
              <td colSpan={6} style={{ width: "100%" }}>
                Loading...
              </td>
            </tr>
          ) : confirmed.length === 0 ? (
            <tr style={{ width: "100%" }}>
              <td colSpan={6} style={{ width: "100%" }}>
                No data available.
              </td>
            </tr>
          ) : (
            confirmedData.map((confirm, i) => (
              <ConfirmedRow
                reformatScheduledDate={reformatScheduledDate}
                handleShowPdfFilesTable={handleShowPdfFilesTable}
                confirm={confirm}
                key={i}
                currentConfirm={currentConfirm}
                isConfirmButtonClicked={clickedButtons[i]}
                handleConfirmButtonClick={() => handleConfirmButtonClick(i)}
              />
            ))
          )}
        </tbody>
      </SimpleBar>
      <tfoot style={{ width: "100%" }}>
        <tr style={{ width: "100%" }}>
          <td
            style={{
              backgroundColor: "#9ecb6a",
              fontWeight: "bold",
              color: "white",
              width: "100%",
            }}
            colSpan="6"
          >
            <b>For questions about shipping please contact:</b>
            <br></br>
            <b>Keith Conner:</b> 586-412-9826
            <a href="mailto:kconner@temoinc.com">kconner@temoinc.com</a>
            <br></br>
            <b>Linda Armagost:</b> 586-412-9837
            <a href="mailto:laramagost@temoinc.com">larmagost@temoinc.com</a>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

ConfirmedTable.propTypes = {
  handleSortOrderByScheduledDate: PropTypes.func,
  confirmedIsLoading: PropTypes.bool,
  confirmed: PropTypes.array,
  confirmedData: PropTypes.array,
  reformatScheduledDate: PropTypes.func,
  handleShowPdfFilesTable: PropTypes.func,
  sortOrderByName: PropTypes.string,
  setSortOrderByName: PropTypes.func,
  sortOrderByDate: PropTypes.string,
  setSortOrderByDate: PropTypes.func,
  setConfirmedData: PropTypes.func,
  sortOrderBySchedDate: PropTypes.string,
  setSortOrderBySchedDate: PropTypes.func,
  currentConfirm: PropTypes.object,
};

ConfirmedTable.displayName = "ConfirmedTable";

export default ConfirmedTable;
