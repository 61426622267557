import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import InfoIcon from "../../../UniversalComponents/InfoIcon";

const RightAngle = ({
  redBorderFields,
  rightAngleFeet,
  rightAngleInches,
  handleRightAngleFeetChange,
  handleRightAngleInchesChange,
  rightSideMissingWallLength,
  setRightMissingWallLength,
  setRightSideMissingWallDiv,
  selectedAngleOption,
}) => {
  const VerticalLine = `https://temoappstorage.blob.core.windows.net/temoimages/LincolnLogPhotos/Vertical-Line.jpg`;
  const handleABSquaredLine = useCallback(
    (value) => {
      const generateRightMissingWallLength = (total, selectedAngledOption) => {
        let newTotal = Number(total);
        return Array.from({ length: newTotal }, (_, index) => {
          let gridRow;
          let gridColumn;
          let height = "100%";
          switch (selectedAngledOption.toString()) {
            case "3":
              gridColumn = "37 / span 1";
              gridRow = "34 / span 6";
              height = "100%";
              break;
            case "6":
              gridColumn = "36 / span 1";
              gridRow = "30 / span 7";
              height = "100%";
              break;
            case "7":
              gridColumn = "37 / span 1";
              gridRow = "34 / span 7";
              height = "100%";
              break;
            case "9":
              gridColumn = "34 / span 1";
              gridRow = "33 / span 7";
              height = "100%";
              break;
            case "10":
              gridColumn = "36 / span 1";
              gridRow = "28 / span 7";
              height = "100%";
              break;
            default:
              gridRow = "34 / span 7";
              height = "100%";
              gridColumn = "36 / span 1";

              break;
          }
          return {
            gridColumn: gridColumn,
            gridRow: gridRow,
            backgroundImage: VerticalLine,
            cursorPointer: false,
            width: "100%",
            height: height,
            id: `right-${index}`,
          };
        });
      };
      const numericValue = parseInt(value, 10);
      if (numericValue >= 0 && numericValue <= 4) {
        setRightMissingWallLength(
          generateRightMissingWallLength(numericValue, selectedAngleOption),
        );
      } else {
        setRightMissingWallLength([]);
      }
    },
    [VerticalLine, setRightMissingWallLength, selectedAngleOption],
  );

  useEffect(() => {
    handleABSquaredLine(1);
  }, [handleABSquaredLine]);

  useEffect(() => {
    setRightSideMissingWallDiv([...rightSideMissingWallLength]);
  }, [rightSideMissingWallLength, setRightSideMissingWallDiv]);

  return (
    <div className="popupwindow">
      <h6 className="greenBackground headers">
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Distance to Front Wall
        </span>
        <InfoIcon defaultMessage="" />
      </h6>
      <div className="fontsizeforinputsrow" id="rooflength">
        <span className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="redasterisk">&#10033;</span>
            Feet:&nbsp;
          </span>
          <input
            id="lengthfeet"
            className={
              redBorderFields.includes("Right Angle Length Feet")
                ? "redBackground"
                : "whiteBackground"
            }
            value={rightAngleFeet}
            onChange={handleRightAngleFeetChange}
            maxLength={3}
            pattern="\d*"
            style={{ width: "50%" }}
          />
        </span>
        <span className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="redasterisk">&#10033;</span>
            Inch:&nbsp;
          </span>
          <input
            id="lengthinches"
            className={
              redBorderFields.includes("Right Angle Length Inches")
                ? "redBackground"
                : "whiteBackground"
            }
            value={rightAngleInches}
            onChange={handleRightAngleInchesChange}
            style={{ width: "50%" }}
          />
        </span>
      </div>
    </div>
  );
};

RightAngle.propTypes = {
  redBorderFields: PropTypes.array,
  rightAngleFeet: PropTypes.string,
  rightAngleInches: PropTypes.string,
  handleRightAngleFeetChange: PropTypes.func,
  handleRightAngleInchesChange: PropTypes.func,
  rightSideMissingWallLength: PropTypes.array,
  setRightMissingWallLength: PropTypes.func,
  setRightSideMissingWallDiv: PropTypes.func,
  selectedAngleOption: PropTypes.number,
};

export default RightAngle;
