import React from "react";

const SuccessMessage = () => (
  <div>
    SUCCESS! Your file has been sent.
    <br />
    Thank you for ordering from TEMO!
    <br />
  </div>
);

export default SuccessMessage;
