import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Tab = forwardRef(({ activeTab, label, onClick }, ref) => {
  const handleTabClick = () => {
    onClick(label);
  };

  let className = "tab-list-item";

  if (activeTab === label) {
    className += " tab-list-active";
  }

  return (
    <li className={className} onClick={handleTabClick} ref={ref}>
      {label}
    </li>
  );
});

Tab.displayName = "Tab";

Tab.propTypes = {
  activeTab: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  closeTxtFilesTable: PropTypes.func, // New prop for closing PdfFilesTable
};

export default Tab;
