import React, { useState, useEffect } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const ChairRailSelection = ({
  handleChairRailChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [chairRailWidthInchesOption, setChairRailWidthInchesOption] =
    useState("");
  const [chairRailWidthFeetOption, setChairRailWidthFeetOption] = useState("");

  const handleChairRailInchesChange = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, "");

    const numericInput = parseFloat(input);
    const isValidNumber = !isNaN(numericInput) && numericInput < 12;

    if (chairRailWidthFeetOption === "5") {
      input = "0";
    } else if (input !== "" && !isValidNumber) {
      if (numericInput >= 12) {
        input = "11.999";
      } else {
        input = numericInput.toString();
      }
    }

    setChairRailWidthInchesOption(input);

    const updatedRedBorderFields = redBorderFields.includes("chair rail inches")
      ? input
        ? redBorderFields.filter((field) => field !== "chair rail inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "chair rail inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleChairRailChange(chairRailWidthFeetOption, input, "width");
  };

  const handleChairRailWidthChange = (event) => {
    let input = event.target.value.replace(/\D/g, "").slice(0, 1);
    if (parseInt(input, 10) > 5) {
      input = "5";
    }

    setChairRailWidthFeetOption(input);

    if (input === "5") {
      setChairRailWidthInchesOption("0");
    }

    const updatedRedBorderFields = redBorderFields.includes("chair rail feet")
      ? input
        ? redBorderFields.filter((field) => field !== "chair rail feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "chair rail feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleChairRailChange(
      input,
      input === "5" ? "0" : chairRailWidthInchesOption,
    );
  };

  useEffect(() => {
    handleChairRailChange(
      chairRailWidthFeetOption,
      chairRailWidthInchesOption,
      "width",
    );
  }, [
    chairRailWidthFeetOption,
    chairRailWidthInchesOption,
    handleChairRailChange,
  ]);

  return (
    <div style={{ flex: 1 }}>
      <div className="popupwindow">
        <h6 className="greenBackground headers">
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Chair Rail
          </span>
          <InfoIcon defaultMessage="Railing around the Sunroom to lean chairs on." />
        </h6>

        <div id="roofwidth" className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Feet:&nbsp;
            </span>
            <input
              id="widthfeet"
              className={
                redBorderFields.includes("chair rail feet")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={chairRailWidthFeetOption}
              onChange={handleChairRailWidthChange}
              maxLength={1}
              pattern="\d*"
              style={{ width: "50%" }}
            />
          </span>
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Inch:&nbsp;
            </span>
            <input
              id="widthinches"
              className={
                redBorderFields.includes("chair rail inches")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={chairRailWidthInchesOption}
              onChange={handleChairRailInchesChange}
              style={{ width: "50%" }}
              disabled={chairRailWidthFeetOption === "5"}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

ChairRailSelection.propTypes = {
  handleChairRailChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default ChairRailSelection;
