import React, { useState } from "react";
import InfoIcon from "./InfoIcon";
import PropTypes from "prop-types";

const BiHexSelection = ({
  handleBiHexToolOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [BiHexSelectedOption, setBiHexSelectedOption] = useState("");

  const handleBiHexOptionChange = (pickedoption) => {
    setBiHexSelectedOption(pickedoption);
    const updatedRedBorderFields = redBorderFields.includes("Bi-Hex tool")
      ? pickedoption
        ? redBorderFields.filter((field) => field !== "Bi-Hex tool")
        : redBorderFields
      : pickedoption
        ? redBorderFields
        : [...redBorderFields, "Bi-Hex tool"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleBiHexToolOptionChange(pickedoption);
  };

  return (
    <div className="popupwindow bihex-stuff">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Bi-Hex tool")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Bi-Hex MM Drive Tool
        </span>
        <InfoIcon defaultMessage="This Drive Tool is used to fasten the Bi-Hex #12 screws.<br/>Each installer needs one." />
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${BiHexSelectedOption === "DRIVE TOOL" ? "clicked" : ""}`}
          onClick={() => handleBiHexOptionChange("DRIVE TOOL")}
        >
          Yes
        </div>
        &nbsp;
        <div
          className={`clickable-word ${BiHexSelectedOption === "NO DRIVE TOOL" ? "clicked" : ""}`}
          onClick={() => handleBiHexOptionChange("NO DRIVE TOOL")}
        >
          No
        </div>
      </div>
    </div>
  );
};

BiHexSelection.propTypes = {
  handleBiHexToolOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default BiHexSelection;
