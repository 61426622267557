import React from "react";
import PropTypes from "prop-types";

function LineardoorLocations({
  redBorderFields,
  index,
  selectedLocations,
  redFieldMessage,
  setSelectedLocations,
  handleDoorLocationChange,
  selectedBreezeWay,
  selectedPavilionLayoutOption,
  isFront,
}) {
  return (
    <label
      className="popupwindow"
      style={{
        width: "9vw",
        alignSelf: "center",
        height: "8vh",
        color: "black",
        fontSize: "1.4vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h6
        className={
          "headers " +
          (redBorderFields.includes(`${redFieldMessage}`)
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Door Location
        </span>
      </h6>
      <select
        style={{
          cursor: "pointer",
          width: "fit-content",
          fontSize: "1.4vh",
          alignSelf: "center",
          border: "1px solid black",
        }}
        value={selectedLocations[index]}
        onChange={(e) =>
          handleDoorLocationChange(
            e.target.value,
            index,
            selectedLocations,
            setSelectedLocations,
            redFieldMessage,
          )
        }
      >
        <option value="">Select...</option>
        {((selectedBreezeWay === "Yes" && index === 1) || index !== 1) &&
          !isFront && (
            <>
              <option value="At House">At House</option>
              <option value="Centered">Centered</option>
              <option value="At Front">At Front</option>
            </>
          )}
        {((index === 1 &&
          selectedPavilionLayoutOption !== "3" &&
          selectedBreezeWay !== "Yes") ||
          selectedPavilionLayoutOption === "3" ||
          isFront) && (
          <>
            <option value="Left Side">Left Side</option>
            <option value="Middle">Middle</option>
            <option value="Right Side">Right Side</option>
          </>
        )}
      </select>
    </label>
  );
}

LineardoorLocations.propTypes = {
  redBorderFields: PropTypes.array,
  index: PropTypes.number,
  selectedLocations: PropTypes.array,
  handleDoorLocationChange: PropTypes.func,
  setSelectedLocations: PropTypes.func,
  redFieldMessage: PropTypes.string,
  selectedBreezeWay: PropTypes.string,
  selectedPavilionLayoutOption: PropTypes.string,
  isFront: PropTypes.bool,
};

export default LineardoorLocations;
