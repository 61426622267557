import React, { useState } from "react";
import PropTypes from "prop-types";

const ExistingConcreteSlab = ({
  handleExistingFoundationChange,
  redBorderFields,
  setFieldsWithRedBorder,
  setSelectedExistingConcrete,
  selectedFoundationOption,
}) => {
  const [selectedExistingConcreteOption, setSelectedExistingConcreteOption] =
    useState("");

  const handleExistingConcreteOptionChange = (pickedoption) => {
    if (selectedFoundationOption !== "existingconcrete") {
      pickedoption = "";
    }
    setSelectedExistingConcreteOption(pickedoption);
    const updatedRedBorderFields = redBorderFields.includes(
      "Pick an existing slab option",
    )
      ? pickedoption
        ? redBorderFields.filter(
            (field) => field !== "Pick an existing slab option",
          )
        : redBorderFields
      : pickedoption
        ? redBorderFields
        : [...redBorderFields, "Pick an existing slab option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleExistingFoundationChange(pickedoption);
    setSelectedExistingConcrete(pickedoption);
  };

  return (
    <div className="popupwindow existingconcretefooting">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Pick an existing slab option")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Footing Option
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedExistingConcreteOption === "existingfoot" ? "clicked" : ""}`}
          onClick={() => handleExistingConcreteOptionChange("existingfoot")}
        >
          Existing&nbsp;Footing
        </div>
        <div
          className={`clickable-word ${selectedExistingConcreteOption === "none" ? "clicked" : ""}`}
          onClick={() => handleExistingConcreteOptionChange("none")}
        >
          None
        </div>
      </div>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedExistingConcreteOption === "newfooting" ? "clicked" : ""}`}
          onClick={() => handleExistingConcreteOptionChange("newfooting")}
        >
          New&nbsp;Footing
        </div>
        <div
          className={`clickable-word ${selectedExistingConcreteOption === "newpiers" ? "clicked" : ""}`}
          onClick={() => handleExistingConcreteOptionChange("newpiers")}
        >
          New&nbsp;Piers
        </div>
      </div>
    </div>
  );
};

ExistingConcreteSlab.propTypes = {
  handleExistingFoundationChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  setSelectedExistingConcrete: PropTypes.func,
  selectedFoundationOption: PropTypes.string,
};

export default ExistingConcreteSlab;
