import React from "react";

const ThreeWall = () => (
  <div className="centered-text">
    My structure backs up to three walls.
    <br />
    I need extra hanging rail on two walls.
    <br />
  </div>
);

export default ThreeWall;
