import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        className="showpdfbuttons"
        style={{ width: "20vw", height: "8vh", fontSize: "3vh" }}
        onClick={loginWithRedirect}
      >
        Login
      </button>
    </div>
  );
}

export default Login;
