import React, { useState } from "react";
import CableAndHangingRail from "./OperableOrFixedComponents/CableAndHangingRail";
import ImageButton from "../../UniversalComponents/ImageButton";
import PropTypes from "prop-types";

const OperableOrNot = ({
  handleOperableChange,
  handleActuatorOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const Fixed = `https://temoappstorage.blob.core.windows.net/temoimages/PergolaViewingImages/Fixed.jpg`;
  const Operable = `https://temoappstorage.blob.core.windows.net/temoimages/PergolaViewingImages/Operable.jpg`;
  const MotorCity = `https://temoappstorage.blob.core.windows.net/temoimages/PergolaViewingImages/MotorCity.jpg`;
  const images = [Operable, Fixed, MotorCity];
  const messages = ["Operable", "Fixed", "Motor City"];
  const [selectedOperableOption, setSelectedOperableOption] = useState("");
  const [showCableComponent, setShowCableComponent] = useState(false);

  const handleOperableOptionChange = (value) => {
    handleOperableChange(value);
    const updatedRedBorderFields = redBorderFields.includes("Operable or Fixed")
      ? value
        ? redBorderFields.filter((field) => field !== "Operable or Fixed")
        : redBorderFields
      : value
        ? redBorderFields
        : [...redBorderFields, "Operable or Fixed"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    setSelectedOperableOption(value);
    // Set showCableComponent to true if "operable" is selected
    setShowCableComponent(value === "PERGOLA OPERABLE");

    // Set the actuator option based on the operable selection
    if (value === "PERGOLA FIXED") {
      handleActuatorOptionChange("");
    }
  };

  return (
    <div>
      <div className="popupwindow operableornot">
        <h6
          className={
            "headers " +
            (redBorderFields.includes("Operable or Fixed")
              ? "redBackground"
              : "greenBackground")
          }
        >
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Pergola Type
          </span>
          <ImageButton images={images} messages={messages} />
        </h6>
        <div className="clickable-words">
          <div
            className={`clickable-word ${selectedOperableOption === "PERGOLA OPERABLE" ? "clicked" : ""}`}
            onClick={() => handleOperableOptionChange("PERGOLA OPERABLE")}
          >
            Operable
          </div>
          <div
            className={`clickable-word ${selectedOperableOption === "PERGOLA FIXED" ? "clicked" : ""}`}
            onClick={() => handleOperableOptionChange("PERGOLA FIXED")}
          >
            Fixed
          </div>
          &nbsp;
          <div
            className={`clickable-word ${selectedOperableOption === "MOTOR CITY" ? "clicked" : ""}`}
            onClick={() => handleOperableOptionChange("MOTOR CITY")}
          >
            Motor City
          </div>
        </div>
      </div>
      {showCableComponent && (
        <CableAndHangingRail
          handleActuatorOptionChange={handleActuatorOptionChange}
          redBorderFields={redBorderFields}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
        />
      )}
    </div>
  );
};

OperableOrNot.propTypes = {
  handleOperableChange: PropTypes.func,
  handleActuatorOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default OperableOrNot;
