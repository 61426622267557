import React /*, { useEffect, useState } */ from "react";
// import SparePart from "../../../components/OrderingSystemComponents/SparePartsComponents/SparePart";
const SpareParts = () => {
  // const [partNames, setPartNames] = useState([
  //   "name",
  //   "test",
  //   "test",
  //   "test",
  //   "test",
  //   "test",
  //   "test",
  //   "test",
  //   "test",
  // ]);
  // const [partPrices, setPartPrices] = useState(["price"]);
  // const [partImages, setPartImages] = useState([]);

  // useEffect(() => {
  //   fetch("/api/SQLThings/SpareParts")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const names = [];
  //       const prices = [];
  //       const partImages = [];
  //       data.forEach((part) => {
  //         names.push(part.name);
  //         prices.push(part.price);
  //         partImages.push(part.image);
  //       });
  //       setPartNames(names);
  //       setPartPrices(prices);
  //       setPartImages(partImages);
  //     });
  // }, []);

  return (
    // <div className="pergola">
    //   <div
    //     style={{
    //       height: "90vh",
    //       marginTop: "10vh",
    //       display: "grid",
    //       gridTemplateColumns: "1fr 9fr",
    //     }}
    //   >
    //     <div>Filters</div>
    //     <div
    //       style={{
    //         display: "grid",
    //         gridTemplateColumns: "1fr 1fr 1fr 1fr",
    //         gridTemplateRows:
    //           partNames[1] !== "" ? `repeat(${partNames.length / 3} 1fr)` : "",
    //       }}
    //     >
    //       {partNames.map((part, i) => (
    //         <SparePart
    //           key={part}
    //           partName={
    //             partNames[i] !== "" ? partNames[i] : "No Name Available"
    //           }
    //           partPrice={
    //             partPrices[i] !== "" ? partPrices[i] : "No Price Available"
    //           }
    //           partImage={partImages[i] !== "" ? partImages[i] : ""}
    //           index={i}
    //         />
    //       ))}
    //     </div>
    //   </div>
    // </div>
    <div>
      <div>
        <h1>Under Construction</h1>
      </div>
    </div>
  );
};

export default SpareParts;
