import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import SingleWallSelection from "../SingleWallSelection";

const FrontSide = ({
  setFrontSideDivs,
  setFrontSidePickMeDivs,
  redBorderFields,
  dimension,
  setWallERFLengthsFeet,
  setWallERFLengthsInches,
  wallERFLengthsInches,
  wallERFLengthsFeet,
  handleWallLengthFeetChange,
  setFrontCavities,
  handleWallLengthInchesChange,
  doorERFWidth,
  setDoorERFWidth,
  handleDoorOptionChange,
  doorERFOption,
  setDoorERFOption,
  lastWallLengthUpdate,
  setLastWallLengthUpdate,
  handleDoorWidthChange,
  selectedSunRoomType,
  setFieldsWithRedBorder,
  selectedERFPatioDoorOrientation,
  selectedERFSwingDoorOrientation,
  setSelectedERFSwingDoorOrientation,
  setSelectedERFPatioDoorOrientation,
  setSelectedERFCodes,
  selectedERFCodes,
  selectedERFButtonIndex,
  setSelectedERFButtonIndex,
  selectedERFImages,
  setSelectedERFImages,
  handleCodeSelect,
  horizontalLines,
  setHorizontalLines,
  pickMeFrontDivs,
  setBlackFrontSquares,
  blackFrontSquares,
  setFrontPickMes,
  index,
  setIndex,
  openFrontSingleDrawing,
  setOpenFrontSingleDrawing,
  isScreenRoom,
  setSelectedERFLocations,
  selectedERFLocations,
  handleDoorLocationChange,
  selectedBreezeWay,
  selectedPavilionLayoutOption,
  handleSelectedPatioDoorOrientation,
  currentImage,
  handleSwingDoorOrientation,
}) => {
  const BlackSquare = `https://temoappstorage.blob.core.windows.net/temoimages/LincolnLogPhotos/Black_square.jpg`;
  const HorizontalLine = `https://temoappstorage.blob.core.windows.net/temoimages/LincolnLogPhotos/HorizontalLine.jpg`;
  const ClickMeFront = `https://temoappstorage.blob.core.windows.net/temoimages/LincolnLogPhotos/CLICKFRONT.png`;
  const openFrontSidesModal = useCallback(
    (indexValue) => {
      setOpenFrontSingleDrawing(true);
      setIndex(indexValue);
    },
    [setIndex, setOpenFrontSingleDrawing],
  );

  const generateFrontPickMeDivs = useCallback(
    (total) => {
      let newTotal = Number(total);
      return Array.from({ length: newTotal }, (_, index) => {
        let clickMeFrontLength =
          selectedSunRoomType !== "Linear Screen"
            ? (wallERFLengthsFeet[index] || wallERFLengthsFeet[index] === 0) &&
              (wallERFLengthsInches[index] || wallERFLengthsInches[index] === 0)
              ? `${wallERFLengthsFeet[index]}' ${wallERFLengthsInches[index]}"`
              : ""
            : doorERFOption[index] === "No"
              ? "No Door"
              : doorERFOption[index] !== ""
                ? "Yes Door"
                : "";
        let clickMeFrontImage = ClickMeFront;
        let clickMeFrontDoorOption =
          selectedSunRoomType !== "Linear Screen"
            ? doorERFOption[index] === "No"
              ? "No Door"
              : doorERFWidth[index]
            : doorERFOption[index] === "No"
              ? ""
              : selectedERFLocations[index];
        let clickMeFrontOrientation =
          doorERFOption[index] === "No"
            ? ""
            : selectedERFPatioDoorOrientation[index] === ""
              ? selectedERFSwingDoorOrientation[index]
              : selectedERFPatioDoorOrientation[index];

        let clickMeFrontCode =
          selectedSunRoomType !== "Linear Screen"
            ? selectedERFCodes[index]
            : (wallERFLengthsFeet[index] || wallERFLengthsFeet[index] === 0) &&
                (wallERFLengthsInches[index] ||
                  wallERFLengthsInches[index] === 0)
              ? `${wallERFLengthsFeet[index]}' ${wallERFLengthsInches[index]}"`
              : "";
        let gridColumnStart;
        let values = [
          clickMeFrontCode,
          clickMeFrontLength,
          clickMeFrontDoorOption,
          clickMeFrontOrientation,
        ];
        const offset = 5;
        switch (newTotal) {
          case 1:
            gridColumnStart = 15;
            break;
          case 2:
            gridColumnStart = index === 0 ? 9 : 21;
            break;
          case 3:
            gridColumnStart = index === 0 ? 7 : index === 1 ? 15 : 23;
            break;
          case 4:
            gridColumnStart =
              index === 0 ? 6 : index === 1 ? 12 : index === 2 ? 18 : 24;
            break;
          case 5:
            gridColumnStart =
              index === 0
                ? 5
                : index === 1
                  ? 10
                  : index === 2
                    ? 15
                    : index === 3
                      ? 20
                      : 25;
            break;
          default:
            gridColumnStart = index * 4 + offset;
            break;
        }
        return {
          gridColumn: `${gridColumnStart} / span 4`,
          gridRow: "20 / span 6",
          width: "20%",
          height: "100%",
          clickMeFrontImage,
          values,
        };
      });
    },
    [
      selectedSunRoomType,
      wallERFLengthsFeet,
      wallERFLengthsInches,
      doorERFOption,
      ClickMeFront,
      doorERFWidth,
      selectedERFLocations,
      selectedERFPatioDoorOrientation,
      selectedERFSwingDoorOrientation,
      selectedERFCodes,
    ],
  );

  const generateFrontBlackSquares = useCallback(
    (total) => {
      let newTotal = Number(total);
      return Array.from({ length: newTotal - 1 }, (_, index) => {
        const offset = 4;
        let newWidth;
        let gridColumnStart;
        let newSpan;
        switch (newTotal) {
          case 2:
            gridColumnStart = 16;
            newWidth = "50%";
            newSpan = 2;
            break;
          case 3:
            gridColumnStart = index === 1 ? 12 : 20;
            newWidth = "50%";
            newSpan = 2;
            break;
          case 4:
            gridColumnStart = index === 1 ? 10 : index === 2 ? 16 : 22;
            newWidth = "50%";
            newSpan = 2;
            break;
          case 5:
            gridColumnStart =
              index === 1 ? 9 : index === 2 ? 14 : index === 3 ? 19 : 24;
            newSpan = 1;
            newWidth = "100%";
            break;
          default:
            gridColumnStart = (index + 1) * 4 + offset;
            newWidth = "50%";
            newSpan = 2;
            break;
        }
        return {
          gridColumn: `${gridColumnStart} / span ${newSpan}`,
          gridRow: "26 / span 4",
          backgroundImage: BlackSquare,
          cursorPointer: false,
          width: newWidth,
          height: "50%",
        };
      });
    },
    [BlackSquare],
  );

  const handleFrontCavities = useCallback(
    (value) => {
      setFrontCavities(value);
      const generateHorizontalLines = (total) => {
        let newTotal = Number(total);
        return Array.from({ length: newTotal }, (_, index) => {
          const offset = 6;
          let gridColumnStart;
          let newSpan;
          let newWidth;
          switch (newTotal) {
            case 1:
              newSpan = 24;
              gridColumnStart = 5;
              newWidth = "90%";
              break;
            case 2:
              newSpan = 12;
              gridColumnStart = index === 0 ? 5 : 17;
              newWidth = "80%";
              break;
            case 3:
              newSpan = 8;
              gridColumnStart = index === 0 ? 5 : index === 1 ? 13 : 21;
              newWidth = "70%";
              break;
            case 4:
              newSpan = 6;
              gridColumnStart =
                index === 0 ? 5 : index === 1 ? 11 : index === 2 ? 17 : 23;
              newWidth = "60%";
              break;
            case 5:
              newSpan = 4;
              gridColumnStart =
                index === 0
                  ? 5
                  : index === 1
                    ? 10
                    : index === 2
                      ? 15
                      : index === 3
                        ? 20
                        : 25;
              newWidth = "60%";
              break;
            default:
              newWidth = "100%";
              gridColumnStart = index * 4 + offset;
              newSpan = 2;
              break;
          }
          return {
            gridColumn: `${gridColumnStart} / span ${newSpan}`,
            gridRow: "24 / span 8",
            backgroundImage: HorizontalLine,
            onClick: () => openFrontSidesModal(index),
            cursorPointer: true,
            width: newWidth,
            height: "10%",
            id: "front",
          };
        });
      };
      if (value >= 0 && value <= 6) {
        setHorizontalLines(generateHorizontalLines(value));
        setBlackFrontSquares(generateFrontBlackSquares(value));
        setFrontPickMes(generateFrontPickMeDivs(value));
      } else {
        setHorizontalLines([]);
        setBlackFrontSquares([]);
        setFrontPickMes([]);
      }
    },
    [
      HorizontalLine,
      generateFrontBlackSquares,
      generateFrontPickMeDivs,
      openFrontSidesModal,
      setBlackFrontSquares,
      setFrontCavities,
      setFrontPickMes,
      setHorizontalLines,
    ],
  );

  useEffect(() => {
    if (Array.isArray(horizontalLines) && horizontalLines.length === 0) {
      handleFrontCavities(1);
    }
  }, [handleFrontCavities, horizontalLines]);

  useEffect(() => {
    setFrontSideDivs([...horizontalLines, ...blackFrontSquares]);
    setFrontSidePickMeDivs(pickMeFrontDivs);
  }, [
    horizontalLines,
    blackFrontSquares,
    setFrontSideDivs,
    setFrontSidePickMeDivs,
    pickMeFrontDivs,
  ]);

  useEffect(() => {
    const newPickMeLeftDivs = generateFrontPickMeDivs(horizontalLines.length);
    setFrontPickMes(newPickMeLeftDivs);
  }, [
    doorERFOption,
    doorERFWidth,
    selectedERFPatioDoorOrientation,
    selectedERFSwingDoorOrientation,
    selectedERFCodes,
    generateFrontPickMeDivs,
    horizontalLines.length,
    setFrontPickMes,
  ]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <select onChange={(e) => handleFrontCavities(e.target.value)}>
          <option value={1}>1 Cavity</option>
          <option value={2}>2 Cavities</option>
          <option value={3}>3 Cavities</option>
          <option value={4}>4 Cavities</option>
          <option value={5}>5 Cavities</option>
          <option value={6}>6 Cavities</option>
        </select>
      </div>
      {openFrontSingleDrawing && (
        <SingleWallSelection
          setOpenSingleDrawing={setOpenFrontSingleDrawing}
          dimension={dimension}
          wallLengthsFeet={wallERFLengthsFeet}
          wallLengthsInches={wallERFLengthsInches}
          redBorderFields={redBorderFields}
          index={Number(index)}
          handleWallLengthFeetChange={handleWallLengthFeetChange}
          handleWallLengthInchesChange={handleWallLengthInchesChange}
          setWallLengthsFeet={setWallERFLengthsFeet}
          setWallLengthsInches={setWallERFLengthsInches}
          wallLengthMessage={`Front Wall Length Feet ${index + 1}`}
          wallLengthInchesMessage={`Front Wall Length Inches ${index + 1}`}
          doorWidth={doorERFWidth}
          setDoorWidth={setDoorERFWidth}
          handleDoorOptionChange={handleDoorOptionChange}
          doorOption={doorERFOption}
          setDoorOption={setDoorERFOption}
          doorOptionMessage={`Front Door Option ${index + 1}`}
          lastWallLengthUpdate={lastWallLengthUpdate}
          setLastWallLengthUpdate={setLastWallLengthUpdate}
          handleDoorWidthChange={handleDoorWidthChange}
          setNewDoorWidth={setDoorERFWidth}
          redFieldMessage={`Front Door Width ${index + 1}`}
          selectedSunRoomType={selectedSunRoomType}
          isFront={true}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
          selectedPatioDoorOrientation={selectedERFPatioDoorOrientation}
          selectedSwingDoorOrientation={selectedERFSwingDoorOrientation}
          setSelectedSwingDoorOrientation={setSelectedERFSwingDoorOrientation}
          setSelectedPatioDoorOrientation={setSelectedERFPatioDoorOrientation}
          initialSelectedCodes={selectedERFCodes}
          setNewSelectedCodes={setSelectedERFCodes}
          selectedButtonIndex={selectedERFButtonIndex}
          setSelectedButtonIndex={setSelectedERFButtonIndex}
          selectedImages={selectedERFImages}
          setSelectedImages={setSelectedERFImages}
          newIndex={1}
          handleCodeSelect={handleCodeSelect}
          redPatioFieldMessage={`Front Patio Door Orientation ${index + 1}`}
          redSwingFieldMessage={`Front Swing Door Orientation ${index + 1}`}
          redWallFieldMessage={`Front Wall Code ${index + 1}`}
          isScreenRoom={isScreenRoom}
          setSelectedLocations={setSelectedERFLocations}
          selectedLocations={selectedERFLocations}
          handleDoorLocationChange={handleDoorLocationChange}
          selectedBreezeWay={selectedBreezeWay}
          selectedPavilionLayoutOption={selectedPavilionLayoutOption}
          handleSelectedPatioDoorOrientation={
            handleSelectedPatioDoorOrientation
          }
          currentImage={currentImage}
          handleSwingDoorOrientation={handleSwingDoorOrientation}
        />
      )}
    </>
  );
};

FrontSide.propTypes = {
  setFrontSideDivs: PropTypes.func,
  redBorderFields: PropTypes.array,
  dimension: PropTypes.number,
  setWallERFLengthsFeet: PropTypes.func,
  setWallERFLengthsInches: PropTypes.func,
  wallERFLengthsInches: PropTypes.array,
  wallERFLengthsFeet: PropTypes.array,
  handleWallLengthFeetChange: PropTypes.func,
  setFrontCavities: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  doorERFWidth: PropTypes.array,
  setDoorERFWidth: PropTypes.func,
  handleDoorOptionChange: PropTypes.func,
  doorERFOption: PropTypes.array,
  setDoorERFOption: PropTypes.func,
  lastWallLengthUpdate: PropTypes.number,
  setLastWallLengthUpdate: PropTypes.func,
  handleDoorWidthChange: PropTypes.func,
  selectedSunRoomType: PropTypes.string,
  setFieldsWithRedBorder: PropTypes.func,
  selectedERFPatioDoorOrientation: PropTypes.array,
  selectedERFSwingDoorOrientation: PropTypes.array,
  setSelectedERFSwingDoorOrientation: PropTypes.func,
  setSelectedERFPatioDoorOrientation: PropTypes.func,
  setSelectedERFCodes: PropTypes.func,
  selectedERFCodes: PropTypes.array,
  selectedERFButtonIndex: PropTypes.array,
  setSelectedERFButtonIndex: PropTypes.func,
  selectedERFImages: PropTypes.array,
  setSelectedERFImages: PropTypes.func,
  handleCodeSelect: PropTypes.func,
  setFrontSidePickMeDivs: PropTypes.func,
  horizontalLines: PropTypes.array,
  setHorizontalLines: PropTypes.func,
  pickMeFrontDivs: PropTypes.array,
  setBlackFrontSquares: PropTypes.func,
  blackFrontSquares: PropTypes.array,
  setFrontPickMes: PropTypes.func,
  index: PropTypes.number || PropTypes.string,
  setIndex: PropTypes.func,
  openFrontSingleDrawing: PropTypes.bool,
  setOpenFrontSingleDrawing: PropTypes.func,
  isScreenRoom: PropTypes.bool,
  setSelectedERFLocations: PropTypes.func,
  selectedERFLocations: PropTypes.array,
  handleDoorLocationChange: PropTypes.func,
  selectedBreezeWay: PropTypes.string,
  selectedPavilionLayoutOption: PropTypes.string,
  handleSelectedPatioDoorOrientation: PropTypes.func,
  currentImage: PropTypes.string,
  handleSwingDoorOrientation: PropTypes.func,
};

export default FrontSide;
