import React, { useState } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const WindowWallStyle = ({
  handleSelectedWindowWallStyle,
  redBorderFields,
  setFieldsWithRedBorder,
  selectedRoomStyle,
}) => {
  const [selectedWindowWallStyle, setSelectedWindowWallStyle] = useState("");

  const handleWindowWallStyleChange = (selected) => {
    setSelectedWindowWallStyle(selected);
    const updatedRedBorderFields = redBorderFields.includes("Window Wall Style")
      ? selected
        ? redBorderFields.filter((field) => field !== "Window Wall Style")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Window Wall Style"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedWindowWallStyle(selected);
  };

  return (
    <div
      className="popupwindow"
      style={{
        flex: 1,
        width: selectedRoomStyle !== "Existing Roof" ? "50%" : "100%",
        marginBottom: "1%",
      }}
    >
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Window Wall Style")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon defaultMessage="206-Single glass and screen<br/>406-Thermal break with insulated glass and screen" />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Window/Wall Style
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedWindowWallStyle === "206" ? "clicked" : ""}`}
          onClick={() => handleWindowWallStyleChange("206")}
        >
          206
        </div>
        <div
          className={`clickable-word ${selectedWindowWallStyle === "406" ? "clicked" : ""}`}
          onClick={() => handleWindowWallStyleChange("406")}
        >
          406
        </div>
      </div>
    </div>
  );
};

WindowWallStyle.propTypes = {
  handleSelectedWindowWallStyle: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  selectedRoomStyle: PropTypes.string,
};

export default WindowWallStyle;
