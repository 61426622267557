import React from "react";
import ExteriorStyleSelection from "./PergolaStyleChoicesComponents/ExteriorStyleSelection";
import PostStyleSelection from "../UniversalComponents/PostStyleSelection";
import TrimColorSelection from "./PergolaStyleChoicesComponents/TrimColorSelection";
import FreesStandingorAttached from "./PergolaStyleChoicesComponents/FreeStandingAttached";
import OperableorNot from "./PergolaStyleChoicesComponents/OperableorNot";
import PropTypes from "prop-types";

const StyleChoices = ({
  permitSelected,
  onAttachedOptionChange,
  handleTrimChange,
  handleOperableOptionChange,
  handlePostStyleOptionChange,
  handleExteriorOptionChange,
  handleActuatorOptionChange,
  handlePostHeightOption,
  redBorderFields,
  setFieldsWithRedBorder,
  handleStructuralBeamChange,
  handleRafterColorOptionChange,
  selectedOperableOption,
  selectedExteriorOption,
}) => {
  return (
    <div>
      <OperableorNot
        setFieldsWithRedBorder={setFieldsWithRedBorder}
        redBorderFields={redBorderFields}
        handleOperableChange={handleOperableOptionChange}
        handleActuatorOptionChange={handleActuatorOptionChange}
      />
      {selectedOperableOption !== "MOTOR CITY" && (
        <>
          <FreesStandingorAttached
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            redBorderFields={redBorderFields}
            permitSelected={permitSelected}
            onAttachedOptionChange={onAttachedOptionChange}
          />
          <ExteriorStyleSelection
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            redBorderFields={redBorderFields}
            handleExteriorStyleOptionChange={handleExteriorOptionChange}
          />
          <PostStyleSelection
            handleExteriorOptionChange={handleExteriorOptionChange}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            redBorderFields={redBorderFields}
            handlePostStyleOptionChange={handlePostStyleOptionChange}
            handlePostHeightOption={handlePostHeightOption}
            handleStructuralBeamOption={handleStructuralBeamChange}
            selectedExteriorOption={selectedExteriorOption}
          />
        </>
      )}
      <TrimColorSelection
        setFieldsWithRedBorder={setFieldsWithRedBorder}
        redBorderFields={redBorderFields}
        handleTrimChange={handleTrimChange}
        handleRafterColorOptionChange={handleRafterColorOptionChange}
      />
    </div>
  );
};

StyleChoices.propTypes = {
  permitSelected: PropTypes.string,
  onAttachedOptionChange: PropTypes.func,
  handleTrimChange: PropTypes.func,
  handleOperableOptionChange: PropTypes.func,
  handlePostStyleOptionChange: PropTypes.func,
  handleExteriorOptionChange: PropTypes.func,
  handleActuatorOptionChange: PropTypes.func,
  handlePostHeightOption: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleStructuralBeamChange: PropTypes.func,
  handleRafterColorOptionChange: PropTypes.func,
  selectedOperableOption: PropTypes.string,
  selectedBeamOption: PropTypes.string,
  selectedExteriorOption: PropTypes.string,
};

export default StyleChoices;
