import React, { Component } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./components/HomeComponents/Login";
import Ordering from "./pages/Ordering_System/Ordering";
import CurrentOrders from "./pages/View_Orders_System/CurrentOrders";
import OrderHistory from "./pages/View_Orders_System/OrderHistory";
import SunRooms from "./pages/Ordering_System/SunRooms/SunRooms";
import SpareParts from "./pages/Ordering_System/SpareParts/SpareParts";
import Pergolas from "./pages/Ordering_System/Pergolas/Pergolas";
import Pavilions from "./pages/Ordering_System/Pavilions/Pavilions";
import Callback from "./components/HomeComponents/Callback";
import NavButtons from "./components/HomeComponents/NavButtons";
import InactivityHandler from "./TimeOut";
function PrivateRoute() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}

function AuthenticatedComponents() {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <>
      <div className="App-header">
        <NavButtons />
      </div>
    </>
  ) : null;
}

class App extends Component {
  render() {
    return (
      <Router>
        <Auth0Provider
          domain="login.temoportal.com"
          clientId="pTCSsx8MxsETEkNBV1EHDWjEbEQETuP9"
          authorizationParams={{
            redirect_uri: window.location.origin + "/login/callback",
            audience: "TEMOAuth",
            response_type: "token id_token",
          }}
        >
          <InactivityHandler>
            <div id="PortalApp" className="App">
              <AuthenticatedComponents />
              <Routes>
                <Route path="/" exact element={<Login />} />
                <Route path="/" element={<PrivateRoute />}>
                  <Route path="/login/callback" element={<Callback />} />
                  <Route path="/Ordering" element={<Ordering />} />
                  <Route path="/CurrentOrders" element={<CurrentOrders />} />
                  <Route path="/OrderHistory" element={<OrderHistory />} />
                  <Route path="/Pergolas" element={<Pergolas />} />
                  <Route path="/SpareParts" element={<SpareParts />} />
                  <Route
                    path="/SunRooms"
                    element={<SunRooms isScreenRoom={false} />}
                  />
                  <Route path="/Pavilions" element={<Pavilions />} />
                  <Route
                    path="/ScreenRooms"
                    element={<SunRooms isScreenRoom={true} />}
                  />
                </Route>
              </Routes>
            </div>
          </InactivityHandler>
        </Auth0Provider>
      </Router>
    );
  }
}

export default App;
