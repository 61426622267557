import React from "react";
import PropTypes from "prop-types";

const StyledDiv = ({
  gridRow,
  gridColumn,
  backgroundImage,
  onClick,
  cursorPointer = false,
  width,
  height,
  id,
}) => {
  return (
    <div
      id={id}
      style={{
        gridColumn,
        gridRow,
        justifySelf: "center",
        alignSelf: "center",
        width,
        height,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        cursor: cursorPointer ? "pointer" : "default",
      }}
      onClick={onClick}
    ></div>
  );
};

StyledDiv.propTypes = {
  gridRow: PropTypes.string,
  gridColumn: PropTypes.string,
  backgroundImage: PropTypes.string,
  onClick: PropTypes.func,
  cursorPointer: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  justifySelf: PropTypes.string,
  alignSelf: PropTypes.string,
  id: PropTypes.string,
};

export default StyledDiv;
