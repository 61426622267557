import React from "react";

const HoldErrorMessage = () => (
  <div>
    You are currently on hold.
    <br />
    Please contact Dana Studer at:
    <br />
    +1-586-412-9831 <br />
    <a href="mailto:dstuder@temoinc.com">dstuder@temoinc.com</a>
  </div>
);

export default HoldErrorMessage;
