import React from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const BackWallPanels = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleBackWallPanelsOption,
}) => {
  const handleDropdownChange = (event) => {
    const selected = event.target.value;
    const updatedRedBorderFields = redBorderFields.includes("back wall panels")
      ? selected
        ? redBorderFields.filter((field) => field !== "back wall panels")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "back wall panels"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleBackWallPanelsOption(selected);
  };

  return (
    <div className="popupwindow value-roof">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("back wall panels")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon defaultMessage='5/8" TemKor panels to cover existing house surface.<br/>TEMO will also send appropriate mullions.' />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Back Wall Panels
        </span>
      </h6>
      <span className="fontsizeforinputs">
        <select
          id="backwallpanels"
          className="input-box"
          style={{ width: "40%" }}
          onChange={handleDropdownChange}
        >
          <option value={""}>Select</option>
          {[...Array(26).keys()].map((num) => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </select>
      </span>
    </div>
  );
};

BackWallPanels.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleBackWallPanelsOption: PropTypes.func,
};

export default BackWallPanels;
