import React from "react";
import PropTypes from "prop-types";

const MissingFieldsBox = ({ missingFields }) => {
  const columnClass =
    missingFields.length > 5 ? "two-columns" : "single-column";

  return (
    <div style={{ fontSize: "large" }}>
      <div className="redasterisk">Please fill in the following fields:</div>
      <ul
        className={columnClass}
        style={{ width: "100%", listStyleType: "none", padding: 0 }}
      >
        {missingFields.map((field, index) => (
          <li key={index}>{field}</li>
        ))}
      </ul>
    </div>
  );
};

MissingFieldsBox.propTypes = {
  missingFields: PropTypes.array,
};

export default MissingFieldsBox;
