import React, { useState } from "react";
import InfoIcon from "./InfoIcon";
import PropTypes from "prop-types";

const CleatsOrBond = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleSelectedCleatsOrBond,
  selectedShingle,
}) => {
  const [selectedCleatOption, setSelectedCleatOption] = useState("");

  const handleCleatBondOptionChange = (selected) => {
    setSelectedCleatOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "Cleats or Bond Tape",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "Cleats or Bond Tape")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Cleats or Bond Tape"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedCleatsOrBond(selected);
    if (selectedShingle === "YES") {
      handleSelectedCleatsOrBond("");
    }
  };

  return (
    <div className="popupwindow cleatsorbond">
      <h6
        style={{ height: "fit-content" }}
        className={
          "headers " +
          (redBorderFields.includes("Cleats or Bond Tape")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon
          defaultMessage={
            "Architectural comes with cleats. <br/>The base cleats cover the roof panel seams. They have a cleat cover.<br/>Bond tape is sent for seams instead of cleats. <br/>The Bond Tape is used to cover the roof seams, in place of the cleats. It is wide and very sticky."
          }
        />
        <span
          className="header-grid-location"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            justifySelf: "center",
            flexDirection: "column",
          }}
        >
          <span>
            <span className="redasterisk">&#10033;</span>Architectural
          </span>
          <span>or Bond Tape</span>
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedCleatOption === "CLEATS" ? "clicked" : ""}`}
          onClick={() => handleCleatBondOptionChange("CLEATS")}
          style={{ fontSize: "1.4vh" }}
        >
          Architect.
        </div>
        <div
          className={`clickable-word ${selectedCleatOption === "BOND-TAPE" ? "clicked" : ""}`}
          onClick={() => handleCleatBondOptionChange("BOND-TAPE")}
          style={{ fontSize: "1.4vh" }}
        >
          Bond&nbsp;Tape
        </div>
      </div>
    </div>
  );
};

CleatsOrBond.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleSelectedCleatsOrBond: PropTypes.func,
  selectedShingle: PropTypes.string,
};

export default CleatsOrBond;
