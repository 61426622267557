import React from "react";

const SunroomLockMessage = () => (
  <div>
    You are only allowed to Order Patio Covers and Pergolas
    <br />
    For more information
    <br />
    Please contact Brendan Bordine at:
    <br />
    +1-586-412-6077 <br />
    <a href="mailto:bbordine@temoinc.com">bbordine@temoinc.com</a>
    <br />
  </div>
);

export default SunroomLockMessage;
