import React, { useState } from "react";
import InfoIcon from "./InfoIcon";
import PropTypes from "prop-types";

const ValueSeries = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleValueSeriesOption,
  fasciaTrimOption,
  setFasciaTrimOption,
}) => {
  const [selectedValueSeries, setValueSeries] = useState("");

  const handleValueSeries = (selected) => {
    setValueSeries(selected);
    const updatedRedBorderFields = redBorderFields.includes("Value Series")
      ? selected
        ? redBorderFields.filter((field) => field !== "Value Series")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Value Series"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleValueSeriesOption(selected);
    if (selected === "YES" && fasciaTrimOption === "bronze") {
      setFasciaTrimOption("");
    }
  };

  return (
    <div className="popupwindow value-roof">
      <h6
        style={{ height: "fit-content" }}
        className={
          "headers " +
          (redBorderFields.includes("Value Series")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <InfoIcon
          defaultMessage={
            "Value View Fascia means NO Gutter or Downspout Kit.<br/>You will recieve Value View Fascia in place of gutter.<br/>If Yes is selected Fascia Trim Color cannot be Bronze."
          }
        />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Value Fascia
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedValueSeries === "YES" ? "clicked" : ""}`}
          onClick={() => handleValueSeries("YES")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedValueSeries === "NO" ? "clicked" : ""}`}
          onClick={() => handleValueSeries("NO")}
        >
          No
        </div>
      </div>
    </div>
  );
};

ValueSeries.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleValueSeriesOption: PropTypes.func,
  fasciaTrimOption: PropTypes.string,
  setFasciaTrimOption: PropTypes.func,
};

export default ValueSeries;
