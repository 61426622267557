import React, { useState } from "react";
import ImageButton from "../../UniversalComponents/ImageButton";
import PropTypes from "prop-types";

const RoofStyle = ({
  handleRoofStyleOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
  pavstyle,
}) => {
  const [selectedRoofOption, setSelectedRoofOption] = useState("");
  const Studio = `https://temoappstorage.blob.core.windows.net/temoimages/PavilionViewingImages/Studio.jpg`;
  const Cathedral = `https://temoappstorage.blob.core.windows.net/temoimages/PavilionViewingImages/Cathedral.jpg`;
  const images = [Studio, Cathedral];
  const messages = ["Studio", "Cathedral"];

  const handleRoofOptionChange = (selected) => {
    const optionChange = selected;
    let updatedRedBorderFields = redBorderFields.includes("Roof Style")
      ? selected
        ? redBorderFields.filter((field) => field !== "Roof Style")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Roof Style"];

    setSelectedRoofOption(optionChange);
    handleRoofStyleOptionChange(optionChange);
    if (pavstyle !== "TEMO Panel") {
      handleRoofStyleOptionChange("");
      setSelectedRoofOption("");
    }
    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  return (
    <div className="popupwindow roofstyle-choices">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Roof Style")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <ImageButton images={images} messages={messages} />
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Roof Style Choices
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedRoofOption === "STUDIO" ? "clicked" : ""}`}
          onClick={() => handleRoofOptionChange("STUDIO")}
        >
          Studio
        </div>
        <div
          className={`clickable-word ${selectedRoofOption === "CATHEDRAL" ? "clicked" : ""}`}
          onClick={() => handleRoofOptionChange("CATHEDRAL")}
        >
          Cathedral
        </div>
      </div>
    </div>
  );
};

RoofStyle.propTypes = {
  handleRoofStyleOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  pavstyle: PropTypes.string,
};

export default RoofStyle;
