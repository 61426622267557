import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const OrderHistoryPdfTable = ({
  filesList,
  isHistoryPdfsLoading,
  isUserHide,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);
  const [isFetchEmpty, setIsFetchEmpty] = useState(false);

  useEffect(() => {
    if (isHistoryPdfsLoading) {
      setHasAttemptedFetch(true);
    }
    if (
      hasAttemptedFetch &&
      !isHistoryPdfsLoading &&
      (!filesList || filesList.length === 0)
    ) {
      setIsFetchEmpty(true);
    } else {
      setIsFetchEmpty(false);
    }
  }, [isHistoryPdfsLoading, filesList, hasAttemptedFetch]);

  const openFile = async (folderName, fileName, pdfName, e) => {
    e.preventDefault();

    const newWindow = window.open();

    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: "TEMOAuth",
      },
    });

    const fileURL = `/api/TxtThings/historypdf/${pdfName}?folderName=${folderName}`;

    fetch(fileURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        newWindow.location = blobURL;
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  return (
    <div
      className="popupwindow"
      style={{
        width: "100%",
        maxHeight: "70vh",
      }}
    >
      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <thead style={{ width: "100%" }}>
          <tr style={{ width: "100%" }}>
            <th className="theadThStyle" style={{ width: "100%" }}>
              PDF List
            </th>
          </tr>
        </thead>
        <SimpleBar style={{ maxHeight: "55vh", width: "100%" }}>
          <tbody style={{ width: "100%" }}>
            {isHistoryPdfsLoading ? (
              <tr style={{ width: "100%" }}>
                <td style={{ width: "100%" }}>Loading...</td>
              </tr>
            ) : filesList && filesList.length > 0 ? (
              filesList.map((item, index) =>
                item.files && item.files.length > 0 ? (
                  item.files.map((file, fileIndex) => (
                    <tr key={fileIndex} style={{ width: "100%" }}>
                      <td style={{ width: "100%" }}>
                        {item.order && item.order.FileName ? (
                          <button
                            type="button"
                            onClick={(e) =>
                              openFile(
                                item.order.Folder,
                                item.order.FileName,
                                file,
                                e,
                              )
                            }
                            style={{
                              background: "none",
                              border: "none",
                              padding: "0",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            {file}
                          </button>
                        ) : (
                          <span>{file}</span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : isFetchEmpty && !isUserHide ? (
                  <tr key={index} style={{ width: "100%" }}>
                    <td style={{ width: "100%" }}>No files available</td>
                  </tr>
                ) : (
                  <tr key={index} style={{ width: "100%" }}>
                    <td style={{ width: "100%" }}>Click a Show PDFs button!</td>
                  </tr>
                ),
              )
            ) : isFetchEmpty && !isUserHide ? (
              <tr style={{ width: "100%" }}>
                <td style={{ width: "100%" }}>No files available</td>
              </tr>
            ) : (
              <tr style={{ width: "100%" }}>
                <td style={{ width: "100%" }}>Click a Show PDFs button!</td>
              </tr>
            )}
          </tbody>
        </SimpleBar>
      </table>
    </div>
  );
};

OrderHistoryPdfTable.propTypes = {
  filesList: PropTypes.array,
  isHistoryPdfsLoading: PropTypes.bool,
  isUserHide: PropTypes.bool,
};

export default OrderHistoryPdfTable;
