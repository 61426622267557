import React, { useState, useEffect } from "react";
import InfoIcon from "./InfoIcon";
import PropTypes from "prop-types";

const RoofDensity = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleRoofDensity,
  selectedAttachedOption,
  roofstyle,
  lengthFeet,
  lengthInches,
  selectedRoofLoadChange,
  selectedShingle,
  selectedSkyviewOption,
  pavstyle,
  isScreenRoom,
  selectedSunRoomType,
}) => {
  const [optionVisibility, setOptionVisibility] = useState({
    option1: false,
    option2: false,
    option3: false,
  });
  const [anotherSet, setAnotherSet] = useState(false);

  let newpavstyle = pavstyle;

  const handleDropdownChange = (event) => {
    const selected = event.target.value;
    const updatedRedBorderFields = redBorderFields.includes("roof density")
      ? selected
        ? redBorderFields.filter((field) => field !== "roof density")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "roof density"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleRoofDensity(selected);
  };

  const feetInchConversion = (feet, inches) => {
    const totalInches = parseInt(feet) * 12 + parseInt(inches);
    return totalInches;
  };

  const calculateRoofDensity = (
    selectedAttachedOption,
    selectedRoofLoadChange,
    totalInches,
    selectedShingle,
    selectedSkyviewOption,
    pavstyle,
    isScreenRoom,
  ) => {
    var beamdeduction = isScreenRoom ? 1 : 2;
    var bearingdeduction =
      pavstyle !== "ST Thomas" && pavstyle !== "Santa FE"
        ? selectedAttachedOption === "FREE-STANDING"
          ? 24
          : 12
        : 0;
    var spanroof = totalInches - (bearingdeduction + beamdeduction);
    var anothersetofbeamsandcolumns = false;

    var option1 =
      spanroof <=
      {
        40: 9 * 12,
        35: 9 * 12 + 9,
        30: 10 * 12 + 6,
        25: 11 * 12 + 9,
        20: 13 * 12,
        15: 15 * 12 + 6,
        10: 18 * 12,
      }[selectedRoofLoadChange];
    var option2 =
      spanroof <=
      {
        40: 10 * 12 + 6,
        35: 11 * 12 + 2,
        30: 11 * 12 + 10,
        25: 13 * 12 + 3,
        20: 14 * 12 + 8,
        15: 15 * 12 + 10,
        10: 17 * 12,
      }[selectedRoofLoadChange];
    var option3 = true;

    if (selectedShingle === "YES" || selectedSkyviewOption === "Skyview YES") {
      option1 = false;
      option2 =
        spanroof <=
        {
          40: 10 * 12 + 6 - beamdeduction,
          35: 11 * 12 + 2 - beamdeduction,
          30: 11 * 12 + 10 - beamdeduction,
          25: 13 * 12 + 3 - beamdeduction,
          20: 14 * 12 + 8 - beamdeduction,
          15: 15 * 12 + 10 - beamdeduction,
          10: 17 * 12 - beamdeduction,
        }[selectedRoofLoadChange + 5];
    }
    setOptionVisibility({
      option1: option1,
      option2: option2,
      option3: option3,
    });
    if (option3 === false) {
      anothersetofbeamsandcolumns = true;
      setAnotherSet(anothersetofbeamsandcolumns);
    }
  };

  useEffect(() => {
    var totalInches = feetInchConversion(lengthFeet, lengthInches);
    calculateRoofDensity(
      selectedAttachedOption,
      selectedRoofLoadChange,
      totalInches,
      selectedShingle,
      selectedSkyviewOption,
      newpavstyle,
      isScreenRoom,
    );
  }, [
    selectedAttachedOption,
    selectedRoofLoadChange,
    lengthFeet,
    lengthInches,
    selectedShingle,
    anotherSet,
    selectedSkyviewOption,
    newpavstyle,
    isScreenRoom,
  ]);
  return (
    <div
      className="popupwindow roofdensity"
      style={{
        marginRight: "1%",
      }}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <h6
          className={
            "headers " +
            (redBorderFields.includes("roof density")
              ? "redBackground"
              : "greenBackground")
          }
        >
          <InfoIcon defaultMessage="Temo roof panels are 0.032 aluminum thickness.<br/>Sandstone Roof Panels can only be selected if aluminum thickness is 0.024." />
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            TEMO Roof Choice
          </span>
        </h6>
        {roofstyle !== "CATHEDRAL" && selectedSunRoomType !== "Solarium" && (
          <span className="fontsizeforinputs">
            <select
              id="roofdensity"
              className="input-box "
              style={{ width: "95%" }}
              onChange={handleDropdownChange}
            >
              <option value="">Select a TEMO Roof Choice</option>
              {optionVisibility.option1 && (
                <option value='4 1/4" 0.024 1 pound'>
                  4 1/4&quot; 0.024 1 pound
                </option>
              )}
              {optionVisibility.option2 && (
                <option value='4 1/4" 0.032 1 pound'>
                  4 1/4&quot; 0.032 1 pound
                </option>
              )}
              {optionVisibility.option3 && (
                <option value='4 1/4" 0.032 2 pound'>
                  4 1/4&quot; 0.032 2 pound
                </option>
              )}
            </select>
          </span>
        )}
        {roofstyle === "CATHEDRAL" && selectedSunRoomType !== "Solarium" && (
          <span className="fontsizeforinputs">
            <select
              id="roofdensity"
              className="input-box "
              style={{ width: "95%" }}
              onChange={handleDropdownChange}
            >
              <option value="">Select a roof density</option>
              <option value='4 1/4" 0.024 1 pound'>
                4 1/4&quot; 0.024 1 pound
              </option>
              <option value='4 1/4" 0.032 1 pound'>
                4 1/4&quot; 0.032 1 pound
              </option>
              <option value='4 1/4" 0.032 2 pound'>
                4 1/4&quot; 0.032 2 pound
              </option>
            </select>
          </span>
        )}
      </form>
    </div>
  );
};

RoofDensity.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleRoofDensity: PropTypes.func,
  selectedAttachedOption: PropTypes.string,
  roofstyle: PropTypes.string,
  lengthFeet: PropTypes.string,
  lengthInches: PropTypes.string,
  selectedRoofLoadChange: PropTypes.string,
  selectedShingle: PropTypes.string,
  selectedSkyviewOption: PropTypes.string,
  pavstyle: PropTypes.string,
  isScreenRoom: PropTypes.bool,
  selectedSunRoomType: PropTypes.string,
};

export default RoofDensity;
