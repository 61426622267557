import React from "react";
import PropTypes from "prop-types";

const RailSideSelector = ({
  redBorderFields,
  selectedSide,
  handleRailSideChange,
}) => {
  return (
    <div className="popupwindow hangingrailside">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("rail side")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">✱</span> Which Side?
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedSide === "LEFT" ? "clicked" : ""}`}
          onClick={() => handleRailSideChange("LEFT")}
        >
          Left
        </div>
        <div
          className={`clickable-word ${selectedSide === "RIGHT" ? "clicked" : ""}`}
          onClick={() => handleRailSideChange("RIGHT")}
        >
          Right
        </div>
      </div>
    </div>
  );
};

RailSideSelector.propTypes = {
  redBorderFields: PropTypes.array,
  selectedSide: PropTypes.string,
  handleRailSideChange: PropTypes.func,
};

export default RailSideSelector;
