import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import FrontWallHeight from "./WallHeights/FrontWallHeight";
import HeightAtHouse from "./WallHeights/HeightAtHouseWallHeight";

const WallHeight = ({
  redBorderFields,
  roofstyle,
  onHeightChange,
  setFieldsWithRedBorder,
  selectedAttachedOption,
  selectedSunRoomType,
  selectedTopTransomOption,
  heightAtHouse,
  setHeightAtHouse,
  frontHeight,
  setFrontHeight,
  newKey,
  resetWallHeight,
  setShouldShowAlert,
  shouldShowAlert,
  setResetWallHeight,
  tempFrontHeight,
  tempHeightAtHouse,
  setTempFrontHeight,
  setTempHeightAtHouse,
  isScreenRoom,
  isSunRoom,
}) => {
  const [totalHeightAtHouseInches, setTotalHeightAtHouseInches] = useState("");
  const [totalFrontHeightInches, setTotalFrontHeightInches] = useState("");
  const alertShownRef = useRef(false);

  const handleTotalHeightAtHouseInches = useCallback((heightAtHouse) => {
    setTotalHeightAtHouseInches(heightAtHouse);
  }, []);

  const handleTotalFrontHeightInches = useCallback((frontHeight) => {
    setTotalFrontHeightInches(frontHeight);
  }, []);

  const showAlerts = useCallback(() => {
    if (roofstyle === "CATHEDRAL") {
      alert("Peak Height must be greater than Side Height");
    } else if (selectedAttachedOption === "FREE-STANDING") {
      alert("Tallest Height must be greater than Shortest Height");
    } else {
      alert("Height at House must be greater than Height at Front");
    }
  }, [roofstyle, selectedAttachedOption]);

  useEffect(() => {
    let heightinches;
    let frontinches;

    let newheightinches = heightAtHouse.heightinches;
    let newfrontinches = frontHeight.frontinches;

    !newheightinches
      ? (heightinches = "")
      : (heightinches = parseInt(heightAtHouse.heightinches, 10));

    !newfrontinches
      ? (frontinches = "")
      : (frontinches = parseInt(frontHeight.frontinches, 10));

    let newheight = "";
    let newfront = "";

    if (heightAtHouse.housefeet !== "") {
      newheight = parseInt(heightAtHouse.heightfeet, 10) * 12 + heightinches;
    }
    handleTotalHeightAtHouseInches(newheight);

    if (frontHeight.frontfeet !== "") {
      newfront = parseInt(frontHeight.frontfeet, 10) * 12 + frontinches;
    }
    handleTotalFrontHeightInches(newfront);

    if (roofstyle !== "Existing Roof") {
      if (
        typeof totalHeightAtHouseInches === "number" &&
        typeof totalFrontHeightInches === "number" &&
        totalFrontHeightInches >= totalHeightAtHouseInches
      ) {
        if (!alertShownRef.current) {
          setShouldShowAlert(true);
          setResetWallHeight(true);
          alertShownRef.current = true;
        }
      } else {
        alertShownRef.current = false;
      }
    }
  }, [
    heightAtHouse,
    frontHeight,
    roofstyle,
    selectedAttachedOption,
    setShouldShowAlert,
    totalHeightAtHouseInches,
    totalFrontHeightInches,
    setResetWallHeight,
    handleTotalHeightAtHouseInches,
    handleTotalFrontHeightInches,
  ]);

  useEffect(() => {
    if (shouldShowAlert) {
      showAlerts();
      setShouldShowAlert(false);
    }
  }, [roofstyle, setShouldShowAlert, showAlerts, shouldShowAlert]);

  return (
    <div className="density-fanbeam skyview">
      <HeightAtHouse
        redBorderFields={redBorderFields}
        roofstyle={roofstyle}
        setFieldsWithRedBorder={setFieldsWithRedBorder}
        selectedAttachedOption={selectedAttachedOption}
        selectedSunRoomType={selectedSunRoomType}
        selectedTopTransomOption={selectedTopTransomOption}
        heightAtHouse={heightAtHouse}
        setHeightAtHouse={setHeightAtHouse}
        newKey={newKey}
        resetWallHeight={resetWallHeight}
        setResetWallHeight={setResetWallHeight}
        onHeightChange={onHeightChange}
        frontHeight={frontHeight}
        tempHeightAtHouse={tempHeightAtHouse}
        setTempHeightAtHouse={setTempHeightAtHouse}
        isScreenRoom={isScreenRoom}
        isSunRoom={isSunRoom}
      />
      <FrontWallHeight
        redBorderFields={redBorderFields}
        roofstyle={roofstyle}
        setFieldsWithRedBorder={setFieldsWithRedBorder}
        selectedAttachedOption={selectedAttachedOption}
        selectedSunRoomType={selectedSunRoomType}
        selectedTopTransomOption={selectedTopTransomOption}
        frontHeight={frontHeight}
        setFrontHeight={setFrontHeight}
        newKey={newKey}
        resetWallHeight={resetWallHeight}
        setResetWallHeight={setResetWallHeight}
        onHeightChange={onHeightChange}
        heightAtHouse={heightAtHouse}
        tempFrontHeight={tempFrontHeight}
        setTempFrontHeight={setTempFrontHeight}
        isScreenRoom={isScreenRoom}
        isSunRoom={isSunRoom}
      />
    </div>
  );
};
WallHeight.propTypes = {
  selectedAttachedOption: PropTypes.string,
  redBorderFields: PropTypes.array,
  roofstyle: PropTypes.string,
  onHeightChange: PropTypes.func,
  setFieldsWithRedBorder: PropTypes.func,
  selectedSunRoomType: PropTypes.string,
  selectedTopTransomOption: PropTypes.string,
  heightAtHouse: PropTypes.object,
  setHeightAtHouse: PropTypes.func,
  frontHeight: PropTypes.object,
  setFrontHeight: PropTypes.func,
  newKey: PropTypes.string,
  resetWallHeight: PropTypes.bool,
  setResetWallHeight: PropTypes.func,
  shouldShowAlert: PropTypes.bool,
  setShouldShowAlert: PropTypes.func,
  tempFrontHeight: PropTypes.object,
  tempHeightAtHouse: PropTypes.object,
  setTempFrontHeight: PropTypes.func,
  setTempHeightAtHouse: PropTypes.func,
  isScreenRoom: PropTypes.bool,
  isSunRoom: PropTypes.bool,
};

export default WallHeight;
