import React, { useState } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const SashOption = ({
  handleSashOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [sashSelectedOption, setSashSelectedOption] = useState("");

  const handleSashValueChange = (pickedoption) => {
    setSashSelectedOption(pickedoption);
    const updatedRedBorderFields = redBorderFields.includes("Sash Option")
      ? pickedoption
        ? redBorderFields.filter((field) => field !== "Sash Option")
        : redBorderFields
      : pickedoption
        ? redBorderFields
        : [...redBorderFields, "Sash Option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSashOptionChange(pickedoption);
  };

  return (
    <div className="popupwindow bihex-stuff" style={{ marginTop: "1%" }}>
      <h6
        className={
          "headers " +
          (redBorderFields.includes("Sash Option")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Sash
        </span>
        <InfoIcon defaultMessage="No - full sunroom without sliding sashes.<br/>Window sections will all have screen." />
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${sashSelectedOption === "Yes" ? "clicked" : ""}`}
          onClick={() => handleSashValueChange("Yes")}
        >
          Yes
        </div>
        &nbsp;
        <div
          className={`clickable-word ${sashSelectedOption === "No" ? "clicked" : ""}`}
          onClick={() => handleSashValueChange("No")}
        >
          No
        </div>
      </div>
    </div>
  );
};

SashOption.propTypes = {
  handleSashOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default SashOption;
