import React, { useEffect } from "react";
import TrimColorSelection from "./PavilionStyleComponents/TrimColor";
import FreesStandingorAttached from "./PavilionStyleComponents/FreeStandingOrAttached";
import ExteriorStyleSelection from "./PavilionStyleComponents/ExteriorStyle";
import PostStyleSelection from "../UniversalComponents/PostStyleSelection";
import RoofStyle from "./PavilionStyleComponents/RoofStyle";
import PavilionStyle from "./PavilionStyleComponents/PavilionStyle";
import PropTypes from "prop-types";

const StyleChoices = ({
  permitSelected,
  onAttachedOptionChange,
  handleTrimChange,
  handlePostStyleOptionChange,
  handleExteriorOptionChange,
  handlePostHeightOption,
  redBorderFields,
  setFieldsWithRedBorder,
  handlePavilionStyleOptionChange,
  pavstyle,
  handleAttachedOption,
  handleShingleChange,
  handleRoofStyleOptionChange,
  selectedSkyviewChoice,
  handleStructuralBeamOption,
  selectedStructuralBeamOption,
  selectedExteriorOption,
}) => {
  useEffect(() => {
    if (pavstyle !== "TEMO Panel") {
      handleRoofStyleOptionChange("");
      handlePostStyleOptionChange("");
      handleExteriorOptionChange("");
    }
    if (selectedSkyviewChoice === "Skyview YES") {
      handleShingleChange("");
      handleRoofStyleOptionChange("");
    }
  }, [
    pavstyle,
    handleRoofStyleOptionChange,
    handlePostStyleOptionChange,
    selectedSkyviewChoice,
    handleShingleChange,
    selectedStructuralBeamOption,
    handleExteriorOptionChange,
  ]);
  return (
    <div>
      <div>
        <div>
          <PavilionStyle
            handlePavilionStyleOptionChange={handlePavilionStyleOptionChange}
            redBorderFields={redBorderFields}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
          />
        </div>
        <div>
          <FreesStandingorAttached
            handleAttachedOption={handleAttachedOption}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            redBorderFields={redBorderFields}
            permitSelected={permitSelected}
            onAttachedOptionChange={onAttachedOptionChange}
          />
        </div>
        {pavstyle === "TEMO Panel" && (
          <>
            <div>
              <PostStyleSelection
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                redBorderFields={redBorderFields}
                handlePostStyleOptionChange={handlePostStyleOptionChange}
                handlePostHeightOption={handlePostHeightOption}
                pavstyle={pavstyle}
                handleStructuralBeamOption={handleStructuralBeamOption}
                handleExteriorOptionChange={handleExteriorOptionChange}
                selectedExteriorOption={selectedExteriorOption}
              />
            </div>
            <div>
              <ExteriorStyleSelection
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                redBorderFields={redBorderFields}
                handleExteriorStyleOptionChange={handleExteriorOptionChange}
                pavstyle={pavstyle}
                selectedBeamOption={selectedStructuralBeamOption}
              />
            </div>
          </>
        )}
        <div>
          <TrimColorSelection
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            redBorderFields={redBorderFields}
            handleTrimChange={handleTrimChange}
          />
        </div>
        {selectedSkyviewChoice !== "Skyview YES" &&
          pavstyle === "TEMO Panel" && (
            <div>
              <RoofStyle
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                redBorderFields={redBorderFields}
                pavstyle={pavstyle}
                handleRoofStyleOptionChange={handleRoofStyleOptionChange}
              />
            </div>
          )}
      </div>
    </div>
  );
};

StyleChoices.propTypes = {
  permitSelected: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onAttachedOptionChange: PropTypes.func,
  handleTrimChange: PropTypes.func,
  handlePostStyleOptionChange: PropTypes.func,
  handleExteriorOptionChange: PropTypes.func,
  handlePostHeightOption: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handlePavilionStyleOptionChange: PropTypes.func,
  pavstyle: PropTypes.string,
  handleAttachedOption: PropTypes.func,
  handleShingleChange: PropTypes.func,
  handleRoofStyleOptionChange: PropTypes.func,
  selectedSkyviewChoice: PropTypes.string,
  handleStructuralBeamOption: PropTypes.func,
  selectedStructuralBeamOption: PropTypes.string,
  selectedExteriorOption: PropTypes.string,
};

export default StyleChoices;
