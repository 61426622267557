import React, { useState } from "react";
import PropTypes from "prop-types";

const MotorCityDimensions = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleMotorCityPergolaWidth,
  handleMotorCityPergolaLength,
}) => {
  const [widthValue, setWidthValue] = useState("");
  const [lengthValue, setLengthValue] = useState("");

  const handleWidthChange = (event) => {
    let input = event.target.value;
    setWidthValue(input);
    handleMotorCityPergolaWidth(input);
    const updatedRedBorderFields = redBorderFields.includes("width")
      ? input
        ? redBorderFields.filter((field) => field !== "width")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "width"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleLengthChange = (event) => {
    let input = event.target.value;
    setLengthValue(input);
    handleMotorCityPergolaLength(input);
    const updatedRedBorderFields = redBorderFields.includes("length")
      ? input
        ? redBorderFields.filter((field) => field !== "length")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "length"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  return (
    <div className="load-selection">
      <div className="popupwindow lengthdim">
        <h6 className="greenBackground headers">
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Roof Length
          </span>
        </h6>
        <div className="fontsizeforinputsrow" id="rooflength">
          <span className="fontsizeforinputsrow">
            <select
              id="length"
              className={
                redBorderFields.includes("length")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={lengthValue}
              onChange={handleLengthChange}
              style={{ width: "50%" }}
            >
              <option value="">Select length</option>
              <option value="10">10</option>
              <option value="12">12</option>
              <option value="14">14</option>
            </select>
          </span>
        </div>
      </div>
      <div className="popupwindow widthdim">
        <h6 className="greenBackground headers">
          <span className="header-grid-location">
            <span className="redasterisk">&#10033;</span>
            Roof Width
          </span>
        </h6>
        <div id="roofwidth" className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <select
              id="width"
              className={
                redBorderFields.includes("width")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={widthValue}
              onChange={handleWidthChange}
              style={{ width: "50%" }}
            >
              <option value="">Select width</option>
              <option value="10">10</option>
              <option value="12">12</option>
              <option value="14">14</option>
              <option value="16">16</option>
              <option value="18">18</option>
              <option value="20">20</option>
              <option value="22">22</option>
              <option value="24">24</option>
            </select>
          </span>
        </div>
      </div>
    </div>
  );
};

MotorCityDimensions.propTypes = {
  handleDimensionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  pavstyle: PropTypes.string,
  handleMotorCityPergolaWidth: PropTypes.func,
  handleMotorCityPergolaLength: PropTypes.func,
};

export default MotorCityDimensions;
