import React from "react";

const OneWall = () => (
  <div className="centered-text">
    My structure backs up to one wall.
    <br />
    I need no extra hanging rail.
    <br />
  </div>
);

export default OneWall;
