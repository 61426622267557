import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

function PatioDoorOrientation({
  setSelectedPatioDoorOrientation,
  index,
  setShowPatioDoorOrientation,
  selectedPatioDoorOrientation,
  redFieldMessage,
  currentImage,
  handleSelectedPatioDoorOrientation,
}) {
  const handleCloseModal = () => {
    setShowPatioDoorOrientation(false);
  };

  return ReactDOM.createPortal(
    <div
      style={{
        zIndex: 2005,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="modal">
        <div
          className="modal-content"
          style={{ width: "80%", height: "80%", overflowY: "auto" }}
        >
          {selectedPatioDoorOrientation[index] === null && (
            <button
              className="navbutton"
              style={{
                position: "absolute",
                top: "1%",
                right: "1%",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={handleCloseModal}
            >
              X
            </button>
          )}
          {selectedPatioDoorOrientation[index] !== null && (
            <button
              className="navbutton"
              style={{
                position: "absolute",
                top: "1%",
                right: "1%",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={handleCloseModal}
            >
              Done
            </button>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <h3 style={{ fontSize: "2.5vh", color: "brown" }}>
              You are looking at the door from the outside.
            </h3>
            <img
              src={currentImage}
              alt="Patio Door"
              style={{ width: "auto", height: "80%", margin: "2%" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "50%",
              }}
            >
              <button
                className="navbutton"
                onClick={() =>
                  handleSelectedPatioDoorOrientation(
                    "Left-Operable",
                    index,
                    redFieldMessage,
                    setSelectedPatioDoorOrientation,
                  )
                }
              >
                Left
              </button>
              <button
                className="navbutton"
                onClick={() =>
                  handleSelectedPatioDoorOrientation(
                    "Right-Operable",
                    index,
                    redFieldMessage,
                    setSelectedPatioDoorOrientation,
                  )
                }
              >
                Right
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root"),
  );
}

PatioDoorOrientation.propTypes = {
  setSelectedPatioDoorOrientation: PropTypes.func,
  index: PropTypes.number,
  setShowPatioDoorOrientation: PropTypes.func,
  selectedPatioDoorOrientation: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  redFieldMessage: PropTypes.string,
  handleSelectedPatioDoorOrientation: PropTypes.func,
  setSelectedSwingDoorOrientation: PropTypes.func,
  currentImage: PropTypes.string,
};

export default PatioDoorOrientation;
