import React, { forwardRef, useState } from "react";
import OrderHistoryRow from "./OrderHistoryRow";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const OrdersHistoryTable = forwardRef(
  (
    {
      orders,
      handleShowOrderHistoryPdfTable,
      isLoading,
      setInputFields,
      inputFields,
      currentOrder,
    },
    ref,
  ) => {
    const inputStyle = {
      width: "100%",
      boxSizing: "border-box",
      position: "absolute",
      bottom: 0,
      left: 0,
      color: "black",
    };

    const [clickedButtons, setClickedButtons] = useState({});

    const handleButtonClick = (index) => {
      setClickedButtons((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    };

    return (
      <div
        style={{
          height: "50vh",
          width: "70vw",
        }}
      >
        <table
          id="dtDynamicVerticalScrollExample"
          style={{
            width: "80vw",
            tableLayout: "fixed",
          }}
        >
          <thead className="table-header-div theadThStyle">
            <tr style={{ width: "100%" }}>
              <th
                className="theadThStyle"
                style={{
                  position: "sticky",
                  color: "black",
                }}
              >
                <span style={{ color: "white" }}>Folder Name</span>
                <br />
                <br />
                <input
                  id="sfoldername"
                  style={inputStyle}
                  placeholder="Search Folder Name"
                  value={inputFields.folder}
                  onChange={(e) =>
                    setInputFields((prevState) => ({
                      ...prevState,
                      folder: e.target.value,
                    }))
                  }
                />
              </th>

              <th
                className="theadThStyle"
                style={{
                  position: "sticky",
                  color: "black",
                }}
              >
                <span style={{ color: "white" }}>File Name</span>
                <br></br>
                <br></br>
                <input
                  id="sfilename"
                  style={inputStyle}
                  type="text"
                  placeholder="Search File Name"
                  value={inputFields.fileName}
                  onChange={(e) =>
                    setInputFields((prevState) => ({
                      ...prevState,
                      fileName: e.target.value,
                    }))
                  }
                />
              </th>
              <th
                className="theadThStyle"
                style={{
                  position: "sticky",
                  color: "black",
                }}
              >
                <span style={{ color: "white" }}>Job Name</span>
                <br></br>
                <br></br>
                <input
                  id="sjobname"
                  style={inputStyle}
                  type="text"
                  placeholder="Search Job Name"
                  value={inputFields.jobName}
                  onChange={(e) =>
                    setInputFields((prevState) => ({
                      ...prevState,
                      jobName: e.target.value,
                    }))
                  }
                />
              </th>
              <th
                className="theadThStyle"
                style={{
                  position: "sticky",
                  color: "black",
                }}
              >
                <span style={{ color: "white" }}>Date Shipped</span>
                <br></br>
                <br></br>
                <input
                  id="sdateship"
                  style={inputStyle}
                  type="text"
                  placeholder="Search Date Shipped"
                  value={inputFields.dateShipped}
                  onChange={(e) =>
                    setInputFields((prevState) => ({
                      ...prevState,
                      dateShipped: e.target.value,
                    }))
                  }
                />
              </th>
              <th
                className="theadThStyle"
                style={{
                  position: "sticky",
                  color: "black",
                }}
              >
                <span style={{ color: "white" }}>Street Address</span>
                <br></br>
                <br></br>
                <input
                  id="sstreetadd"
                  style={inputStyle}
                  type="text"
                  placeholder="Search Street Address"
                  value={inputFields.streetAddress}
                  onChange={(e) =>
                    setInputFields((prevState) => ({
                      ...prevState,
                      streetAddress: e.target.value,
                    }))
                  }
                />
              </th>
              <th
                className="theadThStyle"
                style={{
                  position: "sticky",
                  color: "black",
                }}
              >
                <span style={{ color: "white" }}>City/State/Zip</span>
                <br></br>
                <br></br>
                <input
                  id="scitstzip"
                  style={inputStyle}
                  type="text"
                  placeholder="Search City/State/Zip"
                  value={inputFields.cityStateZip}
                  onChange={(e) =>
                    setInputFields((prevState) => ({
                      ...prevState,
                      cityStateZip: e.target.value,
                    }))
                  }
                />
              </th>
              <th
                className="theadThStyle"
                style={{
                  position: "sticky",
                  borderRight: "1px solid transparent",
                  borderBottom: "1px solid black",
                }}
              >
                {" "}
                PDF Files{" "}
              </th>
            </tr>
          </thead>
          <SimpleBar style={{ maxHeight: "50vh", width: "100%" }}>
            <tbody
              style={{
                display: "block",
                width: "100%",
              }}
            >
              {orders.length === 0 && !isLoading && (
                <tr style={{ width: "100%", border: "none" }}>
                  <td style={{ border: "none", width: "100%" }} colSpan={7}>
                    No data available.
                  </td>
                </tr>
              )}
              {isLoading && (
                <tr style={{ width: "100%" }}>
                  <td style={{ border: "none", width: "100%" }} colSpan={7}>
                    Loading...
                  </td>
                </tr>
              )}

              {orders.map((order, index) => (
                <OrderHistoryRow
                  order={order}
                  key={index}
                  handleShowOrderHistoryPdfTable={
                    handleShowOrderHistoryPdfTable
                  }
                  handleButtonClick={() => handleButtonClick(index)}
                  isButtonClicked={clickedButtons[index]}
                  currentOrder={currentOrder}
                />
              ))}
            </tbody>
          </SimpleBar>
        </table>
      </div>
    );
  },
);

OrdersHistoryTable.displayName = "OrdersHistoryTable";

OrdersHistoryTable.propTypes = {
  orders: PropTypes.array,
  handleShowOrderHistoryPdfTable: PropTypes.func,
  setInputFields: PropTypes.func,
  inputFields: PropTypes.object,
  isLoading: PropTypes.bool,
  currentOrder: PropTypes.object,
};

export default OrdersHistoryTable;
