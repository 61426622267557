import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import SingleWallSelection from "../SingleWallSelection";

const LeftSide = ({
  setLeftSideDivs,
  setLeftSidePickMeDivs,
  redBorderFields,
  dimension,
  setWallERLLengthsFeet,
  setWallERLLengthsInches,
  wallERLLengthsFeet,
  wallERLLengthsInches,
  handleWallLengthFeetChange,
  handleWallLengthInchesChange,
  setLeftCavities,
  setDoorERLOption,
  doorERLOption,
  handleDoorOptionChange,
  doorERLWidth,
  setDoorERLWidth,
  lastWallLengthUpdate,
  setLastWallLengthUpdate,
  handleDoorWidthChange,
  selectedSunRoomType,
  setFieldsWithRedBorder,
  selectedERLPatioDoorOrientation,
  selectedERLSwingDoorOrientation,
  setSelectedERLSwingDoorOrientation,
  setSelectedERLPatioDoorOrientation,
  setSelectedERLCodes,
  selectedERLCodes,
  selectedERLButtonIndex,
  setSelectedERLButtonIndex,
  selectedERLImages,
  setSelectedERLImages,
  handleCodeSelect,
  blackLeftSquares,
  setBlackLeftSquares,
  setLeftPickMes,
  pickMeLeftDivs,
  setVerticalLeftLines,
  verticalLeftLines,
  index,
  setIndex,
  setOpenLeftSingleDrawing,
  openLeftSingleDrawing,
  isScreenRoom,
  setSelectedERLLocations,
  selectedERLLocations,
  handleDoorLocationChange,
  selectedBreezeWay,
  selectedPavilionLayoutOption,
  handleSelectedPatioDoorOrientation,
  currentImage,
  handleSwingDoorOrientation,
}) => {
  const BlackSquare = `https://temoappstorage.blob.core.windows.net/temoimages/LincolnLogPhotos/Black_square.jpg`;
  const VerticalLine = `https://temoappstorage.blob.core.windows.net/temoimages/LincolnLogPhotos/Vertical-Line.jpg`;
  const ClickMeLeft = `https://temoappstorage.blob.core.windows.net/temoimages/LincolnLogPhotos/CLICKRIGHT.png`;

  const openLeftSidesModal = useCallback(
    (indexValue) => {
      setOpenLeftSingleDrawing(true);
      setIndex(indexValue);
    },
    [setIndex, setOpenLeftSingleDrawing],
  );

  const generateLeftPickMeDivs = useCallback(
    (total) => {
      let newTotal = Number(total);
      return Array.from({ length: newTotal }, (_, index) => {
        let clickMeLeftLength =
          selectedSunRoomType !== "Linear Screen"
            ? (wallERLLengthsFeet[index] || wallERLLengthsFeet[index] === 0) &&
              (wallERLLengthsInches[index] || wallERLLengthsInches[index] === 0)
              ? `${wallERLLengthsFeet[index]}' ${wallERLLengthsInches[index]}"`
              : ""
            : doorERLOption[index] === "No"
              ? "No Door"
              : doorERLOption[index] !== ""
                ? "Yes Door"
                : "";
        let clickMeLeftImage = ClickMeLeft;
        let clickMeLeftDoorOption =
          selectedSunRoomType !== "Linear Screen"
            ? doorERLOption[index] === "No"
              ? "No Door"
              : doorERLWidth[index]
            : doorERLOption[index] === "No"
              ? ""
              : selectedERLLocations[index];
        let clickMeLeftOrientation =
          doorERLOption[index] === "No"
            ? ""
            : selectedERLPatioDoorOrientation[index] === ""
              ? selectedERLSwingDoorOrientation[index]
              : selectedERLPatioDoorOrientation[index];

        let clickMeLeftCode =
          selectedSunRoomType !== "Linear Screen"
            ? selectedERLCodes[index]
            : (wallERLLengthsFeet[index] || wallERLLengthsFeet[index] === 0) &&
                (wallERLLengthsInches[index] ||
                  wallERLLengthsInches[index] === 0)
              ? `${wallERLLengthsFeet[index]}' ${wallERLLengthsInches[index]}"`
              : "";
        let values = [
          clickMeLeftCode,
          clickMeLeftLength,
          clickMeLeftDoorOption,
          clickMeLeftOrientation,
        ];
        let gridRow;
        switch (newTotal) {
          case 1:
            gridRow = "12 / span 6";
            break;
          case 2:
            gridRow = index === 0 ? "6 / span 4" : "18 / span 6";
            break;
          default:
            gridRow =
              index === 0
                ? "2 / span 6"
                : index === 1
                  ? "11 / span 6"
                  : "20 / span 6";
            break;
        }
        const uniqueKey = `left-pick-me-${index}-${JSON.stringify(values)}`;
        return {
          key: uniqueKey,
          gridColumn: "1 / span 4",
          gridRow: `${gridRow}`,
          width: "20%",
          height: "100%",
          clickMeLeftImage,
          values,
        };
      });
    },
    [
      selectedSunRoomType,
      wallERLLengthsFeet,
      wallERLLengthsInches,
      doorERLOption,
      ClickMeLeft,
      doorERLWidth,
      selectedERLLocations,
      selectedERLPatioDoorOrientation,
      selectedERLSwingDoorOrientation,
      selectedERLCodes,
    ],
  );

  const generateLeftBlackSquares = useCallback(
    (total) => {
      let newTotal = Number(total);
      return Array.from({ length: newTotal }, (_, index) => {
        let gridRow;
        switch (newTotal) {
          case 1:
            gridRow = "27 / span 2";
            break;
          case 2:
            gridRow = index === 0 ? "13 / span 2" : "27 / span 2";
            break;
          default:
            gridRow =
              index === 0
                ? "9 / span 2"
                : index === 1
                  ? "18 / span 2"
                  : "27 / span 2";
            break;
        }
        return {
          gridColumn: "4 / span 2",
          gridRow: `${gridRow}`,
          backgroundImage: BlackSquare,
          cursorPointer: false,
          width: "50%",
          height: "100%",
        };
      });
    },
    [BlackSquare],
  );

  const handleLeftCavities = useCallback(
    (value) => {
      setLeftCavities(value);
      const generateLeftVerticalLines = (total) => {
        let newTotal = Number(total);
        return Array.from({ length: newTotal }, (_, index) => {
          let gridRow;
          let height = "70%";
          switch (newTotal) {
            case 1:
              gridRow = "3 / span 23";
              height = "100%";
              break;
            case 2:
              gridRow = index === 0 ? "3 / span 9" : "16 / span 10";
              height = "100%";
              break;
            default:
              gridRow =
                index === 0
                  ? "3 / span 5"
                  : index === 1
                    ? "12 / span 5"
                    : "21 / span 5";
              height = "100%";
              break;
          }
          return {
            gridColumn: "4 / span 2",
            gridRow: gridRow,
            backgroundImage: VerticalLine,
            onClick: () => openLeftSidesModal(index),
            cursorPointer: true,
            width: "20%",
            height: height,
            id: "left",
          };
        });
      };
      const numericValue = parseInt(value, 10);
      if (numericValue >= 0 && numericValue <= 4) {
        setVerticalLeftLines(generateLeftVerticalLines(numericValue));
        setBlackLeftSquares(generateLeftBlackSquares(numericValue));
        setLeftPickMes(generateLeftPickMeDivs(numericValue));
      } else {
        setVerticalLeftLines([]);
        setBlackLeftSquares([]);
        setLeftPickMes([]);
      }
    },
    [
      VerticalLine,
      generateLeftBlackSquares,
      generateLeftPickMeDivs,
      openLeftSidesModal,
      setBlackLeftSquares,
      setLeftCavities,
      setLeftPickMes,
      setVerticalLeftLines,
    ],
  );

  useEffect(() => {
    if (Array.isArray(verticalLeftLines) && verticalLeftLines.length === 0) {
      handleLeftCavities(1);
    }
  }, [verticalLeftLines, handleLeftCavities]);

  useEffect(() => {
    setLeftSideDivs([...verticalLeftLines, ...blackLeftSquares]);
    setLeftSidePickMeDivs([...pickMeLeftDivs]);
  }, [
    verticalLeftLines,
    blackLeftSquares,
    setLeftSideDivs,
    setLeftSidePickMeDivs,
    pickMeLeftDivs,
  ]);

  useEffect(() => {
    const newPickMeLeftDivs = generateLeftPickMeDivs(verticalLeftLines.length);
    setLeftPickMes(newPickMeLeftDivs);
  }, [
    doorERLOption,
    doorERLWidth,
    selectedERLPatioDoorOrientation,
    selectedERLSwingDoorOrientation,
    selectedERLCodes,
    generateLeftPickMeDivs,
    verticalLeftLines.length,
    setLeftPickMes,
  ]);

  return (
    <>
      <select onChange={(e) => handleLeftCavities(e.target.value)}>
        <option value={1}>1 Cavity</option>
        <option value={2}>2 Cavities</option>
        <option value={3}>3 Cavities</option>
      </select>
      {openLeftSingleDrawing && (
        <SingleWallSelection
          setOpenSingleDrawing={setOpenLeftSingleDrawing}
          dimension={dimension}
          wallLengthsFeet={wallERLLengthsFeet}
          wallLengthsInches={wallERLLengthsInches}
          redBorderFields={redBorderFields}
          index={Number(index)}
          setWallLengthsFeet={setWallERLLengthsFeet}
          setWallLengthsInches={setWallERLLengthsInches}
          handleWallLengthFeetChange={handleWallLengthFeetChange}
          handleWallLengthInchesChange={handleWallLengthInchesChange}
          wallLengthMessage={`Left Wall Length Feet ${index + 1}`}
          wallLengthInchesMessage={`Left Wall Length Inches ${index + 1}`}
          handleDoorOptionChange={handleDoorOptionChange}
          doorOption={doorERLOption}
          setDoorOption={setDoorERLOption}
          doorWidth={doorERLWidth}
          setDoorWidth={setDoorERLWidth}
          doorOptionMessage={`Left Door Option ${index + 1}`}
          lastWallLengthUpdate={lastWallLengthUpdate}
          setLastWallLengthUpdate={setLastWallLengthUpdate}
          handleDoorWidthChange={handleDoorWidthChange}
          setNewDoorWidth={setDoorERLWidth}
          redFieldMessage={`Left Door Width ${index + 1}`}
          selectedSunRoomType={selectedSunRoomType}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
          selectedPatioDoorOrientation={selectedERLPatioDoorOrientation}
          selectedSwingDoorOrientation={selectedERLSwingDoorOrientation}
          setSelectedSwingDoorOrientation={setSelectedERLSwingDoorOrientation}
          setSelectedPatioDoorOrientation={setSelectedERLPatioDoorOrientation}
          initialSelectedCodes={selectedERLCodes}
          setNewSelectedCodes={setSelectedERLCodes}
          selectedButtonIndex={selectedERLButtonIndex}
          setSelectedButtonIndex={setSelectedERLButtonIndex}
          selectedImages={selectedERLImages}
          setSelectedImages={setSelectedERLImages}
          newIndex={0}
          handleCodeSelect={handleCodeSelect}
          redPatioFieldMessage={`Left Patio Door Orientation ${index + 1}`}
          redSwingFieldMessage={`Left Swing Door Orientation ${index + 1}`}
          redWallFieldMessage={`Left Wall Code ${index + 1}`}
          isScreenRoom={isScreenRoom}
          setSelectedLocations={setSelectedERLLocations}
          selectedLocations={selectedERLLocations}
          handleDoorLocationChange={handleDoorLocationChange}
          selectedBreezeWay={selectedBreezeWay}
          selectedPavilionLayoutOption={selectedPavilionLayoutOption}
          handleSelectedPatioDoorOrientation={
            handleSelectedPatioDoorOrientation
          }
          currentImage={currentImage}
          handleSwingDoorOrientation={handleSwingDoorOrientation}
        />
      )}
    </>
  );
};

LeftSide.propTypes = {
  setLeftSideDivs: PropTypes.func,
  redBorderFields: PropTypes.array,
  dimension: PropTypes.number,
  setWallERLLengthsFeet: PropTypes.func,
  setWallERLLengthsInches: PropTypes.func,
  wallERLLengthsFeet: PropTypes.array,
  wallERLLengthsInches: PropTypes.array,
  handleWallLengthFeetChange: PropTypes.func,
  setLeftCavities: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  setDoorERLOption: PropTypes.func,
  doorERLOption: PropTypes.array,
  handleDoorOptionChange: PropTypes.func,
  doorERLWidth: PropTypes.array,
  setDoorERLWidth: PropTypes.func,
  lastWallLengthUpdate: PropTypes.number,
  setLastWallLengthUpdate: PropTypes.func,
  handleDoorWidthChange: PropTypes.func,
  selectedSunRoomType: PropTypes.string,
  setFieldsWithRedBorder: PropTypes.func,
  selectedERLPatioDoorOrientation: PropTypes.array,
  selectedERLSwingDoorOrientation: PropTypes.array,
  setSelectedERLSwingDoorOrientation: PropTypes.func,
  setSelectedERLPatioDoorOrientation: PropTypes.func,
  setSelectedERLCodes: PropTypes.func,
  selectedERLCodes: PropTypes.array,
  selectedERLButtonIndex: PropTypes.array,
  setSelectedERLButtonIndex: PropTypes.func,
  selectedERLImages: PropTypes.array,
  setSelectedERLImages: PropTypes.func,
  handleCodeSelect: PropTypes.func,
  setLeftSidePickMeDivs: PropTypes.func,
  blackLeftSquares: PropTypes.array,
  setBlackLeftSquares: PropTypes.func,
  setLeftPickMes: PropTypes.func,
  pickMeLeftDivs: PropTypes.array,
  setVerticalLeftLines: PropTypes.func,
  verticalLeftLines: PropTypes.array,
  index: PropTypes.number || PropTypes.string,
  setIndex: PropTypes.func,
  setOpenLeftSingleDrawing: PropTypes.func,
  openLeftSingleDrawing: PropTypes.bool,
  isScreenRoom: PropTypes.bool,
  setSelectedERLLocations: PropTypes.func,
  selectedERLLocations: PropTypes.array,
  handleDoorLocationChange: PropTypes.func,
  selectedBreezeWay: PropTypes.string,
  selectedPavilionLayoutOption: PropTypes.string,
  handleSelectedPatioDoorOrientation: PropTypes.func,
  currentImage: PropTypes.string,
  handleSwingDoorOrientation: PropTypes.func,
};

export default LeftSide;
