import React from "react";
import PropTypes from "prop-types";
import StainlessSteelHinges from "../UniversalWalls/StainlessSteelHinges";

function AngledDoorHardware({
  selectedDoorHardware,
  setSelectedDoorHardware,
  setShowDoorHardware,
  setFieldsWithRedBorder,
  doorWidth,
  handleShowDoorHardwareClick,
  SSHinges,
  handleSSHingesOptionChange,
  redBorderFields,
  showDoorHardware,
}) {
  const NoHardware = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/NoHardware.jpg`;
  const Satin = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/SatinHardware.jpg`;
  const OilRubbedBronze = `https://temoappstorage.blob.core.windows.net/temoimages/DoorOrientationImages/OilRubbedBronze.jpg`;
  const handleSelectedHardwareOption = (selectedHardwareOption) => {
    setSelectedDoorHardware(selectedHardwareOption);
    setFieldsWithRedBorder((prevRedBorderFields) => {
      if (selectedHardwareOption) {
        return prevRedBorderFields.filter((field) => field !== "Door Hardware");
      } else {
        return [...prevRedBorderFields, "Door Hardware"];
      }
    });
  };

  const handleCloseModal = () => {
    setShowDoorHardware(false);
  };

  return (
    <>
      {doorWidth.some((door) => door === "3' Door" || door === "3.5' Door") && (
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "8.5vh",
            alignSelf: "center",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            gap: "1vh",
          }}
        >
          <button
            className={`navbutton ${redBorderFields.includes("Door Hardware") ? "redBackground" : "greenBackground"}`}
            style={{
              flex: 1,
              fontSize: "1.4vh",
              height: "100%",
            }}
            onClick={handleShowDoorHardwareClick}
          >
            {selectedDoorHardware === "" && (
              <span>Swing&nbsp;Door Hardware</span>
            )}
            {selectedDoorHardware !== "" && (
              <span>
                You Selected
                <br />
                {selectedDoorHardware}
              </span>
            )}
          </button>
          <StainlessSteelHinges
            SSHinges={SSHinges}
            handleSSHingesOptionChange={handleSSHingesOptionChange}
            redBorderFields={redBorderFields}
          />
        </div>
      )}
      {showDoorHardware && (
        <div
          style={{
            zIndex: 2000,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal">
            <div
              className="modal-content"
              style={{
                width: "101%",
                height: "101%",
                overflowY: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {selectedDoorHardware === null && (
                <button
                  className="navbutton"
                  style={{
                    position: "absolute",
                    top: "1%",
                    right: "1%",
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={handleCloseModal}
                >
                  X
                </button>
              )}
              {selectedDoorHardware !== null && (
                <button
                  className="navbutton"
                  style={{
                    position: "absolute",
                    top: "1%",
                    right: "1%",
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={handleCloseModal}
                >
                  Done
                </button>
              )}
              <div style={{ height: "90%" }}>
                <button
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "0",
                    position: "relative",
                  }}
                  onClick={() => handleSelectedHardwareOption("No Hardware")}
                >
                  <img
                    src={NoHardware}
                    alt="NoHardware"
                    style={{ height: "100%", width: "100%" }}
                  />
                  {selectedDoorHardware === "No Hardware" && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(173, 216, 230, 0.5)",
                        pointerEvents: "none",
                      }}
                    />
                  )}
                </button>
                <p>No Hardware</p>
              </div>
              <div style={{ height: "90%" }}>
                <button
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "0",
                    position: "relative",
                  }}
                  onClick={() => handleSelectedHardwareOption("Satin")}
                >
                  <img
                    src={Satin}
                    alt="Satin"
                    style={{ height: "100%", width: "100%" }}
                  />
                  {selectedDoorHardware === "Satin" && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(173, 216, 230, 0.5)",
                        pointerEvents: "none",
                      }}
                    />
                  )}
                </button>
                <p>Satin</p>
              </div>
              <div style={{ height: "90%" }}>
                <button
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "0",
                    position: "relative",
                  }}
                  onClick={() =>
                    handleSelectedHardwareOption("Oil Rubbed Bronze")
                  }
                >
                  <img
                    src={OilRubbedBronze}
                    alt="OilRubbedBronze"
                    style={{ height: "100%" }}
                  />
                  {selectedDoorHardware === "Oil Rubbed Bronze" && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(173, 216, 230, 0.5)",
                        pointerEvents: "none",
                      }}
                    />
                  )}
                </button>
                <p>Oil Rubbed Bronze</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

AngledDoorHardware.propTypes = {
  index: PropTypes.number,
  selectedDoorHardware: PropTypes.string,
  setSelectedDoorHardware: PropTypes.func,
  setShowDoorHardware: PropTypes.func,
  setFieldsWithRedBorder: PropTypes.func,
  handleShowDoorHardwareClick: PropTypes.func,
  SSHinges: PropTypes.string,
  handleSSHingesOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  showDoorHardware: PropTypes.bool,
  doorWidth: PropTypes.array,
};

export default AngledDoorHardware;
