import React from "react";

const TwoWall = () => (
  <div className="centered-text">
    My structure backs up to two walls.
    <br />
    I need extra hanging rail on one wall.
    <br />
  </div>
);

export default TwoWall;
