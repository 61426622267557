import React, { useState } from "react";
import InfoIcon from "../UniversalComponents/InfoIcon";
import PropTypes from "prop-types";

const Transoms = ({
  handleSelectedBotTransom,
  redBorderFields,
  setFieldsWithRedBorder,
  handleBotTransomOption,
  handleTopTransomOption,
  selectedSunRoomType,
  handleSelectedTopCustomReady,
  selectedTopTransomOption,
  setSelectedTopTransomOption,
  isScreenRoom,
}) => {
  const [bottomTransom, setBottomTransom] = useState("");
  const [selectedTCR, setSelectedTCR] = useState("");

  const handleBottomTransomChange = (selected) => {
    setBottomTransom(selected);
    handleSelectedBotTransom(selected);

    const updatedRedBorderFields = redBorderFields.includes("Bottom Transom")
      ? selected
        ? redBorderFields.filter((field) => field !== "Bottom Transom")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Bottom Transom"];

    if (selected === "yes" && selectedSunRoomType === "Upper Deck") {
      handleBotTransomOptionChange("24");
    } else if (
      selected === "yes" &&
      (selectedSunRoomType === "Vista View" ||
        selectedSunRoomType === "Solarium")
    ) {
      handleBotTransomOptionChange("12");
    } else {
      handleBotTransomOptionChange("");
    }

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleTCRChange = (selected) => {
    setSelectedTCR(selected);
    handleSelectedTopCustomReady(selected);

    const updatedRedBorderFields = redBorderFields.includes("TCR")
      ? selected
        ? redBorderFields.filter((field) => field !== "TCR")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "TCR"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleTopTransomOptionChange = (selected) => {
    setSelectedTopTransomOption(selected);
    handleTopTransomOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "Top Transom Option",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "Top Transom Option")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Top Transom Option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };
  const handleBotTransomOptionChange = (selected) => {
    handleBotTransomOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "Bot Transom Option",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "Bot Transom Option")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Bot Transom Option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  return (
    <>
      {isScreenRoom && (
        <div
          className="popupwindow"
          style={{
            flex: 1,
            width: "50%",
            marginBottom: "1%",
          }}
        >
          <h6
            className={
              "headers " +
              (redBorderFields.includes("TCR")
                ? "redBackground"
                : "greenBackground")
            }
          >
            <InfoIcon defaultMessage="Refers to custom trapezoidal glass.<br/>Dealer must supply glass." />
            <span className="header-grid-location">
              <span className="redasterisk">&#10033;</span>
              Custom Ready
            </span>
          </h6>
          <div className="clickable-words">
            <div
              className={`clickable-word ${selectedTCR === "yes" ? "clicked" : ""}`}
              onClick={() => handleTCRChange("yes")}
            >
              Yes
            </div>
            <div
              className={`clickable-word ${selectedTCR === "no" ? "clicked" : ""}`}
              onClick={() => handleTCRChange("no")}
            >
              No
            </div>
          </div>
        </div>
      )}
      {!isScreenRoom && (
        <div
          className="transoms"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "1%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div className="popupwindow" style={{ width: "100%" }}>
              <h6
                className={
                  "headers " +
                  (redBorderFields.includes("Top Transom Option")
                    ? "redBackground"
                    : "greenBackground")
                }
              >
                <InfoIcon defaultMessage="Rectangular factory transoms that go at the top of your wall." />
                <span className="header-grid-location">
                  <span className="redasterisk">&#10033;</span>
                  Top Transom
                </span>
              </h6>
              <div className="clickable-words">
                <div
                  className={`clickable-word ${selectedTopTransomOption === "TOP TRANSOMS-6" ? "clicked" : ""}`}
                  onClick={() => handleTopTransomOptionChange("TOP TRANSOMS-6")}
                >
                  6in
                </div>
                <div
                  className={`clickable-word ${selectedTopTransomOption === "TOP TRANSOMS-10" ? "clicked" : ""}`}
                  onClick={() =>
                    handleTopTransomOptionChange("TOP TRANSOMS-10")
                  }
                >
                  10in
                </div>
                &nbsp;
                <div
                  className={`clickable-word ${selectedTopTransomOption === "TOP TRANSOMS-12" ? "clicked" : ""}`}
                  onClick={() =>
                    handleTopTransomOptionChange("TOP TRANSOMS-12")
                  }
                >
                  12in
                </div>
                &nbsp;
                <div
                  className={`clickable-word ${selectedTopTransomOption === "TOP TRANSOMS-24" ? "clicked" : ""}`}
                  onClick={() =>
                    handleTopTransomOptionChange("TOP TRANSOMS-24")
                  }
                >
                  24in
                </div>
                <div
                  className={`clickable-word ${selectedTopTransomOption === "none" ? "clicked" : ""}`}
                  style={{ marginLeft: "1%" }}
                  onClick={() => handleTopTransomOptionChange("none")}
                >
                  None
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "1%",
            }}
          >
            {selectedSunRoomType !== "Premier View" && (
              <div
                className="popupwindow"
                style={{ width: "50%", height: "100%" }}
              >
                <h6
                  className={
                    "headers " +
                    (redBorderFields.includes("Bottom Transom")
                      ? "redBackground"
                      : "greenBackground")
                  }
                >
                  <InfoIcon defaultMessage='12" or 24" factory transoms that go at the bottom of the wall.<br/>Size is determined by Sunroom Type.<br/>Upper Deck - 24"<br/>Vista - 12"<br/>Solarium - 12"' />
                  <span className="header-grid-location">
                    <span className="redasterisk">&#10033;</span>
                    Bot. Transom
                  </span>
                </h6>
                <div className="clickable-words">
                  <div
                    className={`clickable-word ${bottomTransom === "yes" ? "clicked" : ""}`}
                    onClick={() => handleBottomTransomChange("yes")}
                  >
                    Yes
                  </div>
                  <div
                    className={`clickable-word ${bottomTransom === "no" ? "clicked" : ""}`}
                    onClick={() => handleBottomTransomChange("no")}
                  >
                    No
                  </div>
                </div>
              </div>
            )}
            <div
              className="popupwindow"
              style={{
                flex: 1,
                width: selectedSunRoomType !== "Premier View" ? "50%" : "100%",
                marginLeft: selectedSunRoomType !== "Premier View" ? "1%" : 0,
              }}
            >
              <h6
                className={
                  "headers " +
                  (redBorderFields.includes("TCR")
                    ? "redBackground"
                    : "greenBackground")
                }
              >
                <InfoIcon defaultMessage="Refers to custom trapezoidal glass.<br/>Dealer must supply glass." />
                <span className="header-grid-location">
                  <span className="redasterisk">&#10033;</span>
                  Custom Ready
                </span>
              </h6>
              <div className="clickable-words">
                <div
                  className={`clickable-word ${selectedTCR === "yes" ? "clicked" : ""}`}
                  onClick={() => handleTCRChange("yes")}
                >
                  Yes
                </div>
                <div
                  className={`clickable-word ${selectedTCR === "no" ? "clicked" : ""}`}
                  onClick={() => handleTCRChange("no")}
                >
                  No
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Transoms.propTypes = {
  handleSelectedTopTransom: PropTypes.func,
  handleSelectedBotTransom: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleBotTransomOption: PropTypes.func,
  handleTopTransomOption: PropTypes.func,
  selectedSunRoomType: PropTypes.string,
  handleSelectedTopCustomReady: PropTypes.func,
  selectedTopTransomOption: PropTypes.string,
  setSelectedTopTransomOption: PropTypes.func,
  isScreenRoom: PropTypes.bool,
};

export default Transoms;
