import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import LoginButton from "./LoginButton";

const Login = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/login/callback");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="app-page">
      <LoginButton />
    </div>
  );
};

export default Login;
