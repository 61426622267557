import React from "react";

const RepErrorMessage = () => (
  <div>
    You are currently logged on as a TEMO Employee.
    <br />
    TEMO Employees cannot order.
    <br />
  </div>
);

export default RepErrorMessage;
