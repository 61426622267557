import React from "react";
import PropTypes from "prop-types";

function StainlessSteelHinges({
  handleSSHingesOptionChange,
  redBorderFields,
  SSHinges,
}) {
  return (
    <div
      className="popupwindow"
      style={{ flex: 1, height: "102%", width: "100%" }}
    >
      <h6
        className={
          "headers " +
          (redBorderFields.includes("SS Hinges")
            ? "redBackground"
            : "greenBackground")
        }
        style={{
          fontSize: "1.4vh",
          marginBottom: "0",
        }}
      >
        <span className="header-grid-location">
          <span className="redasterisk">&#10033;</span>
          Stainless Steel Hinges
        </span>
      </h6>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "70%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="clickable-words"
          style={{ flexWrap: "nowrap", width: "100%" }}
        >
          <div
            className={`clickable-word ${SSHinges === "YES" ? "clicked" : ""}`}
            onClick={() => handleSSHingesOptionChange("YES")}
          >
            Yes
          </div>
          &nbsp;
          <div
            className={`clickable-word ${SSHinges === "NO" ? "clicked" : ""}`}
            onClick={() => handleSSHingesOptionChange("NO")}
          >
            No
          </div>
        </div>
      </div>
    </div>
  );
}

StainlessSteelHinges.propTypes = {
  handleSSHingesOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  SSHinges: PropTypes.string,
};

export default StainlessSteelHinges;
