import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";

const NumericInputWithButtons = ({
  min,
  max,
  onChange,
  step,
  value: externalValue,
  id,
  className,
}) => {
  const [value, setValue] = useState("");
  const intervalIdRef = useRef(null);

  useEffect(() => {
    setValue(externalValue !== undefined ? externalValue.toString() : "");
  }, [externalValue]);

  const handleIncrement = useCallback(() => {
    setValue((prevValue) => {
      const newValue =
        prevValue === "" ? min : Math.min(max, parseInt(prevValue, 10) + step);
      onChange(newValue);
      return newValue.toString();
    });
  }, [min, max, step, onChange]);

  const handleDecrement = useCallback(() => {
    setValue((prevValue) => {
      const newValue =
        prevValue === "" ? min : Math.max(min, parseInt(prevValue, 10) - step);
      onChange(newValue);
      return newValue.toString();
    });
  }, [min, step, onChange]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const handleBlur = () => {
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      const stepsFromMin = Math.round((numericValue - min) / step);
      const roundedValue = min + stepsFromMin * step;
      const clampedValue = Math.max(min, Math.min(max, roundedValue));
      setValue(clampedValue.toString());
      onChange(clampedValue);
    } else {
      setValue(min.toString());
      onChange(min);
    }
  };

  const startIncrementing = () => {
    handleIncrement();
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(handleIncrement, 200);
  };

  const startDecrementing = () => {
    handleDecrement();
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(handleDecrement, 200);
  };

  const stopUpdating = () => {
    clearInterval(intervalIdRef.current);
  };

  return (
    <div
      id={id}
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        width: "66%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <button
          onMouseDown={startDecrementing}
          onMouseUp={stopUpdating}
          onMouseLeave={stopUpdating}
          className="showpdfbuttons"
          style={{
            fontSize: "2vh",
            padding: "5%",
            lineHeight: ".5",
            paddingBottom: "5.5%",
            paddingTop: "0",
            width: "fit-content",
          }}
        >
          &ndash;
        </button>
        <input
          type="number"
          value={value}
          onChange={handleChange}
          className={`no-arrows ${className}`}
          style={{ width: "100%" }}
          onBlur={handleBlur}
        />
        <button
          onMouseDown={startIncrementing}
          onMouseUp={stopUpdating}
          onMouseLeave={stopUpdating}
          className="showpdfbuttons"
          style={{
            fontSize: "2vh",
            padding: "5%",
            lineHeight: ".5",
            paddingBottom: "6%",
            paddingTop: "0",
            width: "fit-content",
          }}
        >
          &#43;
        </button>
      </div>
    </div>
  );
};

NumericInputWithButtons.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  className: PropTypes.string,
};

export default NumericInputWithButtons;
