import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "./Tabs";
import ConfirmedTable from "./ConfirmedTable/ConfirmedTable";
import ProcessedTable from "./ProcessedTable/ProcessedTable";
import UnprocessedTable from "./UnprocessedTable/UnprocessedTable";
import InitialOrdersTable from "./InitialOrderTable/InitialOrdersTable";

const CurrentOrdersTable = ({
  confirmed,
  processed,
  unprocessed,
  initialorders,
  handleShowPdfFilesTable,
  handleShowPdfFilesProcess,
  handleTabClick,
  initialOrdersIsLoading,
  confirmedIsLoading,
  processedIsLoading,
  unprocessedIsLoading,
  currentProcess,
  currentConfirm,
  setFilesList,
  setIsUserHide,
}) => {
  const [confirmedData, setConfirmedData] = useState(confirmed);
  const [sortOrderByName, setSortOrderByName] = useState("desc");
  const [sortOrderByDate, setSortOrderByDate] = useState("desc");
  const [sortOrderBySchedDate, setSortOrderBySchedDate] = useState("asc");
  const [processedData, setProcessedData] = useState(processed);
  const [sortProcessedByName, setSortProcessedByName] = useState("desc");
  const [sortProcessedByDateCreated, setSortProcessedByDateCreated] =
    useState("desc");
  const [sortUnprocessedOrderByLastName, setSortUnprocessedOrderByLastName] =
    useState("desc");
  const [sortUnprocessedOrderByFirstName, setSortUnprocessedOrderByFirstName] =
    useState("desc");
  const [sortInitialOrderByLastName, setsortInitialOrderByLastName] =
    useState("desc");
  const [sortInitialOrderByFirstName, setsortInitialOrderByFirstName] =
    useState("desc");
  const [unprocessedData, setUnprocessedData] = useState(unprocessed);
  const [initialordersData, setInitialOrdersData] = useState(initialorders);
  const [searchTerm, setSearchTerm] = useState("");
  const currentOrdersTableRef = useRef(null);

  const reformatScheduledDate = (dateString) => {
    if (!dateString || dateString.length < 6) {
      return "";
    }
    const scheduledDate = dateString.substring(0, 4);
    const lastTwoDigits = dateString.substring(dateString.length - 2);

    if (isNaN(scheduledDate) || isNaN(lastTwoDigits)) {
      return "";
    }
    const year = `20${lastTwoDigits}`;
    const month = scheduledDate.substr(0, 2);
    const day = scheduledDate.substr(2, 2);
    if (isNaN(month) || isNaN(day) || isNaN(year)) {
      return "";
    }
    const formattedDate = new Date(`${year}-${month}-${day}`);
    if (isNaN(formattedDate.getTime())) {
      return "";
    }
    const YYYYMMDD = formattedDate.toISOString().split("T")[0];
    return YYYYMMDD;
  };

  const reformatDateSent = (dateString) => {
    if (!dateString || dateString.length < 14) {
      return { date: "", time: "" };
    }

    const year = dateString.substr(0, 4);
    const month = dateString.substr(4, 2);
    const day = dateString.substr(6, 2);

    let time = "";

    if (dateString.length < 16) {
      time = dateString.substr(7);
    } else {
      time = dateString.substr(8);
    }

    const date = `${year}-${month}-${day}`;
    return { date, time };
  };

  const itemToString = useCallback((item, dataset) => {
    let str = "";
    for (const key in item) {
      if (key === "SchedDate" && dataset === "confirmed") {
        str += reformatScheduledDate(item[key]) + " ";
      } else if (
        key === "dateTimeSent" &&
        (dataset === "unprocessed" || dataset === "initialorders")
      ) {
        const { date, time } = reformatDateSent(item[key]);
        str += date + " " + time + " ";
      } else {
        str += item[key] + " ";
      }
    }
    return str.trim().toLowerCase();
  }, []);

  useEffect(() => {
    const filterData = (data, dataset) =>
      searchTerm
        ? data.filter((item) =>
            itemToString(item, dataset).includes(
              searchTerm.trim().toLowerCase(),
            ),
          )
        : data;

    setConfirmedData(filterData(confirmed, "confirmed"));
    setProcessedData(filterData(processed, "processed"));
    setUnprocessedData(filterData(unprocessed, "unprocessed"));
    setInitialOrdersData(filterData(initialorders, "initialorders"));
  }, [
    confirmed,
    processed,
    unprocessed,
    initialorders,
    searchTerm,
    itemToString,
  ]);
  return (
    <div style={{ width: "100%" }}>
      <Tabs
        ref={currentOrdersTableRef}
        handleTabClick={handleTabClick}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setFilesList={setFilesList}
        setIsUserHide={setIsUserHide}
      >
        <div label="Confirmed" style={{ width: "100%" }}>
          <table
            className="tableStyle tableHeaderTableHeight"
            style={{ width: "100%" }}
          >
            <thead className="theadThStyle" style={{ width: "100%" }}>
              <tr style={{ width: "100%" }}>
                <th
                  colSpan={6}
                  className="thStyle"
                  style={{ borderRight: "none", width: "100%" }}
                >
                  CURRENT CONFIRMED ORDERS
                </th>
              </tr>
            </thead>
          </table>
          <ConfirmedTable
            id="dtDynamicVerticalScrollExample"
            confirmedIsLoading={confirmedIsLoading}
            confirmed={confirmed}
            confirmedData={confirmedData}
            reformatScheduledDate={reformatScheduledDate}
            handleShowPdfFilesTable={handleShowPdfFilesTable}
            sortOrderByName={sortOrderByName}
            setSortOrderByName={setSortOrderByName}
            sortOrderByDate={sortOrderByDate}
            setSortOrderByDate={setSortOrderByDate}
            setConfirmedData={setConfirmedData}
            sortOrderBySchedDate={sortOrderBySchedDate}
            setSortOrderBySchedDate={setSortOrderBySchedDate}
            currentConfirm={currentConfirm}
          />
        </div>

        <div label="Processed" style={{ width: "100%" }}>
          <table
            className="tableStyle tableHeaderTableHeight"
            style={{ width: "100%" }}
          >
            <thead className="theadThStyle" style={{ width: "100%" }}>
              <tr style={{ width: "100%" }}>
                <th
                  colSpan={6}
                  className="thStyle"
                  style={{ borderRight: "none", width: "100%" }}
                >
                  CURRENT PROCESSED ORDERS
                </th>
              </tr>
            </thead>
          </table>
          <ProcessedTable
            id="dtDynamicVerticalScrollExample"
            processedIsLoading={processedIsLoading}
            processed={processed}
            processedData={processedData}
            handleShowPdfFilesProcess={handleShowPdfFilesProcess}
            setProcessedData={setProcessedData}
            setSortProcessedByName={setSortProcessedByName}
            sortProcessedByName={sortProcessedByName}
            setSortProcessedByDateCreated={setSortProcessedByDateCreated}
            sortProcessedByDateCreated={sortProcessedByDateCreated}
            currentProcess={currentProcess}
          />
        </div>

        <div label="Unprocessed" style={{ width: "100%" }}>
          <table
            className="tableStyle tableHeaderTableHeight"
            style={{ width: "100%" }}
          >
            <thead className="theadThStyle" style={{ width: "100%" }}>
              <tr style={{ width: "100%" }}>
                <th
                  colSpan={6}
                  className="thStyle"
                  style={{ borderRight: "none", width: "100%" }}
                >
                  CURRENT UNPROCESSED ORDERS
                </th>
              </tr>
            </thead>
          </table>
          <UnprocessedTable
            id="dtDynamicVerticalScrollExample"
            unprocessedIsLoading={unprocessedIsLoading}
            unprocessed={unprocessed}
            unprocessedData={unprocessedData}
            reformatDateSent={reformatDateSent}
            setsortUnprocessedOrderByLastName={
              setSortUnprocessedOrderByLastName
            }
            setUnprocessedData={setUnprocessedData}
            sortUnprocessedOrderByLastName={sortUnprocessedOrderByLastName}
            sortUnprocessedOrderByFirstName={sortUnprocessedOrderByFirstName}
            setsortUnprocessedOrderByFirstName={
              setSortUnprocessedOrderByFirstName
            }
          />
        </div>

        <div label="Initial Orders" style={{ width: "100%" }}>
          <table
            className="tableStyle tableHeaderTableHeight"
            style={{ width: "100%" }}
          >
            <thead className="theadThStyle" style={{ width: "100%" }}>
              <tr style={{ width: "100%" }}>
                <th
                  colSpan={6}
                  className="thStyle"
                  style={{ borderRight: "none", width: "100%" }}
                >
                  INITIAL ORDERS
                </th>
              </tr>
            </thead>
          </table>
          <InitialOrdersTable
            id="dtDynamicVerticalScrollExample"
            initialOrdersIsLoading={initialOrdersIsLoading}
            initialorders={initialorders}
            initialordersData={initialordersData}
            reformatDateSent={reformatDateSent}
            setInitialOrdersData={setInitialOrdersData}
            sortInitialOrderByLastName={sortInitialOrderByLastName}
            setsortInitialOrderByLastName={setsortInitialOrderByLastName}
            sortInitialOrderByFirstName={sortInitialOrderByFirstName}
            setsortInitialOrderByFirstName={setsortInitialOrderByFirstName}
            sortOrderByDate={sortOrderByDate}
            setSortOrderByDate={setSortOrderByDate}
          />
        </div>
      </Tabs>
    </div>
  );
};

CurrentOrdersTable.displayName = "CurrentOrdersTable";

CurrentOrdersTable.propTypes = {
  confirmed: PropTypes.array,
  processed: PropTypes.array,
  unprocessed: PropTypes.array,
  initialorders: PropTypes.array,
  handleShowPdfFilesTable: PropTypes.func,
  handleShowPdfFilesProcess: PropTypes.func,
  handleTabClick: PropTypes.func,
  initialOrdersIsLoading: PropTypes.bool,
  confirmedIsLoading: PropTypes.bool,
  processedIsLoading: PropTypes.bool,
  unprocessedIsLoading: PropTypes.bool,
  currentProcess: PropTypes.object,
  currentConfirm: PropTypes.object,
  setFilesList: PropTypes.func,
  setIsUserHide: PropTypes.func,
};

export default CurrentOrdersTable;
