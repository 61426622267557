import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const InactivityHandler = ({ children }) => {
  const { logout } = useAuth0();

  useEffect(() => {
    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(
        () => {
          logout({ returnTo: window.location.origin });
        },
        30 * 60 * 1000,
      );
    };

    const events = ["mousemove", "keydown", "click", "touchstart"];
    const appElement = document.getElementById("PortalApp");

    if (appElement) {
      events.forEach((event) => appElement.addEventListener(event, resetTimer));
    }

    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      if (appElement) {
        events.forEach((event) =>
          appElement.removeEventListener(event, resetTimer),
        );
      }
    };
  }, [logout]);

  return children;
};

export default InactivityHandler;
