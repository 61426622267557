import React, { useState } from "react";
import ImageButton from "../UniversalComponents/ImageButton";
import PropTypes from "prop-types";

const LEDLightSelection = ({
  handleSelectedLEDLightChoice,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedLEDOption, setSelectedLEDOption] = useState("");
  const images = ["LED_Yes", "LED_No"]; // Replace with actual image paths
  const messages = ["LED YES", "LED NO"];

  const handleLEDOptionChange = (selected) => {
    setSelectedLEDOption(selected);
    const updatedRedBorderFields = redBorderFields.includes("LED YES or NO")
      ? selected
        ? redBorderFields.filter((field) => field !== "LED YES or NO")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "LED YES or NO"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedLEDLightChoice(selected);
  };

  return (
    <div className="popupwindow ledlightselection">
      <h6
        className={
          "headers " +
          (redBorderFields.includes("LED YES or NO")
            ? "redBackground"
            : "greenBackground")
        }
      >
        <ImageButton images={images} messages={messages} />
        <span
          style={{ marginBottom: ".5em", marginTop: ".5em" }}
          className="header-grid-location"
        >
          <span className="redasterisk">&#10033;</span>
          Skylight L.E.D.s
        </span>
      </h6>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedLEDOption === "LED YES" ? "clicked" : ""}`}
          onClick={() => handleLEDOptionChange("LED YES")}
        >
          YES
        </div>
        <div
          className={`clickable-word ${selectedLEDOption === "LED NO" ? "clicked" : ""}`}
          onClick={() => handleLEDOptionChange("LED NO")}
        >
          NO
        </div>
      </div>
    </div>
  );
};

LEDLightSelection.propTypes = {
  handleSelectedLEDLightChoice: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default LEDLightSelection;
