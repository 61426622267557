import React from "react";
import { Link } from "react-router-dom";
import logo from "./website-logo.png";
function TemoLogo() {
  return (
    <header style={{ height: "10vh" }}>
      <Link to="/login/callback">
        <img src={logo} className="App-logo" alt={logo} />
      </Link>
    </header>
  );
}
export default TemoLogo;
